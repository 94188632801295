import { Clock as ClockIcon } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import classNames from 'classnames';
import React from 'react';

import { MENTIONS_TIMESTAMP_PILL, TIMESTAMP_INPUT_BUTTON } from '~/constants/testIDs';

interface TimestampButtonProps {
  shouldEnableTimestamps: boolean;
  setShouldEnableTimestamps: (shouldEnableTimestamps: boolean) => void;
  formattedTimestamp?: string;
  shouldDisableTimestampsAndAnnotations: boolean;
}

export const TimestampButton = ({
  shouldEnableTimestamps,
  setShouldEnableTimestamps,
  formattedTimestamp,
  shouldDisableTimestampsAndAnnotations,
}: TimestampButtonProps) => {
  return (
    <Tooltip
      label={
        shouldDisableTimestampsAndAnnotations ? 'Timestamp commenting is disabled during video processing' : 'Timestamp'
      }
      side="top"
    >
      <button
        data-theme="light" // set light theme to preserve colors, should be removed in the future when asset modal is real "dark" theme
        disabled={shouldDisableTimestampsAndAnnotations}
        className={classNames(
          'flex items-center rounded-full',
          shouldEnableTimestamps ? 'bg-blue-1 p-1 hover:bg-blue-2' : '',
        )}
        onClick={() => setShouldEnableTimestamps(!shouldEnableTimestamps)}
      >
        <ClockIcon
          aria-label="Timestamp"
          className={classNames(
            'rounded-full hover:text-blue-11',
            shouldEnableTimestamps ? 'w-4 p-0 hover:bg-transparent' : 'w-6 p-1 hover:bg-blue-2',
            shouldEnableTimestamps
              ? 'text-blue-11'
              : shouldDisableTimestampsAndAnnotations
              ? 'text-grey-8'
              : 'text-grey-2',
          )}
          data-testid={TIMESTAMP_INPUT_BUTTON}
        />
        {shouldEnableTimestamps && (
          <div className="ml-1 mr-0.5 text-10 font-medium text-blue-11" data-testid={MENTIONS_TIMESTAMP_PILL}>
            {formattedTimestamp}
          </div>
        )}
      </button>
    </Tooltip>
  );
};
