import { memo } from 'react';

import { AssetTitleAndDescriptionContainer } from '~/components/AssetModal/shared/components/InfoPanel/components/AssetTitleAndDescription/AssetTitleAndDescriptionContainer';
import { InfoPanelDescription } from '~/components/AssetModal/shared/components/InfoPanel/components/AssetTitleAndDescription/components/InfoPanelDescription';
import { InfoPanelTitle } from '~/components/AssetModal/shared/components/InfoPanel/components/AssetTitleAndDescription/components/InfoPanelTitle';

export const ReadonlyAssetTitleAndDescription = memo(() => {
  return <AssetTitleAndDescriptionContainer title={<InfoPanelTitle />} description={<InfoPanelDescription />} />;
});

ReadonlyAssetTitleAndDescription.displayName = 'ReadonlyAssetTitleAndDescription';
