import { Skeleton } from '@air/component-skeleton';
import { memo } from 'react';

export const InfoPanelLoadingSkeleton = memo(() => {
  return (
    <div className="flex flex-col gap-4 px-2 py-5">
      <div className="flex flex-col gap-4">
        <Skeleton width={64} height={6} />
        <Skeleton width={168} height={10} />
        <Skeleton width={107} height={10} />
      </div>
      <div className="flex flex-col gap-5">
        <Skeleton width={64} height={6} />
        <Skeleton width={168} height={10} />
        <Skeleton width={168} height={10} />
        <Skeleton width={107} height={10} />
        <Skeleton width={107} height={10} />
        <Skeleton width={168} height={10} />
        <Skeleton width={107} height={10} />
        <Skeleton width={168} height={10} />
        <Skeleton width={107} height={10} />
      </div>
    </div>
  );
});

InfoPanelLoadingSkeleton.displayName = 'InfoPanelLoadingSkeleton';
