import Router from 'next/router';
import { useCallback } from 'react';

import { getQueryWithoutAssetModalParams } from '~/components/AssetModal/shared/assetModalUtils';

export const useCloseAssetModal = () => {
  const closeAssetModal = useCallback(() => {
    Router.ready(() => {
      const query = getQueryWithoutAssetModalParams();
      const cliplessUrl = Router.asPath.split(/\/c\//)[0];

      return Router.push({ pathname: cliplessUrl, query }, undefined, {
        shallow: true,
      });
    });
  }, []);

  return {
    closeAssetModal,
  };
};
