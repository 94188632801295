import { useBreakpointsContext } from '@air/provider-media-query';
import { useAirModal } from '@air/provider-modal';
import { useIsLoggedIn } from '@air/utils-auth';
import Router from 'next/router';
import { memo, useCallback, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import { BOUNDING_BOX_TEXT_WIDTH } from '~/components/BoundingBox/constants';
import { DiscussionForm, DiscussionFormHandle } from '~/components/Discussions/DiscussionForm';
import { PublicCommentAnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/PublicCommentAnonymousSignUpModal';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { ANNOTATION_COMMENT_FORM } from '~/constants/testIDs';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useAnnotationContext } from '~/providers/AnnotationProvider/AnnotationProvider';
import { centralizedBoardAncestorsSelector } from '~/store/centralizedBoard/selectors';
import { centralizedClipWorkspaceIdSelector } from '~/store/centralizedClip/selectors';
import { activeTimestampParamSelector } from '~/store/router/selectors';
import { useGetDiscussionMentionsMembers } from '~/swr-hooks/discussions/useGetDiscussionMentionsMembers';
import { useSubmitNewDiscussion } from '~/swr-hooks/discussions/useSubmitNewDiscussion';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

export const FloatingCommentForm = memo(() => {
  const inputRef = useRef<DiscussionFormHandle>(null);
  const boardId = useURLBoardIdSelector();
  const boardAncestors = useAirSelector(centralizedBoardAncestorsSelector);
  const clipWorkspaceId = useSelector(centralizedClipWorkspaceIdSelector) ?? '';
  const store = useAirStore();

  const mentionsList = useGetDiscussionMentionsMembers({
    boardId,
    ancestors: boardAncestors,
    workspaceId: clipWorkspaceId,
  });

  const { submitNewDiscussion } = useSubmitNewDiscussion();
  const { clearNewAnnotation, getValue } = useAnnotationContext();

  const { isAboveMediumScreen } = useBreakpointsContext();

  const { isLoggedIn } = useIsLoggedIn();
  const [showSignUpModal] = useAirModal(PublicCommentAnonymousSignUpModal);

  useMount(() => {
    inputRef?.current?.focus();
  });

  useHotkeys(
    'esc',
    () => {
      clearNewAnnotation();
    },
    { enableOnFormTags: ['TEXTAREA'] },
    [clearNewAnnotation],
  );

  const submitComment = useCallback(
    (comment: string) => {
      const createNewDiscussion = () =>
        submitNewDiscussion({
          newComment: comment,
          timestamp: activeTimestampParamSelector(store.getState()),
          trackLocation: 'asset-page',
          annotation: getValue().newAnnotation,
        });

      if (isLoggedIn) {
        createNewDiscussion();
      } else {
        const ssoCallbackData: SSOCallbackData = {
          url: Router.asPath,
          action: {
            action: 'create-public-discussion',
            data: {
              newComment: comment,
              annotation: getValue().newAnnotation,
              timestamp: activeTimestampParamSelector(store.getState()),
              trackLocation: 'asset-page',
            },
          },
        };

        showSignUpModal({
          onLoginSuccess: createNewDiscussion,
          ssoCallbackData,
        });

        clearNewAnnotation();
      }
    },
    [clearNewAnnotation, getValue, isLoggedIn, showSignUpModal, store, submitNewDiscussion],
  );

  return (
    <div className="mx-3 rounded border border-grey-5 bg-grey-1 shadow-popover [&>form]:border-none [&>form]:shadow-none [&>form]:focus-within:border-none [&>form]:focus-within:shadow-none">
      <DiscussionForm
        data-testid={ANNOTATION_COMMENT_FORM}
        ref={inputRef}
        onSubmit={submitComment}
        mentionsList={mentionsList}
        placeholder="Share your thoughts"
        shouldShowMentionsButton={isAboveMediumScreen}
        style={{ width: BOUNDING_BOX_TEXT_WIDTH }}
      />
    </div>
  );
});

FloatingCommentForm.displayName = 'FloatingCommentForm';
