import classNames from 'classnames';
import { memo, useMemo } from 'react';

import { FileExtensionName, fileExtensionStylingMap, genericFile } from '~/components/FileExtensionPreview/types';

import { THUMBNAIL_HEIGHT } from '../../../shared/constants';

interface VersionFileThumbnailProps {
  ext?: string;
  isCurrentVersion: boolean;
}

export const VersionFileThumbnail = memo(({ ext, isCurrentVersion }: VersionFileThumbnailProps) => {
  const extStyles = useMemo(() => {
    const normalizedExt = ext?.toLowerCase() || ''; // to support e.g. JPG
    return normalizedExt in fileExtensionStylingMap
      ? fileExtensionStylingMap[normalizedExt as FileExtensionName]
      : genericFile;
  }, [ext]);

  return (
    <div
      className={classNames(
        'asset-modal-version-thumbnail pointer-events-none box-border flex h-[THUMBNAIL_HEIGHT] w-full items-center justify-center overflow-hidden rounded-sm border border-black',
        isCurrentVersion ? 'shadow-[0px_0px_0px_2px] shadow-grey-10' : '',
      )}
      style={{
        height: THUMBNAIL_HEIGHT,
        color: extStyles.iconColor,
        backgroundColor: extStyles.bgColor,
      }}
    >
      <extStyles.iconComponent className="block w-8" />
    </div>
  );
});

VersionFileThumbnail.displayName = 'VersionFileThumbnail';
