import { useVirtualizer } from '@tanstack/react-virtual';
import React, { forwardRef, memo, useImperativeHandle, useRef } from 'react';

export interface DiscussionsVirtualizedListProps {
  itemsCount: number;
  children: (props: { index: number }) => React.ReactNode;
}

export interface DiscussionsVirtualizedListHandle {
  scrollToBottom: () => void;
  getScrollElement: () => HTMLDivElement | null;
}

const _DiscussionsVirtualizedList = forwardRef<DiscussionsVirtualizedListHandle, DiscussionsVirtualizedListProps>(
  ({ itemsCount, children }: DiscussionsVirtualizedListProps, forwardedRef) => {
    const scrollElementRef = useRef<HTMLDivElement | null>(null);

    const virtualizer = useVirtualizer({
      count: itemsCount,
      estimateSize: () => 500,
      getScrollElement: () => scrollElementRef.current,
      overscan: 10,
      initialOffset: itemsCount * 500, // this sets the initial scroll position to the bottom, 500 is an arbitrary number, higher than default size
    });

    useImperativeHandle(forwardedRef, () => ({
      getScrollElement: () => scrollElementRef.current,
      scrollToBottom: () => virtualizer.scrollToIndex(itemsCount - 1),
    }));

    const virtualItems = virtualizer.getVirtualItems();

    return (
      <div
        data-testid="CLIP_FACES_LIST_SCROLL"
        ref={scrollElementRef}
        className="flex w-full flex-1 overflow-y-auto scrollbar scrollbar-track-grey-2 scrollbar-thumb-grey-7 [&:not(:hover)]:scrollbar-track-transparent"
      >
        <div className="relative mt-auto w-full" style={{ height: virtualizer.getTotalSize() }}>
          <div
            className="absolute left-0 top-0 flex w-full flex-col"
            style={{ transform: `translateY(${virtualItems[0]?.start ?? 0}px)` }}
          >
            {virtualItems.map((virtualRow) => (
              <div
                className="border-t border-t-grey-5 first:border-t-0"
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
              >
                {children({ index: virtualRow.index })}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
);

_DiscussionsVirtualizedList.displayName = 'DiscussionsVirtualizedList';

export const DiscussionsVirtualizedList = memo(_DiscussionsVirtualizedList);

DiscussionsVirtualizedList.displayName = 'DiscussionsVirtualizedList';
