import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { AssetLocationBreadcrumbs } from '~/components/AssetModal/Header/components/AssetLocationBreadcrumbs';
import { AssetLocationName } from '~/components/AssetModal/Header/components/AssetLocationName';
import { centralizedClipBoardInfoSelector } from '~/store/centralizedClip/selectors';

export interface BasicAssetHeaderLocationProps {
  onClose?: () => void;
  workspaceName: string | undefined;
}

export const BasicAssetHeaderLocation = memo(({ onClose, workspaceName }: BasicAssetHeaderLocationProps) => {
  const { current } = useSelector(centralizedClipBoardInfoSelector);
  const { libraries: librariesFlag } = useFlags();

  if (current) {
    if (current.library && !!current.ancestors?.length && librariesFlag) {
      return <AssetLocationBreadcrumbs />;
    }
    return <AssetLocationName onClick={onClose} location={current.title} />;
  }

  if (workspaceName) {
    return <AssetLocationName location={workspaceName} />;
  }

  return null;
});

BasicAssetHeaderLocation.displayName = 'BasicAssetHeaderLocation';
