import { Loop as LoopIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import classNames from 'classnames';
import { memo } from 'react';

interface LoopVideoButtonProps {
  shouldLoopVideo: boolean;
  toggleLoopVideo: () => void;
}
export const LoopVideoButton = memo(({ shouldLoopVideo, toggleLoopVideo }: LoopVideoButtonProps) => {
  return (
    <IconButton
      appearance="ghost"
      color="grey"
      onClick={toggleLoopVideo}
      className="group/loop-video-button mr-0 md:mr-2"
      size="large"
      label={shouldLoopVideo ? 'Unloop video' : 'Loop video'}
    >
      <LoopIcon
        className={classNames(
          'h-6 w-6',
          shouldLoopVideo ? 'text-teal-7 group-hover/loop-video-button:text-teal-9' : '',
        )}
      />
    </IconButton>
  );
});

LoopVideoButton.displayName = 'LoopVideoButton';
