import { BoxAnnotation } from '@air/api/types';
import { teal, tealDark } from '@air/colors';
import { isUndefined } from 'lodash';
import { useTheme } from 'next-themes';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { AnnotationCommentInput } from '~/components/Annotations/shared/AnnotationCommentInput';
import { getAnnotationCommentPos, getPageNumber } from '~/components/Annotations/shared/annotationUtils';
import { BaseAnnotationLayerProps } from '~/components/Annotations/shared/types';
import {
  BoundingBoxLayer,
  BoundingBoxLayerMouseEventHandler,
  BoundingBoxLayerProps,
} from '~/components/BoundingBox/components/BoundingBoxLayer';
import { PercentageRect } from '~/components/BoundingBox/types';
import { DrawPercentageRectOnCanvas } from '~/components/BoundingBox/utils/BoundingBoxCanvas';
import { drawClearedBoundingBoxOnCanvas } from '~/components/BoundingBox/utils/drawBoxesOnCanvas';
import { QueryParamNames } from '~/constants/search';
import { setQueryParams } from '~/utils/PathUtils';

interface AnnotationLayerProps extends BaseAnnotationLayerProps<BoxAnnotation> {
  shouldDrawOverlay: boolean;
}

const BORDER_WIDTH = 2;

export const BoxAnnotationLayer = memo(
  ({
    disabled,
    containerRef,
    pageNumber,
    onIsSelectingChange,
    containerWidth,
    containerHeight,
    activeAnnotation,
    onAnnotationDrawn,
    onClear,
    newAnnotation,
    shouldDrawOverlay,
    containerTop,
    containerLeft,
  }: AnnotationLayerProps) => {
    const theme = useTheme();

    const strokeColor = theme.theme === 'dark' ? tealDark.teal7 : teal.teal7;

    const annotationToDraw = activeAnnotation || newAnnotation;

    const [isDrawingActive, setIsDrawingActive] = useState(false);

    const _onIsSelectingChange = useCallback(
      (isSelecting: boolean) => {
        onIsSelectingChange?.(isSelecting);
        setIsDrawingActive(isSelecting);
      },
      [onIsSelectingChange],
    );

    const drawNewAnnotation: DrawPercentageRectOnCanvas = useCallback(
      ({ canvas, rect }) =>
        drawClearedBoundingBoxOnCanvas({ canvas, box: rect, strokeColor, borderWidth: BORDER_WIDTH }),
      [strokeColor],
    );

    // Give clicked annotation boxes prominence.

    const annotationBoxToDraw = useMemo<PercentageRect | undefined>(() => {
      if (!annotationToDraw) {
        return undefined;
      }

      return {
        pctEndX: annotationToDraw?.dimensions.width || 0,
        pctEndY: annotationToDraw?.dimensions.height || 0,
        pctStartX: annotationToDraw?.coordinates.x || 0,
        pctStartY: annotationToDraw?.coordinates.y || 0,
      };
    }, [annotationToDraw]);

    const drawBoxes = useCallback<BoundingBoxLayerProps<PercentageRect>['drawBoxes']>(
      ({ boxes, canvas }) => {
        if (boxes.length === 0) {
          return;
        }
        drawClearedBoundingBoxOnCanvas({
          canvas,
          box: boxes[0],
          strokeColor,
          borderWidth: BORDER_WIDTH,
          isEditable: !!newAnnotation,
        });
      },
      [newAnnotation, strokeColor],
    );

    const { maxY, maxX, minX } = useMemo(
      () => (newAnnotation ? getAnnotationCommentPos(newAnnotation) : { maxX: 0, maxY: 0, minX: 0 }),
      [newAnnotation],
    );

    const onBoundingBoxClear = useCallback(() => {
      setQueryParams({ [QueryParamNames.discussionId]: null }, 'replace');
      onClear();
    }, [onClear]);

    const onBoundingBoxDrawn = useCallback(
      (box: PercentageRect) => {
        const createdAnnotation: BoxAnnotation = {
          dimensions: {
            height: box.pctEndY,
            width: box.pctEndX,
          },
          coordinates: {
            x: box.pctStartX,
            y: box.pctStartY,
          },
          type: 'box',
          color: strokeColor,
          pageNumber,
        };

        onAnnotationDrawn(createdAnnotation);
      },
      [onAnnotationDrawn, pageNumber, strokeColor],
    );

    const annotationPageNumber = getPageNumber(annotationToDraw);

    const boundingBoxToDraw = useMemo(
      () =>
        annotationBoxToDraw && (annotationPageNumber === pageNumber || isUndefined(annotationPageNumber))
          ? [annotationBoxToDraw]
          : [],
      [annotationBoxToDraw, annotationPageNumber, pageNumber],
    );

    const onMouseDown = useCallback<BoundingBoxLayerMouseEventHandler>(({ event }) => {
      event.stopPropagation();
      return false;
    }, []);

    if (!containerWidth || !containerHeight) {
      return null;
    }

    return (
      <>
        <BoundingBoxLayer
          editableBoundingBoxIndex={!!newAnnotation ? 0 : undefined}
          onMouseDown={onMouseDown}
          drawNewBox={drawNewAnnotation}
          drawBoxes={drawBoxes}
          hasCustomCursor
          disabled={disabled}
          shouldDrawOverlay={shouldDrawOverlay}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
          containerLeft={containerLeft}
          containerTop={containerTop}
          boundingBoxes={boundingBoxToDraw}
          onClear={onBoundingBoxClear}
          onIsSelectingChange={_onIsSelectingChange}
          onBoundingBoxDrawn={onBoundingBoxDrawn}
        />
        {!isDrawingActive && newAnnotation && getPageNumber(newAnnotation) === pageNumber && (
          <AnnotationCommentInput
            containerRef={containerRef}
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            startX={minX}
            endX={maxX}
            endY={maxY}
            containerLeft={containerLeft}
          />
        )}
      </>
    );
  },
);

BoxAnnotationLayer.displayName = 'BoxAnnotationLayer';
