import { isUndefined } from 'lodash';
/**
 *
 * Mentions show up in the textarea within a <span> element as `Firstname Lastname`
 * However in code, it looks like `@[Firstname Lastname](some-id)`
 * This means that while the textarea element will tell you you're at position n right after the name,
 * you are actually in a much higher position within the string that is saved.
 * Mention from `react-mentions` knows how to parse these links and convert them.
 * But in order to insert, for example, an emoji after a mention, we need the real string position,
 * not just the position returned from the textarea cursor position.
 */
export const getRealStringPositionWithinMentions = (commentValue: string, position?: number) => {
  if (isUndefined(position)) return commentValue.length;
  const reg = /@\[(\w|\s)*\]\((\w|-)*\)/;

  let match = commentValue.match(reg);
  let offset = 0;
  let updatedString = commentValue;
  while (!!match) {
    if (!match.index) {
      match = null;
      offset = commentValue.length;
      break;
    }
    if (position < match.index) {
      match = null;
      break;
    }
    const str = match[0];
    const nameStart = str.indexOf('[') + 1;
    const nameEnd = str.indexOf(']');
    const nameStr = str.slice(nameStart, nameEnd);
    offset += str.length - nameStr.length;
    updatedString = updatedString.slice(0, match.index) + updatedString.slice(match.index + str.length);
    match = updatedString.match(reg);
  }
  return position + offset;
};
