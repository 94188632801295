import { type Clip, ClipStatus } from '@air/api/types';
import { formatDateVerbose } from '@air/utilities';
import classNames from 'classnames';
import { ComponentProps, memo, useMemo } from 'react';

import { AssetMobileVersionsListItemThumbnailPreview } from '~/_components/AssetMobileVersionsListItemThumbnailPreview';
import { ImgixImage } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';

export type AssetMobileVersionsItemProps = Pick<ComponentProps<'button'>, 'onClick'> & {
  isActive?: boolean;
  version: Clip;
};

export const AssetMobileVersionsItem = memo(({ isActive, onClick, version }: AssetMobileVersionsItemProps) => {
  const showPreview = useMemo(() => {
    return version.status === ClipStatus.nonTranscodable || version.status === ClipStatus.failed;
  }, [version.status]);

  const fallbackUrl = useMemo(() => {
    return !!version.assets.image && getCloudFrontUrlFromImgixUrl(version.assets.image);
  }, [version.assets.image]);

  return (
    <button className="flex w-full items-center gap-4 text-left" onClick={onClick}>
      <div
        className={classNames(
          'pointer-events-none relative h-16 w-24 shrink-0 overflow-hidden rounded-sm',
          isActive && 'ring-2 ring-blue-9 ring-offset-1 ring-offset-white dark:ring-offset-black',
        )}
      >
        <div className="absolute bottom-1 right-1 rounded-sm bg-black/80 px-1 py-0.5 text-10 font-bold uppercase tracking-wider text-grey-12">
          V{version.version}
        </div>
        {showPreview ? (
          <AssetMobileVersionsListItemThumbnailPreview ext={version.ext} />
        ) : fallbackUrl ? (
          <ImgixImage
            alt={version.title || version.importedName || ''}
            src={version.assets.image}
            fallbackUrl={fallbackUrl}
            imgixParams={{ fit: 'crop', auto: 'compress', w: 96, h: 64 }}
            height={64}
            width={96}
          />
        ) : null}
      </div>
      <div className="grow">
        <div className="truncate text-14 font-semibold text-grey-12">{version.title || version.importedName}</div>
        <div className="text-12 font-medium text-grey-11">{formatDateVerbose(version.createdAt)}</div>
      </div>
    </button>
  );
});

AssetMobileVersionsItem.displayName = 'AssetMobileVersionsItem';
