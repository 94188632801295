import { noop } from 'lodash';
import { createContext, type ProviderProps, useCallback, useContext, useMemo, useState } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type VideoTimestampContextType = {
  currentTime?: number;
  handleSetCurrentTime: (time: number) => void;
  handleClearTimestamp: () => void;
  handleSetTimestamp: (time?: number) => void;
  timestamp?: number;
};

export const VIDEO_TIMESTAMP_CONTEXT_DEFAULT_VALUE: VideoTimestampContextType = {
  currentTime: undefined,
  handleClearTimestamp: noop,
  handleSetCurrentTime: noop,
  handleSetTimestamp: noop,
  timestamp: undefined,
};

export const VideoTimestampContext = createContext<VideoTimestampContextType>(VIDEO_TIMESTAMP_CONTEXT_DEFAULT_VALUE);

export type VideoTimestampProviderProps = Omit<ProviderProps<VideoTimestampContextType>, 'value'>;

export const VideoTimestampProvider = ({ children }: VideoTimestampProviderProps) => {
  const [currentTime, setCurrentTime] = useState<number | undefined>(undefined);
  const [timestamp, setTimestamp] = useState<number | undefined>(undefined);

  const handleClearTimestamp = useCallback(() => {
    setTimestamp(undefined);
  }, []);

  const handleSetCurrentTime = useCallback((time: number) => {
    setCurrentTime(time);
  }, []);

  const handleSetTimestamp = useCallback(
    (time?: number) => {
      setTimestamp(time ?? currentTime);
    },
    [currentTime],
  );

  const values = useMemo<VideoTimestampContextType>(
    () => ({
      currentTime,
      handleClearTimestamp,
      handleSetCurrentTime,
      handleSetTimestamp,
      timestamp,
    }),
    [currentTime, handleClearTimestamp, handleSetCurrentTime, handleSetTimestamp, timestamp],
  );

  return <VideoTimestampContext.Provider value={values}>{children}</VideoTimestampContext.Provider>;
};

export const useVideoTimestampContext = () => {
  const context = useContext(VideoTimestampContext);

  if (context === VIDEO_TIMESTAMP_CONTEXT_DEFAULT_VALUE) {
    const error = 'useVideoTimestampContext must be used within a VideoTimestampProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
