import { ScopedPermissions } from '@air/api';
import { createContext, memo, PropsWithChildren, useContext, useMemo } from 'react';

type DiscussionPermissionsProviderContextValue =
  | {
      permissions: ScopedPermissions | undefined;
    }
  | undefined;

const DiscussionPermissionsProviderContext = createContext<DiscussionPermissionsProviderContextValue>(undefined);

export type DiscussionPermissionsProviderProps = PropsWithChildren<DiscussionPermissionsProviderContextValue>;

export const DiscussionPermissionsProvider = memo(({ children, permissions }: DiscussionPermissionsProviderProps) => {
  const value = useMemo(() => ({ permissions }), [permissions]);

  return (
    <DiscussionPermissionsProviderContext.Provider value={value}>
      {children}
    </DiscussionPermissionsProviderContext.Provider>
  );
});

DiscussionPermissionsProvider.displayName = 'DiscussionPermissionsProvider';

export function useDiscussionPermissions() {
  const context = useContext(DiscussionPermissionsProviderContext);

  if (!context) {
    throw 'DiscussionPermissionsProviderContext used outside of DiscussionPermissionsProvider';
  }

  return context;
}
