import { useRouter } from 'next/router';

import { getShortIdFromPath } from '~/utils/PathUtils';

/**
 * @deprecated
 * Use `useShortIdContext` instead which reads from context is guaranteed to be there
 */
export const useURLShortIdSelector = () => {
  const { asPath } = useRouter();
  const shortId = getShortIdFromPath(asPath);

  return shortId;
};
