import { type ComponentProps, memo } from 'react';

export type ContentProps = Pick<ComponentProps<'div'>, 'children'>;

export const Content = memo(({ children }: ContentProps) => {
  return (
    <div className="flex h-full w-full">
      <div className="flex min-h-full w-full flex-col items-start justify-start rounded-lg bg-grey-1 transition-colors">
        {children}
      </div>
    </div>
  );
});

Content.displayName = 'Content';
