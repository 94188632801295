import {
  BooleanClipMetadata,
  ClipMetadata,
  DateTimeClipMetadata,
  NumberArrayClipMetadata,
  NumberClipMetadata,
  StringArrayClipMetadata,
  StringClipMetadata,
} from '@air/api';
import { ObjectArrayClipMetadata } from '@air/api/build/clips';
import { ObjectClipMetadata } from '@air/api/dist/clips';
import { format } from 'date-fns';

export const isStringMetadataType = (field: ClipMetadata): field is StringClipMetadata => field.type === 'string';

export const isStringArrayMetadataType = (field: ClipMetadata): field is StringArrayClipMetadata =>
  field.type === 'string[]';

export const isBooleanMetadataType = (field: ClipMetadata): field is BooleanClipMetadata => field.type === 'boolean';

export const isNumberMetadataType = (field: ClipMetadata): field is NumberClipMetadata => field.type === 'number';

export const isNumberArrayMetadataType = (field: ClipMetadata): field is NumberArrayClipMetadata =>
  field.type === 'number[]';

export const isDateTimeMetadataType = (field: ClipMetadata): field is DateTimeClipMetadata => field.type === 'dateTime';

export const isObjectMetadataType = (field: ClipMetadata): field is ObjectClipMetadata => field.type === 'object';

export const isObjectArrayMetadataType = (field: ClipMetadata): field is ObjectArrayClipMetadata =>
  field.type === 'object[]';

export const formatMetadataDate = (field: ClipMetadata, formatString: string) => {
  if (!isDateTimeMetadataType(field)) {
    return null;
  }

  try {
    return format(new Date(field.data), formatString);
  } catch (_e) {
    return null;
  }
};
