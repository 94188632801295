import { DiscussionLocation, useTrackAddedComment } from '@air/analytics';
import { Account, Annotation } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { useQueryClient } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';

import { getAnnotationAnalyticsType, hasAnnotation } from '~/components/Annotations/shared/annotationUtils';
import { NEW_DISCUSSION_ID } from '~/components/Annotations/shared/useActiveDiscussionIdObserver';
import { useUpdateStateAfterAddingOrRemovingDiscussion } from '~/components/Discussions/utils';
import { getDiscussionsKey } from '~/constants/react-query-keys';
import { QueryParamNames } from '~/constants/search';
import { useURLShortIdSelector } from '~/hooks/useURLShortIdSelector';
import { useAnnotationContext } from '~/providers/AnnotationProvider/AnnotationProvider';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { centralizedClipSelector, centralizedClipTypeSelector } from '~/store/centralizedClip/selectors';
import { activeDiscussionIdParamSelector } from '~/store/router/selectors';
import { getAccountKey } from '~/swr-hooks/account/useAccount';
import { useCreateDiscussion } from '~/swr-hooks/discussions/useCreateDiscussion';
import { setQueryParams } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useSubmitNewDiscussion = () => {
  const { setActiveAnnotation } = useAnnotationContext();
  const store = useAirStore();
  const { createDiscussion } = useCreateDiscussion();
  const shortId = useURLShortIdSelector();
  const { trackAddedComment } = useTrackAddedComment();
  const queryClient = useQueryClient();

  const { showToast } = useToasts();

  const { updateStateAfterAddingOrRemovingDiscussion } = useUpdateStateAfterAddingOrRemovingDiscussion();

  const submitNewDiscussion = useCallback(
    async ({
      newComment,
      timestamp,
      trackLocation,
      annotation,
    }: {
      newComment: string;
      timestamp?: number;
      trackLocation: DiscussionLocation;
      annotation: Annotation | undefined;
    }) => {
      const account = queryClient.getQueryData<Account>(getAccountKey());
      const board = centralizedBoardSelector(store.getState());
      const clip = centralizedClipSelector(store.getState());

      if (!account) return;
      trackAddedComment({
        boardId: board?.id,
        clipId: clip.id,
        comment: newComment,
        location: trackLocation,
        startedDiscussion: true,
        has_timestamp: !isUndefined(timestamp),
        has_annotation: !!annotation,
        public: trackLocation === 'public-board',
        annotationType: getAnnotationAnalyticsType(annotation),
        assetType: centralizedClipTypeSelector(store.getState()),
      });

      updateStateAfterAddingOrRemovingDiscussion({
        newCommentCount: clip.openCommentCount ? clip.openCommentCount + 1 : 1,
        newDiscussionCount: clip.openDiscussionCount ? clip.openDiscussionCount + 1 : 1,
        boardId: board?.id,
        clipId: clip.id,
      });

      try {
        const createdDiscussion = await createDiscussion({
          assetId: clip.assetId,
          account,
          board: { id: board?.id, title: board?.title },
          clipId: clip.id,
          key: getDiscussionsKey({
            assetId: clip.assetId,
            boardId: board?.id,
            shortId,
            // for public boards we put clipId in request
            clipId: !!shortId ? clip.id : undefined,
          }),
          newComment,
          shortId,
          timestamp,
          type: 'clip',
          currentVersion: clip.version,
          annotation: annotation
            ? {
                ...annotation,
                timestamp,
              }
            : undefined,
          beforeCacheUpdate: async (discussion) => {
            if (hasAnnotation(discussion)) {
              await setQueryParams({ [QueryParamNames.discussionId]: NEW_DISCUSSION_ID }, 'replace');
              setActiveAnnotation(discussion.annotation);
            }
          },
        });

        if (createdDiscussion) {
          const activeDiscussionId = activeDiscussionIdParamSelector(store.getState());
          // we have to check if activeDiscussionId is NEW_DISCUSSION_ID, because user can click another comment before this request is finished
          // or video may be played
          if (
            activeDiscussionId === NEW_DISCUSSION_ID &&
            'annotation' in createdDiscussion &&
            hasAnnotation(createdDiscussion)
          ) {
            setQueryParams({ [QueryParamNames.discussionId]: createdDiscussion.id }, 'replace');
          }
        }
      } catch (_error) {
        showToast('Failed to create discussion', { color: 'red' });
      }
    },
    [
      queryClient,
      store,
      trackAddedComment,
      updateStateAfterAddingOrRemovingDiscussion,
      createDiscussion,
      shortId,
      setActiveAnnotation,
      showToast,
    ],
  );

  return {
    submitNewDiscussion,
  };
};
