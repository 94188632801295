import { CommentResponse } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, useCallback, useEffect, useRef } from 'react';

import { CommentDisplay, CommentDisplayProps } from '~/components/Discussions/CommentDisplay';
import { DiscussionForm, DiscussionFormHandle, MentionsData } from '~/components/Discussions/DiscussionForm';
import { DISCUSSION_COMMENT_BODY, EDIT_COMMENT_FORM } from '~/constants/testIDs';

export interface DiscussionCommentContentProps {
  comment: CommentResponse;
  mentionsList: MentionsData[];
  onSave?: (newComment: string) => void;
  shouldShowMentionsButton?: boolean;
  isEditing: boolean;
  cancelEditing: () => void;
  metadata?: CommentDisplayProps['metadata'];
}

export const DiscussionCommentContent = memo(
  ({
    comment,
    mentionsList,
    onSave,
    shouldShowMentionsButton,
    isEditing,
    cancelEditing,
    metadata,
  }: DiscussionCommentContentProps) => {
    const commentForm = useRef<DiscussionFormHandle>(null);

    const { isAboveMediumScreen } = useBreakpointsContext();

    useEffect(() => {
      if (isEditing) {
        commentForm.current?.focus();
      }
    }, [isEditing]);

    const onCommentSubmit = useCallback(
      (body: string) => {
        onSave?.(body);
        cancelEditing();
      },
      [cancelEditing, onSave],
    );

    return (
      <>
        {isEditing && onSave ? (
          <div className="discussion-form-container">
            <DiscussionForm
              data-testid={EDIT_COMMENT_FORM}
              ref={commentForm}
              comment={comment.body}
              hasCancelButton={isEditing && !isAboveMediumScreen}
              mentionsList={mentionsList}
              onCancel={cancelEditing}
              onSubmit={onCommentSubmit}
              shouldShowMentionsButton={isAboveMediumScreen && shouldShowMentionsButton}
            />
          </div>
        ) : (
          <div className="mr-4 flex w-fit">
            <CommentDisplay
              className="select-text"
              metadata={metadata}
              text={comment.body}
              data-testid={DISCUSSION_COMMENT_BODY}
              mentionsList={mentionsList}
            />
          </div>
        )}
      </>
    );
  },
);

DiscussionCommentContent.displayName = 'DiscussionCommentContent';
