import { ClipStatus, ClipType } from '@air/api/types';
import { Skeleton } from '@air/component-skeleton';
import { useBreakpointsContext } from '@air/provider-media-query';
import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentType, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useNoPreviewInVisualizer } from '~/hooks/useNoPreviewInVisualizer';
import {
  centralizedClipIsLoadingSelector,
  centralizedClipIsPDFTypeSelector,
  centralizedClipPDFAssetSelector,
  centralizedClipStatusSelector,
  centralizedClipTypeSelector,
} from '~/store/centralizedClip/selectors';
import ClipUtils from '~/utils/ClipUtils';

import { FileVisualizer } from './components/FileVisualizer';
import { MobileImageVisualizer } from './components/MobileImageVisualizer';
import { NoPreviewVisualizer } from './components/NoPreviewVisualizer';

enum VISUALIZER_TYPE {
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  LOADER = 'LOADER',
  NOT_VIEWABLE = 'NOT_VIEWABLE',
  PROCESSING = 'PROCESSING',
  FILE = 'FILE',
}

export interface VisualizerProps {
  VideoVisualizer?: ComponentType;
  PDFVisualizer: ComponentType;
  ImageVisualizer: ComponentType;
}

export const Visualizer = memo(({ VideoVisualizer, ImageVisualizer, PDFVisualizer }: VisualizerProps) => {
  const isLoading = useSelector(centralizedClipIsLoadingSelector);
  const clipType = useSelector(centralizedClipTypeSelector);
  const clipStatus = useSelector(centralizedClipStatusSelector);
  const clipPDFAsset = useSelector(centralizedClipPDFAssetSelector);
  const { isAboveMediumScreen } = useBreakpointsContext();
  const isPdfRenderedFile = useSelector(centralizedClipIsPDFTypeSelector);
  const { noPreviewInVisualizer } = useNoPreviewInVisualizer();

  const visualizerType = useMemo<VISUALIZER_TYPE | null>(() => {
    if (isLoading) {
      return VISUALIZER_TYPE.LOADER;
    }

    if (!clipType) {
      return null;
    }

    if (isPdfRenderedFile) {
      if (!!clipPDFAsset) {
        return VISUALIZER_TYPE.PDF;
      } else {
        return VISUALIZER_TYPE.PROCESSING;
      }
    }

    if (clipType === ClipType.nonMedia || clipStatus === ClipStatus.nonTranscodable) {
      return VISUALIZER_TYPE.FILE;
    }

    if (ClipUtils.isFailed(clipStatus)) {
      return VISUALIZER_TYPE.NOT_VIEWABLE;
    }

    /**
     * Show the processing state for all processing nonvideo assets
     * Show processing state for videos if video url isn't returned from websocket yet
     * When it is returned we know we have watchable video while the rest processes
     *  */
    if (noPreviewInVisualizer) {
      return VISUALIZER_TYPE.PROCESSING;
    }

    if (clipType === ClipType.photo || clipType === ClipType.animated) {
      return VISUALIZER_TYPE.IMAGE;
    }

    return VISUALIZER_TYPE.VIDEO;
  }, [clipPDFAsset, clipStatus, clipType, isLoading, isPdfRenderedFile, noPreviewInVisualizer]);

  const clipVisual = useMemo(() => {
    switch (visualizerType) {
      case VISUALIZER_TYPE.LOADER: {
        return <Skeleton width={300} />;
      }
      case VISUALIZER_TYPE.FILE: {
        return <FileVisualizer />;
      }
      case VISUALIZER_TYPE.NOT_VIEWABLE: {
        return <NoPreviewVisualizer />;
      }
      case VISUALIZER_TYPE.PDF: {
        return <PDFVisualizer />;
      }
      case VISUALIZER_TYPE.PROCESSING: {
        return <NoPreviewVisualizer isProcessing={true} />;
      }
      case VISUALIZER_TYPE.IMAGE: {
        if (!isAboveMediumScreen) return <MobileImageVisualizer />;
        return <ImageVisualizer />;
      }
      case VISUALIZER_TYPE.VIDEO: {
        return VideoVisualizer ? <VideoVisualizer /> : null;
      }
      default:
        return null;
    }
  }, [visualizerType, PDFVisualizer, isAboveMediumScreen, ImageVisualizer, VideoVisualizer]);

  const isPDF = visualizerType === VISUALIZER_TYPE.PDF;
  const isVideo = visualizerType === VISUALIZER_TYPE.VIDEO;

  return (
    <div
      className={tailwindMerge(
        'group/visualizer relative flex min-h-0 w-full flex-1 items-center justify-center overflow-hidden p-6',
        isVideo && 'pb-3 pl-6 pr-6 pt-6',
        (!isAboveMediumScreen || isPDF) && 'p-0',
      )}
    >
      <div className="flex h-full w-full items-center justify-center">{clipVisual}</div>
    </div>
  );
});

Visualizer.displayName = 'Visualizer';
