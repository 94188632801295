import { ClipMetadata } from '@air/api';

import { isStringMetadataType } from '~/utils/iptcDataTypes';

export const getCameraSectionTitle = ({
  makeMetadata,
  modelMetadata,
}: {
  makeMetadata?: ClipMetadata;
  modelMetadata?: ClipMetadata;
}) => {
  const make = makeMetadata ? (isStringMetadataType(makeMetadata) ? makeMetadata.data : '') : '';
  const model = modelMetadata ? (isStringMetadataType(modelMetadata) ? modelMetadata.data : '') : '';

  if (!make && !model) {
    return 'Unknown';
  }

  if (!make) {
    return model;
  }

  if (!model) {
    return make;
  }

  return `${make}, ${model}`;
};
