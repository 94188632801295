import { Discussions } from '@air/api';
import { Account } from '@air/api/types';
import { Query, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { getAccountKey } from '~/swr-hooks/account/useAccount';
import { ClipDiscussionsCacheType } from '~/swr-hooks/discussions/types';
import { getCommentParams, insertCommentToDiscussion, isClipDiscussionsKey } from '~/swr-hooks/discussions/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export interface CreateCommentParams {
  existingDiscussion: ClipDiscussion;
  newComment: string;
  boardId?: string;
  shortId?: string;
}

export const useCreateComment = () => {
  const queryClient = useQueryClient();

  const createComment = useCallback(
    async ({ existingDiscussion, newComment, shortId, boardId }: CreateCommentParams) => {
      const account = queryClient.getQueryData<Account>(getAccountKey());
      if (!account) return;

      try {
        const comment = getCommentParams(newComment, existingDiscussion);

        const queryKeyPredicate = ({ queryKey }: Query) =>
          isClipDiscussionsKey(queryKey) && queryKey[1].assetId === existingDiscussion.assetId;

        queryClient.setQueriesData<ClipDiscussionsCacheType>(
          {
            predicate: queryKeyPredicate,
          },
          (data) =>
            insertCommentToDiscussion({
              newComment: {
                ...comment,
                account,
              },
              data,
              discussionId: existingDiscussion.id,
            }),
        );

        // TODO: fix boardId assertion once backend updates with proper typings.
        // BOARDID *IS* POSSIBLY UNDEFINED
        // see: https://github.com/AirLabsTeam/backend/pull/2783
        await Discussions.reply({ comment, boardId: boardId!, shortId });

        queryClient.invalidateQueries({ predicate: queryKeyPredicate });
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to create comment',
          metadata: { data: { account, existingDiscussion, newComment, shortId, boardId } },
        });
      }
    },
    [queryClient],
  );

  return {
    createComment,
  };
};
