import { Annotation, Clip, CommentResponse } from '@air/api/types';
import { DiscussionsComment, DiscussionsCommentProps } from '@air/component-discussions';
import { formatFullName, formatUpdatedAt, parseAirDateToISO } from '@air/utilities';
import { useCurrentUserSession } from '@air/utils-auth/src/hooks/useCurrentUserSession';
import { CSSProperties, memo, PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';

import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { CommentAvatar } from '~/components/Discussions/Discussion/DiscussionComment/components/CommentAvatar';
import {
  CommentMenu,
  CommentMenuProps,
} from '~/components/Discussions/Discussion/DiscussionComment/components/CommentMenu';
import { DiscussionCommentContent } from '~/components/Discussions/Discussion/DiscussionComment/components/DiscussionCommentContent';
import { DiscussionVersionNumber } from '~/components/Discussions/Discussion/DiscussionComment/components/DiscussionVersionNumber';
import { ReopenDiscussionButton } from '~/components/Discussions/Discussion/DiscussionComment/components/ReopenDiscussionButton';
import { useCommentEditing } from '~/components/Discussions/Discussion/shared/useCommentEditing';
import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { useDiscussionPermissions } from '~/components/Discussions/providers/DiscussionPermissionsProvider';
import { useAccountContext } from '~/providers/AccountProvider';
import { isCommentAuthor } from '~/utils/comments';
import { canEditAssetDiscussions } from '~/utils/permissions/assetPermissions';

export type DiscussionCommentProps = Pick<DiscussionsCommentProps, 'className' | 'secondaryActions'> & {
  discussion: ClipDiscussion;
  comment: CommentResponse;

  onSave?: (params: {
    newComment: string;
    comment: CommentResponse;
    clipId: Clip['id'];
    timestamp?: number;
    annotation?: Annotation;
  }) => void;

  onDelete?: CommentMenuProps['onDelete'];
  onToggleResolve?: CommentMenuProps['onToggleResolve'];

  currentVersion?: number;

  onSwitchVersion?: ((clipId: string) => void) | undefined;
  mentionsList: MentionsData[];
  shouldShowMentionsButton?: boolean;

  style?: CSSProperties;
  commentMetadata?: ReactNode;
  showAvatar?: boolean;
};

export const DiscussionComment = memo(
  ({
    discussion,
    className,
    comment,
    onSwitchVersion,
    mentionsList,
    shouldShowMentionsButton,
    style,
    currentVersion,
    onSave,
    onDelete,
    onToggleResolve,
    commentMetadata,
    secondaryActions,
  }: PropsWithChildren<DiscussionCommentProps>) => {
    const { disableEditing, enableEditing, isEditing } = useCommentEditing();
    const { data: account } = useAccountContext();
    const { data: userSession } = useCurrentUserSession();
    const isTopLevelComment = discussion.comments.data[0].id === comment.id;
    const isAuthor = isCommentAuthor({ comment, account, userSession });
    const { permissions } = useDiscussionPermissions();

    const canResolve = isTopLevelComment && onToggleResolve && canEditAssetDiscussions(permissions);
    const canEdit = onSave && isAuthor && canEditAssetDiscussions(permissions);
    const canDelete = onDelete && isAuthor && canEditAssetDiscussions(permissions);

    const commentMenu = useMemo(
      () =>
        discussion.resolved && isTopLevelComment && canResolve ? (
          <ReopenDiscussionButton onToggleResolve={onToggleResolve} discussion={discussion} />
        ) : (
          <CommentMenu
            comment={comment}
            discussion={discussion}
            onToggleResolve={canResolve ? onToggleResolve : undefined}
            onDelete={canDelete ? onDelete : undefined}
            onEdit={canEdit ? enableEditing : undefined}
          />
        ),
      [
        discussion,
        isTopLevelComment,
        canResolve,
        onToggleResolve,
        comment,
        canDelete,
        onDelete,
        canEdit,
        enableEditing,
      ],
    );

    const commentAvatar = useMemo(
      () => <CommentAvatar account={comment.account} isResolved={discussion.resolved} />,
      [comment.account, discussion.resolved],
    );

    const onSaveClick = useCallback(
      (newComment: string) => {
        onSave?.({
          newComment,
          comment,
          clipId: discussion.clipId,
          timestamp: discussion.timestamp,
          annotation: discussion.annotation,
        });
      },
      [onSave, comment, discussion],
    );

    const headerVersion = useMemo(
      () =>
        onSwitchVersion && isTopLevelComment && discussion.assetVersion ? (
          <DiscussionVersionNumber
            discussionVersion={discussion.assetVersion}
            onVersionClick={
              discussion.assetVersion !== currentVersion
                ? () => {
                    onSwitchVersion?.(discussion.clipId);
                  }
                : undefined
            }
          />
        ) : null,
      [currentVersion, discussion.assetVersion, discussion.clipId, isTopLevelComment, onSwitchVersion],
    );

    return (
      <DiscussionsComment
        actions={commentMenu}
        author={formatFullName(comment.account.firstName, comment.account.lastName)}
        avatar={commentAvatar}
        className={className}
        comment={
          <DiscussionCommentContent
            key={comment.id}
            isEditing={isEditing}
            cancelEditing={disableEditing}
            comment={comment}
            onSave={canEdit ? onSaveClick : undefined}
            mentionsList={mentionsList}
            shouldShowMentionsButton={shouldShowMentionsButton}
            metadata={commentMetadata}
          />
        }
        data-comment-id={comment.id}
        meta={headerVersion}
        secondaryActions={secondaryActions}
        style={style}
        timestamp={formatUpdatedAt(parseAirDateToISO(comment.createdAt).getTime())}
      />
    );
  },
);

DiscussionComment.displayName = 'DiscussionComment';
