import { noop } from 'lodash';
import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export interface FullscreenContextValues {
  isFullscreen: boolean;
  setIsFullscreen: (isFullscreen: boolean) => void;
}

const DefaultValue: FullscreenContextValues = {
  isFullscreen: false,
  setIsFullscreen: noop,
};

export const FullscreenContext = createContext<FullscreenContextValues>(DefaultValue);

interface FullscreenProviderProps {
  children: ReactNode;
}

export const FullscreenContextProvider = memo(({ children }: FullscreenProviderProps) => {
  const [_isFullscreen, changeIsFullscreen] = useState(false);

  const setIsFullscreen = useCallback((isFullscreen: boolean) => {
    changeIsFullscreen(isFullscreen);
  }, []);

  const values = useMemo(() => {
    return {
      isFullscreen: _isFullscreen,
      setIsFullscreen,
    };
  }, [_isFullscreen, setIsFullscreen]);

  return <FullscreenContext.Provider value={values}>{children}</FullscreenContext.Provider>;
});

FullscreenContextProvider.displayName = 'FullscreenContextProvider';

export const useFullscreenContext = () => {
  const values = useContext(FullscreenContext);

  if (values === DefaultValue) {
    console.error('FullscreenContext used outside of FullscreenContextProvider');
  }

  return values;
};
