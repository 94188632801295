import { ComponentPropsWithoutRef, forwardRef, memo } from 'react';

// TODO: Once https://airlabs.atlassian.net/browse/BUGS-72 is fixed
// we can go back to just `video`. ALso account for `src`
//and remove the ts-ignore above poster prop
type VideoPlayerProps = ComponentPropsWithoutRef<'video'> & { type: 'video' };
type AudioPlayerProps = ComponentPropsWithoutRef<'audio'> & { type: 'audio' };

type FilePlayerProps = VideoPlayerProps | AudioPlayerProps;

const _FilePlayer = forwardRef<HTMLVideoElement, FilePlayerProps>(
  ({ type, ...props }: FilePlayerProps, forwardedRef) => {
    switch (type) {
      case 'video':
        return <video ref={forwardedRef} {...props} />;
      case 'audio':
        return <audio ref={forwardedRef} {...props} />;
    }
  },
);

_FilePlayer.displayName = 'FilePlayer';

export const FilePlayer = memo(_FilePlayer) as typeof _FilePlayer;

FilePlayer.displayName = 'FilePlayer';
