import { KeyboardShortcut } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';

import { KeyboardShortcutsInfoModal } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/KeyboardShortcutsInfoModal';
import { KEYBOARD_SHORTCUTS_BUTTON } from '~/constants/testIDs';

export const KeyboardShortcutsInfoButton = memo(() => {
  const [showInfoModal] = useAirModal(KeyboardShortcutsInfoModal);

  return (
    <IconButton
      appearance="ghost"
      color="grey"
      data-testid={KEYBOARD_SHORTCUTS_BUTTON}
      onClick={() => showInfoModal()}
      icon={KeyboardShortcut}
      label="Keyboard shortcuts info"
      size="large"
    />
  );
});

KeyboardShortcutsInfoButton.displayName = 'KeyboardShortcutsInfoButton';
