import { memo, PropsWithChildren } from 'react';

interface AncestorImageContainerProps {
  backgroundColor: string;
}

export const AncestorImageContainer = memo(
  ({ backgroundColor, children }: PropsWithChildren<AncestorImageContainerProps>) => {
    return (
      <div className="-ml-0.5 mr-1 flex h-5 w-5 items-center justify-center rounded" style={{ backgroundColor }}>
        {children}
      </div>
    );
  },
);

AncestorImageContainer.displayName = 'AncestorImageContainer';
