import { tailwindMerge } from '@air/tailwind-variants';
import { isNumber, isString, isUndefined } from 'lodash';
import { useRouter } from 'next/router';
import { memo, PropsWithChildren } from 'react';

import { ANNOTATION_COMMENT_ID } from '~/components/Annotations/AnnotationsAvatarsContainer/AnnotationAvatarContainer/components/AnnotationComment';
import { hasAnnotation } from '~/components/Annotations/shared/annotationUtils';
import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { isViewingDiscussionWithId } from '~/store/router/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface TopCommentContainerProps {
  discussion: ClipDiscussion;
  onClick: () => void;
}

export const TopCommentContainer = memo(
  ({ discussion, children, onClick }: PropsWithChildren<TopCommentContainerProps>) => {
    const { query } = useRouter();
    const isDiscussionActive = useAirSelector((st) => isViewingDiscussionWithId(st, discussion.id));
    const timestamp = isString(query.ts) ? parseFloat(query.ts) : undefined;
    const isTimestampActive = isNumber(timestamp) && timestamp === discussion.timestamp;
    const isAnnotation = hasAnnotation(discussion);

    return isAnnotation || !isUndefined(discussion.timestamp) ? (
      <div
        className={tailwindMerge(
          'cursor-pointer hover:bg-grey-3',
          isDiscussionActive ? 'bg-grey-3' : 'bg-transparent',
          isTimestampActive ? 'bg-grey-3' : undefined,
          isDiscussionActive ? '[&>.comment-menu]:opacity-100' : undefined,
        )}
        /**
         * Use to prevent the mousedown event listener from firing when the user clicks on the comment.
         * This event listener is located in:
         * `/src/components/Annotations/shared/useClearAnnotationOnMouseDownOutside.ts`
         */
        id={isAnnotation ? ANNOTATION_COMMENT_ID : undefined}
        onClick={onClick}
        role="button"
      >
        {children}
      </div>
    ) : (
      <>{children}</>
    );
  },
);

TopCommentContainer.displayName = 'TopCommentContainer';
