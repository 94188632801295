import { DiscussionAnnotationType } from '@air/analytics';
import type { Annotation, BoxAnnotation } from '@air/api/types';
import { isEmpty } from 'lodash';

import { ClipDiscussion, FreeformAnnotation } from '~/components/Annotations/shared/types';
import { Point } from '~/components/BoundingBox/types';

export interface PencilAnnotationRect {
  minX: number;
  maxX: number;
  maxY: number;
}

const calculatePencilAnnotationRect = (paths: Point[][]): PencilAnnotationRect => {
  let minX = Number.MAX_VALUE;
  let maxX = 0;
  let maxY = 0;
  paths.forEach((path) =>
    path.forEach(({ y, x }) => {
      if (x < minX) minX = x;
      if (x > maxX) {
        maxX = x;
        maxY = y;
      }
    }),
  );

  return {
    minX,
    maxX,
    maxY,
  };
};

export const isAnnotationCorrect = (annotation?: Annotation): annotation is Annotation => {
  if (!annotation || isEmpty(annotation)) {
    return false;
  } else if (!annotation.type || annotation.type === 'box') {
    return !!annotation.coordinates && !!annotation.dimensions;
  } else if (annotation.type === 'pencil') {
    return annotation.lines?.length > 0 && !!annotation.lines[0].coordinates;
  }
  return false;
};

export const getAnnotationCommentPos = (annotation?: FreeformAnnotation) => {
  if (!annotation || !isAnnotationCorrect(annotation)) {
    return {
      minX: 0,
      maxX: 0,
      maxY: 0,
    };
  }
  switch (annotation?.type) {
    case 'pencil':
      return calculatePencilAnnotationRect(annotation.lines.map(({ coordinates }) => coordinates.map((c) => c)));
    case 'box':
    case undefined:
      return {
        minX: annotation.coordinates.x,
        maxX: annotation.dimensions.width,
        maxY: annotation.dimensions.height,
      };
    default:
      return { minX: 0, maxX: 0, maxY: 0 };
  }
};

export const isPointAnnotation = ({ dimensions, coordinates }: Pick<BoxAnnotation, 'coordinates' | 'dimensions'>) =>
  coordinates.x === dimensions.width && coordinates.y === dimensions.height;

export const getPageNumber = (annotation?: FreeformAnnotation) => {
  switch (annotation?.type) {
    case 'pencil':
      return annotation.lines[0]?.pageNumber;
    case 'box':
    case undefined:
      return annotation?.pageNumber;
  }
};

/**
 * Backend returns empty object if annotation exists instead of undefined/null, so this is a workaround to easy check if annotation exists
 * Moreover, there are some incorrect objects in db, which does not contain dimensions or coordinates - do not consider them as annotations
 * And (maybe this will be fixed by migration) old annotations do not have type - consider them all as 'box' annotations
 * @param discussion
 */
export const hasAnnotation = (discussion: Pick<ClipDiscussion, 'annotation'>) =>
  isAnnotationCorrect(discussion.annotation);

export const getAnnotationAnalyticsType = (annotation?: Annotation): DiscussionAnnotationType => {
  if (!isAnnotationCorrect(annotation)) {
    return 'standalone';
  } else {
    if (annotation.type === 'pencil') return 'pencil';
    else if (isPointAnnotation(annotation)) return 'point';
    else return 'area';
  }
};
