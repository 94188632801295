import React, { memo, PropsWithChildren, ReactNode } from 'react';

export interface InfoPanelProps {
  title: ReactNode;
}

export const InfoPanel = memo(({ title, children }: PropsWithChildren<InfoPanelProps>) => {
  return (
    <div data-testid="INFO_PANEL" className="flex w-full flex-col pb-8">
      {title}
      <div className="w-full px-4 pt-1">{children}</div>
    </div>
  );
});

InfoPanel.displayName = 'InfoPanel';
