import { AnnotationOutlinePlus } from '@air/next-icons';
import { IconButton, IconButtonProps } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo } from 'react';

import { ANNOTATE_ACTION_BUTTON } from '~/constants/testIDs';

export type AnnotateActionButtonProps = Partial<IconButtonProps> & {
  isActive: boolean;
  shouldDisableTimestampsAndAnnotations: boolean;
};

export const AnnotateActionButton = memo(
  ({ isActive, shouldDisableTimestampsAndAnnotations, ...restOfProps }: AnnotateActionButtonProps) => {
    return (
      <Tooltip
        label={
          shouldDisableTimestampsAndAnnotations
            ? 'Annotation commenting is disabled during video processing'
            : 'Add contextual comments'
        }
        side="top"
      >
        <IconButton
          appearance={isActive ? 'filled' : 'ghost'}
          color={isActive ? 'blue' : 'grey'}
          disabled={shouldDisableTimestampsAndAnnotations}
          icon={AnnotationOutlinePlus}
          label="Annotate"
          data-test-id={ANNOTATE_ACTION_BUTTON}
          size="small"
          {...restOfProps}
        />
      </Tooltip>
    );
  },
);

AnnotateActionButton.displayName = 'AnnotateActionButton';
