import { memo } from 'react';

import { InfoPanelDetailDescription } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/IntoPanelDetailDescription';
import { InfoPanelDetailTitle } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/IntoPanelDetailTitle';

interface InfoPanelDetailValueProps {
  value: string;
  additionalInfo?: string;
}

export const InfoPanelDetailValue = memo(({ additionalInfo, value }: InfoPanelDetailValueProps) => {
  return (
    <div>
      <InfoPanelDetailTitle>{value}</InfoPanelDetailTitle>
      {!!additionalInfo && <InfoPanelDetailDescription>{additionalInfo}</InfoPanelDetailDescription>}
    </div>
  );
});

InfoPanelDetailValue.displayName = 'InfoPanelDetailValue';
