import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { SharedImigixAndSourceProps } from 'react-imgix';

import { ImgixImage } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';

import { THUMBNAIL_HEIGHT } from '../../../shared/constants';

const THUMBNAIL_WIDTH = 112;
const IMGX_OPTIONS: SharedImigixAndSourceProps['imgixParams'] = {
  fit: 'crop',
  auto: 'compress',
  w: THUMBNAIL_WIDTH,
  h: THUMBNAIL_HEIGHT,
};

interface VersionImageThumbnailProps {
  src: string;
  isCurrentVersion: boolean;
}

export const VersionImageThumbnail = memo(({ src, isCurrentVersion }: VersionImageThumbnailProps) => {
  const fallbackUrl = useMemo(() => {
    if (src) return getCloudFrontUrlFromImgixUrl(src);
    return '';
  }, [src]);

  return (
    <div
      className={classNames(
        'asset-modal-version-thumbnail pointer-events-none box-border w-full overflow-hidden rounded-sm border border-black',
        isCurrentVersion ? 'shadow-[0px_0px_0px_2px] shadow-grey-10' : '',
      )}
      style={{
        height: THUMBNAIL_HEIGHT,
      }}
    >
      <ImgixImage
        className="pointer-events-none object-cover"
        alt=""
        src={src}
        fallbackUrl={fallbackUrl}
        imgixParams={IMGX_OPTIONS}
        height={THUMBNAIL_HEIGHT}
        width={THUMBNAIL_WIDTH}
      />
    </div>
  );
});

VersionImageThumbnail.displayName = 'VersionImageThumbnail';
