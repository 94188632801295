import { useShowOnActivity } from '@air/hook-use-show-on-activity';
import classNames from 'classnames';
import { memo, MouseEvent as ReactMouseEvent, useCallback, useEffect, useRef, useState } from 'react';

import { useFullscreenContext } from '~/components/AssetModal/shared/context/FullscreenContext';
import { ZoomToPointParams } from '~/components/AssetModal/Visualizer/ImageVisualizer/shared/types';
import { useFullScreen } from '~/hooks/useFullScreen';

import { ForwardedImageVisualizerClipProps, ImageVisualizerClip } from './components/ImageVisualizerClip';
import { ImageVisualizerControls } from './components/ImageVisualizerControls';
import { MAX_ZOOM, MIN_ZOOM } from './shared/constants';

const SCROLL_VELOCITY = 0.2;
const TOUCH_VELOCITY = 0.9;
const DEFAULT_TRANSITION = 0.25;
const ZOOM_INCREMENT = 0.5;
const DECELERATION_DURATION = 750;

// Inspired by: https://github.com/sylvaindubus/react-prismazoom

// Determines how many pixels the image should be moved.
const getLimitedShift = ({
  shift,
  minLimit,
  maxLimit,
  minElement,
  maxElement,
}: {
  shift: number;
  minLimit: number;
  maxLimit: number;
  minElement: number;
  maxElement: number;
}) => {
  if (shift > 0) {
    if (minElement > minLimit) {
      // Forbid move if we are moving to left or top while we are already out minimum boudaries
      return 0;
    } else if (minElement + shift > minLimit) {
      // Lower the shift if we are going out boundaries
      return minLimit - minElement;
    }
  } else if (shift < 0) {
    if (maxElement < maxLimit) {
      // Forbid move if we are moving to right or bottom while we are already out maximum boudaries
      return 0;
    } else if (maxElement + shift < maxLimit) {
      // Lower the shift if we are going out boundaries
      return maxLimit - maxElement;
    }
  }

  return shift;
};

export interface ImageVisualizerProps {
  canAnnotateClip: boolean;
  children?: ForwardedImageVisualizerClipProps['children'];
}

export const ImageVisualizer = memo(({ canAnnotateClip, children }: ImageVisualizerProps) => {
  const { setIsFullscreen } = useFullscreenContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imgContainerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const lastRequestAnimationId = useRef<number | null>(null);
  const lastCursor = useRef<{ posX: number; posY: number } | null>(null);
  const lastShift = useRef<{ x: number; y: number } | null>(null);
  const posXRef = useRef<number>(0);
  const posYRef = useRef<number>(0);
  const zoomRef = useRef<number>(MIN_ZOOM);
  const transitionDuration = useRef<number>(DEFAULT_TRANSITION);
  useShowOnActivity({ containerRef: imgContainerRef, displayRef: controlsRef });
  const [isFullScreen, toggleFullScreen] = useFullScreen(containerRef);

  const [isSelecting, setIsSelecting] = useState(false);
  const [showOriginal, setShowOriginal] = useState(false);
  const isMoving = useRef<boolean>(false);

  const [zoom, setZoom] = useState(1);

  // Repositions whatever imgRef is attached to.
  const repositionElement = ({
    newPosY,
    newPosX,
    newTransitionDuration,
    newZoom,
  }: {
    newPosY: number;
    newPosX: number;
    newTransitionDuration?: number;
    newZoom?: number;
  }) => {
    if (newZoom && newZoom !== zoomRef.current) {
      zoomRef.current = newZoom;
      setZoom(newZoom);
    }

    if (newTransitionDuration !== undefined && newTransitionDuration !== transitionDuration.current) {
      transitionDuration.current = newTransitionDuration;
      if (imageRef.current && imgContainerRef.current) {
        imageRef.current.style.transition = `transform ease-out ${newTransitionDuration}s`;
        imgContainerRef.current.style.transition = `transform ease-out ${newTransitionDuration}s`;
      }
    }

    posXRef.current = newPosX;
    posYRef.current = newPosY;
    if (imgContainerRef.current) {
      imgContainerRef.current.style.transform = `translate3d(${newPosX}px, ${newPosY}px, 0)`;
    }
    if (imageRef.current) {
      imageRef.current.style.transform = `scale(${newZoom || zoomRef.current})`;
    }
  };

  // Function that returns position coordinates if zooming in / out of a specific mouse point.
  const getNewPosition = (x: number, y: number, newZoom: number) => {
    const prevPosY = posYRef.current;
    const prevPosX = posXRef.current;

    if (newZoom === 1) {
      return [0, 0];
    }

    if (newZoom > zoomRef.current && imgContainerRef.current) {
      const rect = imgContainerRef.current.getBoundingClientRect();

      // Get the center of the image container.
      const [centerX, centerY] = [rect.width / 2, rect.height / 2];
      // Get the current mouse position.
      const [relativeX, relativeY] = [x - rect.left - window.pageXOffset, y - rect.top - window.pageYOffset];

      // If zooming in, center the mouse position
      const [absX, absY] = [(centerX - relativeX) / zoomRef.current, (centerY - relativeY) / zoomRef.current];
      const ratio = newZoom - zoomRef.current;
      return [prevPosX + absX * ratio, prevPosY + absY * ratio];
    } else {
      // If zooming out, return to center
      return [(prevPosX * (newZoom - 1)) / (zoomRef.current - 1), (prevPosY * (newZoom - 1)) / (zoomRef.current - 1)];
    }
  };

  // Max zooms into a specific mouse position
  const fullZoomInOnPosition = (x: number, y: number) => {
    const [posX, posY] = getNewPosition(x, y, MAX_ZOOM);

    repositionElement({
      newZoom: MAX_ZOOM,
      newPosX: posX,
      newPosY: posY,
      newTransitionDuration: DEFAULT_TRANSITION,
    });
  };

  // Moves the zoomed in image within its boundaries.
  const move = useCallback((shiftX: number, shiftY: number) => {
    if (!imageRef.current || !imgContainerRef.current?.parentNode) return;
    let posX = posXRef.current;
    let posY = posYRef.current;

    // Get container and container's parent coordinates
    const rect = imageRef.current.getBoundingClientRect();
    const parentRect = (imgContainerRef.current.parentNode as HTMLDivElement).getBoundingClientRect();

    const [isLarger, isOutLeftBoundary, isOutRightBoundary] = [
      // Check if the element is larger than its container
      rect.width > parentRect.right - parentRect.left,
      // Check if the element is out its container left boundary
      shiftX > 0 && rect.left - parentRect.left < 0,
      // Check if the element is out its container right boundary
      shiftX < 0 && rect.right - parentRect.right > 0,
    ];

    const canMoveOnX = isLarger || isOutLeftBoundary || isOutRightBoundary;
    if (canMoveOnX) {
      posX += getLimitedShift({
        shift: shiftX,
        minLimit: parentRect.left,
        maxLimit: parentRect.right,
        minElement: rect.left,
        maxElement: rect.right,
      });
    }

    const [isHigher, isOutTopBoundary, isOutBottomBoundary] = [
      // Check if the element is higher than its container
      rect.height > parentRect.bottom - parentRect.top,
      // Check if the element is out its container top boundary
      shiftY > 0 && rect.top - parentRect.top < 0,
      // Check if the element is out its container bottom boundary
      shiftY < 0 && rect.bottom - parentRect.bottom > 0,
    ];

    const canMoveOnY = isHigher || isOutTopBoundary || isOutBottomBoundary;

    if (canMoveOnY) {
      posY += getLimitedShift({
        shift: shiftY,
        minLimit: parentRect.top,
        maxLimit: parentRect.bottom,
        minElement: rect.top,
        maxElement: rect.bottom,
      });
    }

    repositionElement({
      newPosX: posX,
      newPosY: posY,
      newTransitionDuration: 0,
    });
  }, []);

  //Slows down the move, giving it a feeling of momentum.
  const startDeceleration = useCallback(
    (lastShiftOnX: number, lastShiftOnY: number) => {
      let startTimestamp: number | null = null;

      const moveFrame = (timestamp: number) => {
        if (startTimestamp === null) {
          startTimestamp = timestamp;
        }
        const progress = timestamp - startTimestamp;

        // Calculates the ratio to apply on the move (used to create a non-linear deceleration)
        const ratio = (DECELERATION_DURATION - progress) / DECELERATION_DURATION;

        const [shiftX, shiftY] = [lastShiftOnX * ratio, lastShiftOnY * ratio];

        // Continue animation only if time has not expired and if there is still some movement (more than 1 pixel on one axis)
        if (progress < DECELERATION_DURATION && Math.max(Math.abs(shiftX), Math.abs(shiftY)) > 1) {
          move(shiftX, shiftY);
          lastRequestAnimationId.current = requestAnimationFrame(moveFrame);
        } else {
          lastRequestAnimationId.current = null;
        }
      };

      lastRequestAnimationId.current = requestAnimationFrame(moveFrame);
    },
    [move],
  );

  // Zooms in / out of the current mouse position.
  const handleMouseWheel = (event: WheelEvent) => {
    event.preventDefault();

    let _zoom = zoomRef.current;
    let posX = posXRef.current;
    let posY = posYRef.current;

    // @ts-ignore
    const useTouchVelocity = event.ctrlKey || Math.abs(event.wheelDeltaY) % 120 !== 0;

    // Use the scroll event delta to determine the zoom velocity
    const velocity = (-event.deltaY * (useTouchVelocity ? TOUCH_VELOCITY : SCROLL_VELOCITY)) / 100;

    // Set the new zoom level
    _zoom = Math.max(Math.min(_zoom + velocity, MAX_ZOOM), MIN_ZOOM);

    if (_zoom !== zoomRef.current) {
      if (_zoom !== MIN_ZOOM) {
        [posX, posY] = getNewPosition(event.pageX, event.pageY, _zoom);
      } else {
        // Reset to original position
        [posX, posY] = [0, 0];
      }
    }

    repositionElement({
      newZoom: _zoom,
      newPosX: posX,
      newPosY: posY,
      newTransitionDuration: 0.05,
    });
  };

  const zoomToPoint = useCallback(({ x, y, zoom, transitionDuration = 0.05 }: ZoomToPointParams) => {
    const [posX, posY] = getNewPosition(x, y, zoom);
    repositionElement({
      newZoom: zoom,
      newPosX: posX,
      newPosY: posY,
      newTransitionDuration: transitionDuration,
    });
  }, []);

  // Resets to default zoom.
  const reset = useCallback(() => {
    repositionElement({
      newZoom: MIN_ZOOM,
      newPosX: 0,
      newPosY: 0,
      newTransitionDuration: DEFAULT_TRANSITION,
    });
  }, []);

  const handleDoubleClick = (event: MouseEvent) => {
    event.preventDefault();
    if (zoomRef.current === MIN_ZOOM) {
      fullZoomInOnPosition(event.pageX, event.pageY);
    } else {
      reset();
    }
  };

  const handleMouseMove = useCallback(
    (event: ReactMouseEvent<HTMLDivElement>) => {
      if (zoomRef.current === MIN_ZOOM || !isMoving.current) return;
      event.preventDefault();

      if (!lastCursor.current) {
        return;
      }

      const [posX, posY] = [event.pageX, event.pageY];
      const shiftX = posX - lastCursor.current.posX;
      const shiftY = posY - lastCursor.current.posY;

      move(shiftX, shiftY);
      lastCursor.current = { posX, posY };
      lastShift.current = { x: shiftX, y: shiftY };
    },
    [move],
  );

  const handleMouseStart = useCallback((event: ReactMouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (zoomRef.current !== MIN_ZOOM && !isMoving.current) isMoving.current = true;

    if (lastRequestAnimationId.current) {
      cancelAnimationFrame(lastRequestAnimationId.current);
    }

    lastCursor.current = { posX: event.pageX, posY: event.pageY };
  }, []);

  const handleMouseStop = useCallback(
    (event: ReactMouseEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (isMoving.current) isMoving.current = false;

      if (lastShift.current) {
        // Use the last shift to make a decelerating movement effect
        startDeceleration(lastShift.current.x, lastShift.current.y);
        lastShift.current = null;
      }

      lastCursor.current = null;
    },
    [startDeceleration],
  );

  const zoomIn = useCallback(() => {
    let _zoom = zoomRef.current;
    let posX = posXRef.current;
    let posY = posYRef.current;

    const prevZoom = _zoom;

    _zoom = _zoom + ZOOM_INCREMENT < MAX_ZOOM ? _zoom + ZOOM_INCREMENT : MAX_ZOOM;

    if (_zoom !== prevZoom) {
      posX = (posX * (_zoom - 1)) / (prevZoom > 1 ? prevZoom - 1 : prevZoom);
      posY = (posY * (_zoom - 1)) / (prevZoom > 1 ? prevZoom - 1 : prevZoom);
    }

    repositionElement({
      newZoom: _zoom,
      newPosX: posX,
      newPosY: posY,
      newTransitionDuration: 0.25,
    });
  }, []);

  const zoomOut = useCallback((newZoom?: number) => {
    let _zoom = zoomRef.current;
    let posX = posXRef.current;
    let posY = posYRef.current;

    const prevZoom = _zoom;

    _zoom = newZoom ?? (_zoom - ZOOM_INCREMENT > MIN_ZOOM ? _zoom - ZOOM_INCREMENT : MIN_ZOOM);

    if (_zoom !== prevZoom) {
      posX = (posX * (_zoom - 1)) / (prevZoom - 1);
      posY = (posY * (_zoom - 1)) / (prevZoom - 1);
    }

    repositionElement({
      newZoom: _zoom,
      newPosX: posX,
      newPosY: posY,
      newTransitionDuration: 0.25,
    });
  }, []);

  useEffect(() => {
    if (imgContainerRef.current && imageRef.current) {
      imageRef.current.style.transition = `transform ease-out ${DEFAULT_TRANSITION}s`;
      imgContainerRef.current.style.transition = `transform ease-out ${DEFAULT_TRANSITION}s`;
      imgContainerRef.current.ondblclick = handleDoubleClick;
      imgContainerRef.current.onwheel = handleMouseWheel;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsFullscreen(isFullScreen);
    reset();
    return () => {
      setIsFullscreen(false);
    };
  }, [isFullScreen, reset, setIsFullscreen]);

  useEffect(() => {
    if (isSelecting) {
      isMoving.current = false;
      lastCursor.current = null;
      lastShift.current = null;
    }
  }, [isSelecting]);

  return (
    <div
      className={classNames(
        'flex overflow-visible bg-grey-2',
        isFullScreen ? 'fixed left-0 top-0 z-[3] h-screen w-screen' : 'h-full w-full',
      )}
    >
      <ImageVisualizerClip
        canAnnotateClip={canAnnotateClip}
        imageRef={imageRef}
        ref={imgContainerRef}
        zoom={zoom}
        setIsSelecting={setIsSelecting}
        handleMouseStart={handleMouseStart}
        handleMouseMove={handleMouseMove}
        handleMouseStop={handleMouseStop}
        isFullScreen={isFullScreen}
        isShowOriginal={showOriginal}
        zoomToPoint={zoomToPoint}
      >
        {children}
      </ImageVisualizerClip>
      {!isSelecting && (
        <ImageVisualizerControls
          isFullscreen={isFullScreen}
          isShowOriginal={showOriginal}
          onShowOriginalChange={setShowOriginal}
          onToggleFullScreen={toggleFullScreen}
          onZoomIn={zoomIn}
          onZoomOut={zoomOut}
          ref={controlsRef}
          zoom={zoom}
        />
      )}
    </div>
  );
});

ImageVisualizer.displayName = 'ImageVisualizer';
