import { Board } from '@air/api/types';
import { Breadcrumbs, BreadcrumbsItem } from '@air/component-breadcrumbs';
import { tailwindMerge } from '@air/tailwind-variants';
import classNames from 'classnames';
import { memo, useMemo } from 'react';

export interface BoardAncestorPathProps {
  ancestors: Pick<Board, 'title' | 'id'>[];
  className?: string;
}

export const BoardAncestorPath = memo(({ ancestors, className }: BoardAncestorPathProps) => {
  const ancestorsItems = useMemo<BreadcrumbsItem[]>(() => {
    const items: BreadcrumbsItem[] = [];
    const boardAncestors = ancestors ?? [];

    items.push(
      ...boardAncestors.map<BreadcrumbsItem>((ancestor, index) => ({
        className: classNames('truncate font-normal text-inherit', index === 0 ? 'pl-0' : ''),
        id: ancestor.id,
        label: ancestor.title,
        type: 'item',
      })),
    );

    return items;
  }, [ancestors]);

  if (ancestors.length === 0) {
    return null;
  }

  return (
    <Breadcrumbs
      isCompact
      className={tailwindMerge('overflow-visible text-12 text-grey-10', className)}
      items={ancestorsItems}
    />
  );
});

BoardAncestorPath.displayName = 'BoardAncestorPath';
