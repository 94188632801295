import { ClipMetadata } from '@air/api';
import { User } from '@air/next-icons';
import { memo } from 'react';

import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { InfoPanelDetailValue } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailValue';
import { isStringArrayMetadataType, isStringMetadataType } from '~/utils/iptcDataTypes';

interface InfoPanelCreatorProps {
  clipMetadata: ClipMetadata[];
}

export const InfoPanelCreator = memo(({ clipMetadata }: InfoPanelCreatorProps) => {
  const creators = clipMetadata.find((metadata) => metadata.fieldName === 'Creator');
  const position = clipMetadata.find((metadata) => metadata.fieldName === 'AuthorsPosition');

  if (!creators || !isStringArrayMetadataType(creators) || creators.data.length === 0) {
    return null;
  }

  return (
    <InfoPanelDetailsSection Icon={User} title="Creator">
      <InfoPanelDetailValue
        value={creators.data.join(', ')}
        additionalInfo={!!position && isStringMetadataType(position) ? position.data : undefined}
      />
    </InfoPanelDetailsSection>
  );
});

InfoPanelCreator.displayName = 'InfoPanelCreator';
