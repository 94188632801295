import { useTrackClickedAssetModalTab } from '@air/analytics';
import { Comment } from '@air/next-icons';
import { memo } from 'react';

import { PanelTabButton } from '~/components/AssetModal/Panel/Tabs/components/PanelTabButton';
import { _TAB } from '~/constants/testIDs';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';

export const CommentsTabButton = memo(() => {
  const { panel, togglePanel } = useAssetModalPanelContext();
  const { trackClickedAssetModalTab } = useTrackClickedAssetModalTab();

  return (
    <PanelTabButton
      isSelected={panel === AssetModalPanel.COMMENTS}
      testId={`COMMENTS${_TAB}`}
      label="Comments"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        togglePanel(AssetModalPanel.COMMENTS);
        trackClickedAssetModalTab({
          tab: AssetModalPanel.COMMENTS,
          panelAction: panel ? (panel === AssetModalPanel.COMMENTS ? 'close' : 'switch') : 'open',
        });
      }}
      icon={<Comment className="h-6 w-6" />}
    />
  );
});

CommentsTabButton.displayName = 'CommentsTabButton';
