import { CommentResponse, Discussion } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import { useBreakpointsContext } from '@air/provider-media-query';
import { formatFullName, formatInitials } from '@air/utilities';
import { memo, useRef } from 'react';

import { COMMENT_AUTHOR_HEADER_LEFT, COMMENT_AVATAR_SIZE } from '~/components/Discussions/Discussion/shared/constants';
import { useCommentReply } from '~/components/Discussions/Discussion/shared/useCommentReply';
import { DiscussionForm, DiscussionFormHandle, MentionsData } from '~/components/Discussions/DiscussionForm';
import { DISCUSSION_REPLY_COMMENT_AVATAR, REPLY_COMMENT_FORM } from '~/constants/testIDs';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

export interface CommentReplyProps {
  user?: CommentResponse['account'] | null;
  discussionId: Discussion['id'];
  onSubmit: (newComment: string) => void;
  mentionsList: MentionsData[];
  shouldShowMentionsButton?: boolean;
  onIsAddingReplyChange?: (isAddingReply: boolean) => void;
}

export const CommentReply = memo(
  ({ discussionId, onSubmit, mentionsList, shouldShowMentionsButton, user }: CommentReplyProps) => {
    const fullName = user ? formatFullName(user.firstName, user.lastName) : '';
    const initials = user ? formatInitials(user.firstName, user.lastName) : '';

    const discussionFormRef = useRef<DiscussionFormHandle>(null);

    const { isAboveMediumScreen } = useBreakpointsContext();

    const { onCommentSubmit, isAddingReply, clearReplyingDiscussionId } = useCommentReply({
      discussionId,
      onSubmit,
      formRef: discussionFormRef,
    });

    return (
      <div className="flex py-5 pr-5" style={{ marginLeft: COMMENT_AUTHOR_HEADER_LEFT }}>
        {!!user && (
          <Avatar
            className="!mr-2"
            alt={fullName}
            data-testid={DISCUSSION_REPLY_COMMENT_AVATAR}
            size={COMMENT_AVATAR_SIZE}
            src={getAvatarImageSrc(user.avatar, COMMENT_AVATAR_SIZE)}
            text={initials}
            colorSeed={user.id}
          />
        )}
        <DiscussionForm
          data-testid={REPLY_COMMENT_FORM}
          ref={discussionFormRef}
          hasCancelButton={isAddingReply && !isAboveMediumScreen}
          onCancel={clearReplyingDiscussionId}
          onSubmit={onCommentSubmit}
          mentionsList={mentionsList}
          placeholder="Write a reply..."
          shouldShowMentionsButton={shouldShowMentionsButton}
          onOutsideClick={clearReplyingDiscussionId}
        />
      </div>
    );
  },
);

CommentReply.displayName = 'CommentReply';
