import { defaultMemoizedContextValue, MemoizedContextType } from '@air/react-memoized-context';
import { noop } from 'lodash';

import { ClipDiscussion } from '~/components/Annotations/shared/types';

export enum ASSET_MODAL_DISCUSSION_FILTERS {
  ALL = 'ALL',
  OPENED = 'OPENED',
  RESOLVED = 'RESOLVED',
}

export enum ASSET_MODAL_DISCUSSION_SORT {
  DEFAULT = 'DEFAULT',
  TIMESTAMP_ASC = 'TIMESTAMP_ASC',
  TIMESTAMP_DESC = 'TIMESTAMP_DESC',
}

export enum ASSET_MODAL_DISCUSSION_VERSION_FILTERS {
  ALL = 'ALL',
  CURRENT = 'CURRENT',
}

export interface DiscussionsPanelContextValue {
  discussionsFilter: ASSET_MODAL_DISCUSSION_FILTERS;
  discussionsSort: ASSET_MODAL_DISCUSSION_SORT;
  discussionsVersionFilter: ASSET_MODAL_DISCUSSION_VERSION_FILTERS;
  replyingDiscussionId?: ClipDiscussion['id'];
  openReplies: Record<ClipDiscussion['id'], boolean>;
}

export interface DiscussionsPanelContextType extends MemoizedContextType<DiscussionsPanelContextValue> {
  setDiscussionsFilter: (filter: ASSET_MODAL_DISCUSSION_FILTERS) => void;
  setDiscussionsSort: (sort: ASSET_MODAL_DISCUSSION_SORT) => void;
  setDiscussionsVersionFilter: (filter: ASSET_MODAL_DISCUSSION_VERSION_FILTERS) => void;
  setReplyingDiscussionId: (id: ClipDiscussion['id']) => void;
  setRepliesOpen: (id: ClipDiscussion['id'], isOpen: boolean) => void;
  clearReplyingDiscussionId: () => void;
}

export const defaultDiscussionPanelContextValue: DiscussionsPanelContextType = {
  ...defaultMemoizedContextValue,
  getValue: () => ({
    discussionsFilter: ASSET_MODAL_DISCUSSION_FILTERS.OPENED,
    discussionsVersionFilter: ASSET_MODAL_DISCUSSION_VERSION_FILTERS.ALL,
    discussionsSort: ASSET_MODAL_DISCUSSION_SORT.DEFAULT,
    openReplies: {},
  }),
  setDiscussionsFilter: noop,
  setDiscussionsSort: noop,
  setDiscussionsVersionFilter: noop,
  clearReplyingDiscussionId: noop,
  setReplyingDiscussionId: noop,
  setRepliesOpen: noop,
};

export enum DiscussionsPanelContextActions {
  setDiscussionsFilter = 'setDiscussionsFilter',
  setDiscussionsSort = 'setDiscussionsSort',
  setDiscussionsVersionFilter = 'setDiscussionsVersionFilter',
  setReplyingDiscussionId = 'setReplyingDiscussionId',
  clearReplyingDiscussionId = 'clearReplyingDiscussionId',
  setRepliesOpen = 'setRepliesOpen',
}
