import { Gear } from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { memo, useMemo } from 'react';

import { SelectedOptionIcon } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/shared/SelectedOptionIcon';
import {
  DropdownMenuActionSheetProps,
  DropdownMenuWithActionSheet,
} from '~/components/Menus/DropdownMenuWithActionSheet';

interface VideoQualityOptionsProps {
  currentResolutionIndex: number;
  onSelectVideoResolution: (index: number) => void;
  resolutionOptions: { width: number; height: number }[];
  container: DropdownMenuActionSheetProps['container'];
}
export const VideoQualityOptions = memo(
  ({ currentResolutionIndex, onSelectVideoResolution, resolutionOptions, container }: VideoQualityOptionsProps) => {
    const options = useMemo(() => {
      const optionsArray: DropdownMenuOption[] = [
        {
          id: 'quality',
          label: 'Quality',
          type: 'title',
        },
        {
          id: 'resolution Auto',
          label: 'Auto',
          onSelect: () => onSelectVideoResolution(-1),
          suffix: currentResolutionIndex === -1 ? <SelectedOptionIcon /> : null,
          type: 'item',
        },
      ];

      resolutionOptions.map((res, index) => {
        const resolution = Math.min(res.width, res.height);
        optionsArray.push({
          id: `resolution ${resolution}}`,
          label: `${resolution}p`,
          onSelect: () => onSelectVideoResolution(index),
          suffix: index === currentResolutionIndex ? <SelectedOptionIcon /> : null,
          type: 'item',
        });
      });

      return optionsArray;
    }, [currentResolutionIndex, onSelectVideoResolution, resolutionOptions]);

    return (
      <DropdownMenuWithActionSheet
        data-theme="dark"
        title="Quality"
        container={container}
        trigger={
          <IconButton
            label="Quality"
            icon={Gear}
            size="large"
            appearance="ghost"
            className="ml-2 text-pigeon-100 hover:bg-pigeon-700 hover:text-pigeon-50 active:bg-pigeon-700 active:text-pigeon-50"
          />
        }
        options={options}
      />
    );
  },
);

VideoQualityOptions.displayName = 'VideoQualityOptions';
