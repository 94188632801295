import { ClipStatus, ClipType } from '@air/api/types';
import { memo } from 'react';

import { ProcessingVersionThumbnail } from './components/ProcessingVersionThumbnail';
import { VersionFileThumbnail } from './components/VersionFileThumbnail';
import { VersionImageThumbnail } from './components/VersionImageThumbnail';

interface VersionListItemThumbnailProps {
  type: ClipType;
  status: ClipStatus;
  src: string;
  ext: string;
  isCurrentVersion: boolean;
}

export const VersionListItemThumbnail = memo(
  ({ type, status, src, ext, isCurrentVersion }: VersionListItemThumbnailProps) => {
    if (type === ClipType.nonMedia || status === ClipStatus.failed || status === ClipStatus.nonTranscodable) {
      return <VersionFileThumbnail ext={ext} isCurrentVersion={isCurrentVersion} />;
    }

    if (src) {
      return <VersionImageThumbnail src={src} isCurrentVersion={isCurrentVersion} />;
    }

    return <ProcessingVersionThumbnail isCurrentVersion={isCurrentVersion} />;
  },
);

VersionListItemThumbnail.displayName = 'VersionListItemThumbnail';
