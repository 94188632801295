import classNames from 'classnames';
import { memo, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { centralizedClipIsPDFTypeSelector } from '~/store/centralizedClip/selectors';

const VERSIONS_CLASS_NAMES = 'box-border flex h-[216px] w-full flex-col items-start justify-start px-6 pt-4';

export interface VersionListProps {
  isOpen: boolean;
}

export const VersionList = memo(({ isOpen, children }: PropsWithChildren<VersionListProps>) => {
  const isPDF = useSelector(centralizedClipIsPDFTypeSelector);

  return (
    <div className={classNames('flex w-full flex-1 border-t border-t-grey-5 bg-grey-2', { isOpen })}>
      {isPDF ? (
        isOpen && <div className={VERSIONS_CLASS_NAMES}>{children}</div>
      ) : (
        <div
          className={classNames(
            'transition-opacity delay-150',
            VERSIONS_CLASS_NAMES,
            isOpen ? 'opacity-100' : 'opacity-0',
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
});

VersionList.displayName = 'VersionList';
