import { Discussions } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getDiscussionsKey } from '~/constants/react-query-keys';
import { TEN_SECONDS } from '~/constants/unitsOfTime';
import { convertDiscussionsToClipDiscussions } from '~/swr-hooks/discussions/utils';

export interface UsePublicDiscussionsParams {
  boardId?: Board['id'];
  clipId?: Clip['id'];
  assetId: Clip['assetId'];
  shortId?: string;
  isEnabled: boolean;
}

export const usePublicDiscussions = ({ boardId, clipId, assetId, shortId, isEnabled }: UsePublicDiscussionsParams) => {
  const queryKey = getDiscussionsKey({ assetId, boardId, clipId, shortId });

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => {
      const discussions = await Discussions.list(
        {
          boardId,
          assetId,
          clipId,
          sortBy: 'mostRecentFirstComment',
          resolved: false,
          type: 'clip',
          discussionLimit: 100,
          commentLimit: 100,
          cursor: pageParam || undefined,
        },
        shortId,
      );

      return {
        ...discussions,
        // backend does not return correctly typed discussions, so just ensure TypeScript that we know its type
        data: convertDiscussionsToClipDiscussions(discussions.data),
      };
    },
    /** Revalidates every 10s */
    refetchInterval: TEN_SECONDS,
    enabled: !!shortId && !!assetId && isEnabled,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor || null,
    initialPageParam: '',
  });

  const discussions = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data]);

  return {
    data: discussions,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    total: data?.pages[0]?.total ?? 0,
  };
};
