import { useTrackSwitchedVersion } from '@air/analytics';
import { useCallback } from 'react';

import { GoToAssetPageProps, useGoToAssetPage } from '~/hooks/useGoToAssetPage';

export interface SwitchVersionProps {
  version: GoToAssetPageProps['asset'];
  location: 'tab' | 'dropdown' | 'mobile' | 'discussion' | 'header';
  query?: GoToAssetPageProps['query'];
}

export const useSwitchVersion = () => {
  const { trackSwitchedVersion } = useTrackSwitchedVersion();
  const { goToAssetPage } = useGoToAssetPage();

  const switchVersion = useCallback(
    ({ version, location, query }: SwitchVersionProps) => {
      goToAssetPage({
        asset: version,
        query,
      });
      trackSwitchedVersion({ location });
    },
    [goToAssetPage, trackSwitchedVersion],
  );

  return {
    switchVersion,
  };
};
