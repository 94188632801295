import { Plus } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { memo, ReactNode } from 'react';

import { ADD_CUSTOM_FIELD_MINI_BUTTON, CUSTOM_FIELD_LIST, CUSTOM_FIELD_PANEL } from '~/constants/testIDs';

export interface CustomFieldsPanelProps {
  onCreateClick?: () => void;
  fields: ReactNode;
}

export const CustomFieldsPanel = memo(({ onCreateClick, fields }: CustomFieldsPanelProps) => {
  return (
    <div data-testid={CUSTOM_FIELD_PANEL} className="relative flex h-full w-full flex-col items-start justify-start">
      <div
        data-testid={CUSTOM_FIELD_LIST}
        className="flex w-full flex-1 flex-col items-start justify-start overflow-auto p-4"
      >
        {fields}
      </div>
      {!!onCreateClick && (
        <div className="flex h-16 w-full shrink-0 items-center justify-start border-t border-t-grey-4 bg-grey-1 pl-4">
          <Button
            appearance="ghost"
            color="grey"
            className="w-full justify-start"
            data-testid={ADD_CUSTOM_FIELD_MINI_BUTTON}
            onClick={onCreateClick}
            prefix={<Plus className="h-4 w-4" />}
            size="large"
          >
            Add custom field
          </Button>
        </div>
      )}
    </div>
  );
});

CustomFieldsPanel.displayName = 'CustomFieldsPanel';
