import { Discussions } from '@air/api';
import { Discussion } from '@air/api/types';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { ClipDiscussionsCacheType } from '~/swr-hooks/discussions/types';
import { isClipDiscussionsKey, updateDiscussionInCache } from '~/swr-hooks/discussions/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface ResolveDiscussionParams extends Pick<Required<Discussion>, 'id' | 'resolved' | 'assetId'> {
  boardId?: string;
  shortId?: string;
}

export const useResolveDiscussion = () => {
  const queryClient = useQueryClient();
  const resolveDiscussion = useCallback(
    async ({ id, resolved, shortId, boardId, assetId }: ResolveDiscussionParams) => {
      try {
        const queryToUpdate = ({ queryKey }: { queryKey: QueryKey }) =>
          isClipDiscussionsKey(queryKey) && queryKey[1].assetId === assetId;

        queryClient.setQueriesData<ClipDiscussionsCacheType>(
          {
            predicate: queryToUpdate,
          },
          (data) => updateDiscussionInCache({ id, resolved }, data),
        );

        // TODO: fix boardId assertion once backend updates with proper typings.
        // BOARDID *IS* POSSIBLY UNDEFINED
        // see: https://github.com/AirLabsTeam/backend/pull/2783
        await Discussions.update({
          input: {
            id,
            resolved,
          },
          boardId: boardId!,
          shortId,
        });

        queryClient.invalidateQueries({ predicate: queryToUpdate });
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to resolve discussion',
          metadata: { data: { id, shortId, boardId } },
        });
      }
    },
    [queryClient],
  );

  return {
    resolveDiscussion,
  };
};
