'use client';
import classNames from 'classnames';
import { Children, type ComponentProps, memo, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useResizeDetector } from 'react-resize-detector';

import { MOBILE_ASSET_MODAL_NAVIGATION_ITEM_WIDTH } from '~/_components/MobileAssetModalNavigationItem';

const MOBILE_ASSET_MODAL_NAVIGATION_GAP = 24;

export type MobileAssetModalNavigationProps = Pick<ComponentProps<'div'>, 'children'>;

export const MobileAssetModalNavigation = memo(({ children }: MobileAssetModalNavigationProps) => {
  const { ref: inViewRef, inView } = useInView();
  const { ref: resizeRef, width } = useResizeDetector();

  /**
   * Calculates the gap between navigation items based on the width of the container and the
   * number of children.
   */
  const gap = useMemo(() => {
    if (!width) return 0;

    const childrenCount = Children.count(children);

    const minimumChildrenWidth =
      (MOBILE_ASSET_MODAL_NAVIGATION_ITEM_WIDTH + MOBILE_ASSET_MODAL_NAVIGATION_GAP) * childrenCount;

    /**
     * If the total width of all children is less than the width of the container, we can
     * distribute the remaining space evenly between the children.
     */
    if (minimumChildrenWidth < width) {
      return (width - MOBILE_ASSET_MODAL_NAVIGATION_ITEM_WIDTH * childrenCount) / (childrenCount - 1) - 1;
    }

    const numberOfChildrenThatCanFitInView = Math.floor(
      width / (MOBILE_ASSET_MODAL_NAVIGATION_ITEM_WIDTH + MOBILE_ASSET_MODAL_NAVIGATION_GAP),
    );

    return Math.max(
      MOBILE_ASSET_MODAL_NAVIGATION_GAP,
      (width - MOBILE_ASSET_MODAL_NAVIGATION_ITEM_WIDTH * numberOfChildrenThatCanFitInView) /
        numberOfChildrenThatCanFitInView -
        1,
    );
  }, [children, width]);

  return (
    <div
      className="relative flex w-full shrink-0 items-center border-b border-b-grey-5 bg-grey-1"
      data-testid="MOBILE_ASSET_MODAL_NAVIGATION"
    >
      <div className="flex w-full items-center overflow-x-auto p-4 px-6" ref={resizeRef}>
        <div className="flex shrink-0 items-center" style={{ gap }}>
          {children}
        </div>
        <div className="h-full w-px shrink-0 bg-white" ref={inViewRef} />
      </div>

      <div
        className={classNames(
          'pointer-events-none absolute inset-y-0 right-0 w-24 bg-gradient-to-r from-transparent to-black/60 text-white transition-opacity',
          inView ? 'opacity-0' : 'opacity-100',
        )}
      />
    </div>
  );
});

MobileAssetModalNavigation.displayName = 'MobileAssetModalNavigation';
