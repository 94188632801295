import { throttle } from 'lodash';
import { MutableRefObject, useEffect, useRef } from 'react';

interface UseShowOnActivityProps {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  displayRef: MutableRefObject<HTMLDivElement | null>;
  getShouldHide?: () => boolean;
}

export const useShowOnActivity = ({ containerRef, displayRef, getShouldHide = () => true }: UseShowOnActivityProps) => {
  const hoveringRef = useRef<boolean>(false);
  const hoveringDisplayRef = useRef<boolean>(false);
  const timer = useRef(0);
  const getShouldHideRef = useRef(getShouldHide);
  getShouldHideRef.current = getShouldHide;

  const handleMouseMove = throttle(() => {
    if (displayRef.current) {
      displayRef.current.style.opacity = '1';
      displayRef.current.style.pointerEvents = 'all';
    }

    clearTimeout(timer.current);

    timer.current = window.setTimeout(() => {
      if (hoveringRef.current && displayRef.current && !hoveringDisplayRef.current && getShouldHide()) {
        displayRef.current.style.opacity = '0';
        displayRef.current.style.pointerEvents = 'none';
      }
    }, 1000);
  }, 1000);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.onmouseenter = () => (hoveringRef.current = true);
      containerRef.current.onmouseleave = () => (hoveringRef.current = false);
      containerRef.current.onmousemove = handleMouseMove;
    }

    //so the control bar doesn't hide if hovering over it
    if (displayRef.current) {
      displayRef.current.onmouseenter = () => (hoveringDisplayRef.current = true);
      displayRef.current.onmouseleave = () => (hoveringDisplayRef.current = false);
    }
  }, [containerRef, displayRef, handleMouseMove]);
};
