import { ComponentProps, useEffect, useRef, useState } from 'react';

export interface FixedWidthTextProps extends ComponentProps<'div'> {
  /* text to display */
  text: string;
  /* the longest text which will be displayed */
  maxText: string;
  outerMargin?: number;
}

/**
 * This component displays a text with fixed width, equal to maxText width.
 * It can be used e.g. to display video current time and avoid changing width when time changes, e.g. from 0:01 to 0:02
 */
const FixedWidthText = ({ maxText, text, outerMargin = 0, style, ...props }: FixedWidthTextProps) => {
  const maxTextRef = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState<string | number>('auto');

  useEffect(() => {
    if (maxTextRef.current && maxText) {
      setWidth(maxTextRef.current.offsetWidth + 1 + 2 * outerMargin);
    }
  }, [maxText, outerMargin]);

  return (
    <p style={{ minWidth: width, ...style }} {...props}>
      {text}
      <span className="invisible absolute" ref={maxTextRef} aria-hidden={true}>
        {maxText}
      </span>
    </p>
  );
};

export default FixedWidthText;
