import { AnnotationOutlinePlus } from '@air/next-icons';
import { Button, ButtonProps } from '@air/primitive-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo } from 'react';

import { TOGGLE_EDIT_ANNOTATIONS_BUTTON } from '~/constants/testIDs';

type AnnotateButtonProps = Pick<ButtonProps, 'onClick'> & {
  shouldDisableAnnotations: boolean;
};
export const CreateAnnotationButton = memo(({ onClick, shouldDisableAnnotations }: AnnotateButtonProps) => (
  <Tooltip
    label={
      shouldDisableAnnotations ? 'Annotation commenting is disabled during video processing' : 'Add contextual comments'
    }
    side="top"
  >
    <Button
      appearance="filled"
      color="grey"
      disabled={shouldDisableAnnotations}
      data-testid={TOGGLE_EDIT_ANNOTATIONS_BUTTON}
      prefix={<AnnotationOutlinePlus className="h-4 w-4" />}
      onClick={onClick}
      size="large"
    >
      Annotate
    </Button>
  </Tooltip>
));

CreateAnnotationButton.displayName = 'AnnotationsButton';
