import { ClipsListResponse, PublicClip } from '@air/api';
import { Clip } from '@air/api/types';

/**
 * This method filters out versions with status "created" and "version: null"
 * There is a bug in backend that returns versions with status "created" and "version: null"
 * @see https://air-labs-team.slack.com/archives/CCS4C8ALT/p1701368808930219
 */
export const filterOutNullVersions = <T extends Clip | PublicClip>(versionsList: ClipsListResponse<T>) => ({
  ...versionsList,
  data: {
    ...versionsList.data,
    versions: versionsList.data.clips.filter((clips) => clips.version === null),
  },
});
