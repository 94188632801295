import { useBreakpointsContext } from '@air/provider-media-query';
import { useToasts } from '@air/provider-toast';
import { memo, ReactNode, useEffect, useRef } from 'react';

import { DiscussionsPanelContextProvider } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { AssetModalPanelContextProvider } from '~/providers/AssetModalPanelContextProvider';
import { VideoControlProvider } from '~/providers/VideoControlsProvider';
import { VideoTimestampObserverProvider } from '~/providers/VideoTimestampObserverProvider';
import { VideoTimestampProvider } from '~/providers/VideoTimestampProvider';

import { FullscreenContextProvider } from './shared/context/FullscreenContext';

export interface AssetModalProps {
  desktop: ReactNode;
  mobile: ReactNode;
}

export const AssetModal = memo(({ desktop, mobile }: AssetModalProps) => {
  const { isAboveMediumScreen } = useBreakpointsContext();
  const { setToastXOffset, getToastXOffset } = useToasts();
  const previousOffset = useRef(getToastXOffset());

  useEffect(() => {
    const previousValue = previousOffset.current;
    setToastXOffset(0);

    return () => {
      setToastXOffset(previousValue);
    };
  }, [setToastXOffset]);

  return (
    <div data-theme="dark">
      <AssetModalPanelContextProvider>
        <FullscreenContextProvider>
          <VideoControlProvider>
            <VideoTimestampObserverProvider>
              <VideoTimestampProvider>
                <DiscussionsPanelContextProvider>
                  {!isAboveMediumScreen ? mobile : desktop}
                </DiscussionsPanelContextProvider>
              </VideoTimestampProvider>
            </VideoTimestampObserverProvider>
          </VideoControlProvider>
        </FullscreenContextProvider>
      </AssetModalPanelContextProvider>
    </div>
  );
});

AssetModal.displayName = 'AssetModal';
