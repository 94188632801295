import { useTrackClickedExportAsButton } from '@air/analytics';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { reportErrorToBugsnag } from '@air/utils-error';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { stopEscKeyPropagationOnAssetDetailsModal } from '~/components/AssetModal/shared/hooks/useAssetModalEscKeyHandler';
import Form from '~/components/Form';
import { defaultImageSizeOptions } from '~/components/ImageFormatFormField';
import { EXPORT_IMAGES_MODAL } from '~/constants/testIDs';
import { DownloadFile } from '~/utils/DownloadFile';
import { createImgixImageUrl, ImgixSupportedExportFormat } from '~/utils/Imgix';

import { ExportImagesModalForm } from './components/ExportImagesModalForm/ExportImagesModalForm';

const EXPORT_IMAGES_MODAL_FORM_ID = 'exportImagesModalFormId';

const extractFileName = (format: string, baseName: string, suffix?: string) => {
  return suffix ? `${baseName}${suffix}.${format}` : `${baseName}.${format}`;
};

export interface ExportImagesFormData {
  width: number;
  height: number;
  imageSrc: string;
  size: string;
  fileName: string;
  suffix?: string;
  ext: ImgixSupportedExportFormat;
}

const IMAGE_SIZE_ERROR = 'Asset size must be between 1px and 8000px';
const validationSchema = Yup.object().shape({
  ext: Yup.string().required(),
  height: Yup.number().min(1, IMAGE_SIZE_ERROR).max(8000, IMAGE_SIZE_ERROR).required(IMAGE_SIZE_ERROR),
  size: Yup.string().required(),
  suffix: Yup.string().notRequired(),
  width: Yup.number().min(1, IMAGE_SIZE_ERROR).max(8000, IMAGE_SIZE_ERROR).required(IMAGE_SIZE_ERROR),
});

export interface ExportImagesModalProps
  extends AirModalProps,
    Pick<ExportImagesFormData, 'width' | 'height' | 'imageSrc' | 'fileName'> {}

export const ExportImagesModal = ({ width, height, imageSrc, fileName, onClose }: ExportImagesModalProps) => {
  const { trackClickedExportAsButton } = useTrackClickedExportAsButton();
  const [imageProcessingError, setImageProcessingError] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const exportImage = async (formValues: ExportImagesFormData) => {
    try {
      const { ext, suffix, fileName: initialFileName, size, ...rest } = formValues;
      const fileName = extractFileName(ext, initialFileName, suffix);
      const linkToOpen = createImgixImageUrl({ ...rest, format: ext, fileName });

      if (!linkToOpen) throw new Error('Error exporting image');

      DownloadFile(linkToOpen);

      const sizeLabel = defaultImageSizeOptions.filter((sizeOpt) => sizeOpt.value === size)[0].label;
      trackClickedExportAsButton({ size: sizeLabel, format: ext });
    } catch (error) {
      setImageProcessingError(true);
      reportErrorToBugsnag({ error, context: 'Failed to export image' });
    } finally {
      setIsLoading(false);
      if (imageProcessingError) setImageProcessingError(false);
    }
  };

  const onSubmit = (formValues: ExportImagesFormData) => {
    if (isEditing) return;

    setIsLoading(true);

    // NOTE: exportImage is async because it relies on an iframe loading and some browsers (e.g. Chrome) don't
    // expose iframe.onload so setIsLoading(false) gets called instantly. The timeout allows us to give a better
    // UX by letting the user know briefly that the image is processing.
    setTimeout(() => {
      exportImage(formValues);
    }, 1000);
  };

  useEffect(() => {
    stopEscKeyPropagationOnAssetDetailsModal(true);
    return () => {
      stopEscKeyPropagationOnAssetDetailsModal(false);
    };
  }, []);

  const closeModal = () => {
    if (isEditing) return;
    onClose();
  };

  return (
    <Modal isOpen data-testid={EXPORT_IMAGES_MODAL} onDismiss={closeModal}>
      <header className="mb-6 flex flex-col">
        <div className="flex items-center justify-between gap-4">
          <ModalTitle>Export as</ModalTitle>
          <ModalCloseButton className="shrink-0" onClick={onClose} />
        </div>
      </header>
      <Formik<ExportImagesFormData>
        initialValues={{
          width,
          height,
          fileName,
          imageSrc,
          ext: 'jpg',
          size: '1',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form id={EXPORT_IMAGES_MODAL_FORM_ID}>
          <ExportImagesModalForm
            imageProcessingError={imageProcessingError}
            setIsExportDisabled={setIsExportDisabled}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            exportImage={onSubmit}
          />
          <div className="mt-8 flex justify-end gap-3">
            <Button disabled={isLoading} size="large" appearance="ghost" color="grey" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              data-testid="EXPORT_IMAGES_MODAL_EXPORT_BUTTON"
              disabled={isExportDisabled || isLoading}
              isLoading={isLoading}
              size="large"
              type="submit"
              appearance="filled"
              color="blue"
            >
              Export
            </Button>
          </div>             
        </Form>
      </Formik>
    </Modal>
  );
};
