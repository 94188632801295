import {
  BoundingBoxHandler,
  GetNewRectType,
  InternalStartDrawingType,
} from '~/components/BoundingBox/utils/BoundingBoxHandler';

export class BoundingBoxMover extends BoundingBoxHandler {
  private moveStartXDiff: number | null = null;
  private moveStartYDiff: number | null = null;

  _startDrawing: InternalStartDrawingType = ({ point, currentRect }) => {
    if (!currentRect) return;

    this.moveStartXDiff = point.pctX - currentRect.pctStartX;
    this.moveStartYDiff = point.pctY - currentRect.pctStartY;
  };

  _finishDrawing = () => {
    this.moveStartXDiff = null;
    this.moveStartYDiff = null;
  };

  _getNewRect: GetNewRectType = ({ currentRect, point }) => {
    if (!this.moveStartXDiff || !this.moveStartYDiff || !currentRect) return;

    const width = currentRect.pctEndX - currentRect.pctStartX;
    const height = currentRect.pctEndY - currentRect.pctStartY;

    let newStartX = point.pctX - this.moveStartXDiff;
    let newStartY = point.pctY - this.moveStartYDiff;
    let newEndX = newStartX + width;
    let newEndY = newStartY + height;

    // Check if new rect is within canvas bounds
    // width can be negative if user started drawing from bottom right to top left
    if (width > 0) {
      if (newStartX < 0) {
        newStartX = 0;
      } else if (newStartX + width > 1) {
        newStartX = 1 - width;
      }
      newEndX = newStartX + width;
    } else {
      if (newEndX < 0) {
        newEndX = 0;
      } else if (newEndX - width > 1) {
        newEndX = 1 + width;
      }
      newStartX = newEndX - width;
    }

    if (height > 0) {
      if (newStartY < 0) {
        newStartY = 0;
      } else if (newStartY + height > 1) {
        newStartY = 1 - height;
      }
      newEndY = newStartY + height;
    } else {
      if (newEndY < 0) {
        newEndY = 0;
      } else if (newEndY - height > 1) {
        newEndY = 1 + height;
      }
      newStartY = newEndY - height;
    }

    return {
      pctStartX: newStartX,
      pctStartY: newStartY,
      pctEndX: newEndX,
      pctEndY: newEndY,
    };
  };
}
