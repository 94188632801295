import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { forwardRef, memo } from 'react';

import { useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { useMobileAssetModalContext } from '~/providers/MobileAssetModalContextProvider';

export type MobileAssetModalPanelHeaderProps = {
  title: string;
};

export const MobileAssetModalPanelHeader = memo(
  forwardRef<HTMLDivElement, MobileAssetModalPanelHeaderProps>(({ title }, forwardedRef) => {
    const { navigationHeight } = useMobileAssetModalContext();
    const { hidePanel } = useAssetModalPanelContext();

    return (
      <header
        className="sticky top-10 z-1 flex shrink-0 items-center justify-between gap-2 border-y border-grey-5 bg-grey-1 px-6 py-4"
        style={{ top: navigationHeight }}
        ref={forwardedRef}
      >
        <h2 className="text-14 font-bold uppercase tracking-wide text-grey-10">{title}</h2>
        <IconButton
          appearance="ghost"
          color="grey"
          icon={Close}
          label="Close panel"
          onClick={hidePanel}
          size="medium"
        />
      </header>
    );
  }),
);

MobileAssetModalPanelHeader.displayName = 'MobileAssetModalPanelHeader';
