import { noop } from 'lodash';
import {
  createContext,
  type MutableRefObject,
  type ReactNode,
  type RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type MobileAssetModalContextValue = {
  assetModalRef: RefObject<HTMLDivElement>;
  navigationHeight: number | undefined;
  navigationRef: MutableRefObject<HTMLDivElement>;
  panelRef: RefObject<HTMLDivElement>;
  scrollToBottom: () => void;
  scrollToPanel: () => void;
  scrollToTop: () => void;
};

const defaultContextValue: MobileAssetModalContextValue = {
  assetModalRef: { current: null },
  navigationHeight: undefined,
  // @ts-ignore
  navigationRef: { current: null },
  panelRef: { current: null },
  scrollToBottom: noop,
  scrollToPanel: noop,
  scrollToTop: noop,
};

export const MobileAssetModalContext = createContext<MobileAssetModalContextValue>(defaultContextValue);

export type MobileAssetModalContextProviderProps = {
  children: ReactNode;
  // This was added as a fix for [ONC-332](https://linear.app/air/issue/ONC-332/public-clip-page-crashes-on-mobile)
  // Once public mobile pages are migrated to new navigation, it should be removed
  stickOnTop?: boolean;
};

export const MobileAssetModalContextProvider = ({ children, stickOnTop }: MobileAssetModalContextProviderProps) => {
  const assetModalRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { height: navigationHeight, ref: navigationRef } = useResizeDetector();

  const scrollToPanel = useCallback(() => {
    const scrollTo = () => {
      const top = panelRef.current?.offsetTop && navigationHeight ? panelRef.current.offsetTop - navigationHeight : 0;

      assetModalRef.current?.scrollTo({
        top,
        behavior: 'smooth',
      });
    };

    setTimeout(scrollTo, 100);
  }, [navigationHeight]);

  const scrollToTop = useCallback(() => {
    assetModalRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const scrollToBottom = useCallback(() => {
    const scrollTo = () =>
      assetModalRef.current?.scrollTo({
        top: assetModalRef.current.scrollHeight,
        behavior: 'smooth',
      });

    setTimeout(scrollTo, 100);
  }, []);

  const value = useMemo<MobileAssetModalContextValue>(
    () => ({
      assetModalRef,
      navigationHeight: stickOnTop ? 0 : navigationHeight,
      navigationRef,
      panelRef,
      scrollToBottom,
      scrollToPanel,
      scrollToTop,
    }),
    [navigationHeight, navigationRef, scrollToBottom, scrollToPanel, scrollToTop, stickOnTop],
  );

  return <MobileAssetModalContext.Provider value={value}>{children}</MobileAssetModalContext.Provider>;
};

export const useMobileAssetModalContext = () => {
  const context = useContext(MobileAssetModalContext);

  if (context === defaultContextValue) {
    const error = 'useMobileAssetModalContext must be used within a MobileAssetModalContextProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
