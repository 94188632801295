import { memo, PropsWithChildren } from 'react';

type AssetModalHeaderTitleContainerProps = object;

export const AssetModalHeaderTitleContainer = memo(
  ({ children }: PropsWithChildren<AssetModalHeaderTitleContainerProps>) => {
    return (
      <div className="flex w-full pl-3 pr-2">
        <div className="w-full">{children}</div>
      </div>
    );
  },
);

AssetModalHeaderTitleContainer.displayName = 'AssetModalHeaderTitleContainer';
