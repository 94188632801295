import { noop } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useMemo, useRef } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface VideoControlContextType {
  setVideoEl: (videoEl: HTMLVideoElement) => void;
  play: () => void;
  pause: () => void;
  setCurrentTime: (timeInSeconds: number) => void;
}

const defaultValue: VideoControlContextType = {
  setVideoEl: noop,
  pause: noop,
  play: noop,
  setCurrentTime: noop,
};

const VideoControlContext = createContext(defaultValue);

export interface VideoControlProviderProps {
  children: ReactNode;
}

export const VideoControlProvider = ({ children }: VideoControlProviderProps) => {
  const videoRef = useRef<HTMLVideoElement>();

  const setVideoEl: VideoControlContextType['setVideoEl'] = useCallback((videoEl) => (videoRef.current = videoEl), []);

  const pause = useCallback(() => {
    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause();
    }
  }, []);

  const play = useCallback(() => {
    if (videoRef.current?.paused) {
      videoRef?.current?.play();
    }
  }, []);

  const setCurrentTime = useCallback((time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
      if (videoRef.current.paused) {
        // setting currentTime has a delay if that video chunk is not loaded yet
        // But if we call play (and pause) it will force the video to go to the time even if it is not preloaded
        videoRef.current.play().catch(() => {
          // an error will be thrown if the video is not loaded yet and we call pause just after play
          // we can safely ignore it
        });
        videoRef.current.pause();
      }
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      setVideoEl,
      pause,
      play,
      setCurrentTime,
    }),
    [pause, play, setCurrentTime, setVideoEl],
  );

  return <VideoControlContext.Provider value={contextValue}>{children}</VideoControlContext.Provider>;
};

export function useVideoControlContext() {
  const context = useContext(VideoControlContext);

  if (context === defaultValue) {
    const error = 'VideoControlContext used outside of VideoControlProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
}
