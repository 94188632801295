import { useMemo } from 'react';

import { BOUNDING_BOX_AVATAR_SIZE } from '~/components/BoundingBox/components/BoundingBoxAvatar';
import { BOUNDING_BOX_TEXT_FORM_WIDTH } from '~/components/BoundingBox/constants';
import { BoundingBoxAvatarDirection } from '~/components/BoundingBox/types';

export const useBoundingBoxTextPosition = ({
  containerLeft,
  containerWidth,
  endX,
  endY,
  containerHeight,
  startX,
}: {
  containerLeft: number;
  containerWidth: number;
  containerHeight: number;

  startX: number;
  endX: number;
  endY: number;
}) => {
  const originalCommentPosition: BoundingBoxAvatarDirection = endX >= startX ? 'bottom-right' : 'bottom-left';

  const commentPosition = useMemo((): BoundingBoxAvatarDirection => {
    let position = originalCommentPosition;

    if (containerWidth) {
      if (position === 'bottom-left' && endX * containerWidth + containerLeft < BOUNDING_BOX_TEXT_FORM_WIDTH) {
        position = 'bottom-right';
      } else if (
        position === 'bottom-right' &&
        endX * containerWidth + containerLeft + BOUNDING_BOX_TEXT_FORM_WIDTH > window.innerWidth
      ) {
        position = 'bottom-left';
      }

      if (endY * containerHeight - BOUNDING_BOX_AVATAR_SIZE < 0) {
        return position.includes('left') ? 'top-left' : 'top-right';
      }
    }

    return position;
  }, [containerHeight, containerLeft, containerWidth, endX, endY, originalCommentPosition]);

  const { offsetBottom, offsetLeft } = useMemo(() => {
    if (!containerWidth || !containerHeight) {
      return { offsetLeft: 0, offsetBottom: 0 };
    }

    const offsetBottom = containerHeight - endY * containerHeight;

    const isHorizontalChanged =
      (commentPosition.includes('left') && !originalCommentPosition.includes('left')) ||
      (commentPosition.includes('right') && !originalCommentPosition.includes('right'));

    return {
      offsetBottom: commentPosition.includes('top') ? offsetBottom - BOUNDING_BOX_AVATAR_SIZE : offsetBottom,
      offsetLeft: isHorizontalChanged ? startX * containerWidth : endX * containerWidth,
    };
  }, [commentPosition, containerHeight, containerWidth, endX, endY, originalCommentPosition, startX]);

  return useMemo(
    () => ({
      position: commentPosition,
      offsetLeft,
      offsetBottom,
    }),
    [commentPosition, offsetBottom, offsetLeft],
  );
};
