import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

import { ASSET_MODAL_CLOSE } from '~/constants/testIDs';

interface HeaderCloseButtonProps {
  onClose: () => void;
}

export const HeaderCloseButton = memo(({ onClose }: HeaderCloseButtonProps) => {
  return (
    <div className="ml-7 flex items-center justify-start">
      <div className="mr-4 h-6 w-px rounded-sm bg-grey-5" />
      <IconButton
        appearance="ghost"
        color="grey"
        data-testid={ASSET_MODAL_CLOSE}
        icon={Close}
        label="Close"
        onClick={onClose}
        size="large"
      />
    </div>
  );
});

HeaderCloseButton.displayName = 'HeaderCloseButton';
