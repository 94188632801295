import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { ASSET_TITLE } from '~/constants/testIDs';
import { centralizedDisplayTitleSelector } from '~/store/centralizedClip/selectors';

export const AssetModalHeaderTitle = memo(() => {
  const title = useSelector(centralizedDisplayTitleSelector);

  return (
    <div className="ml-3 w-full truncate text-20 font-semibold text-grey-12" data-testid={ASSET_TITLE}>
      {title}
    </div>
  );
});

AssetModalHeaderTitle.displayName = 'AssetModalHeaderTitle';
