import { ComponentProps, memo } from 'react';

import { AnnotationUtilsPanel } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/AnnotationUtilsPanel';
import { CreateAnnotationButton } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/components/CreateAnnotationButton';
import { useAnnotationsToggle } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/hooks/useAnnotationsToggle';
import { useNoPreviewInVisualizer } from '~/hooks/useNoPreviewInVisualizer';

export type AnnotateToggleProps = Pick<ComponentProps<'div'>, 'className'>;

export const EditAnnotationsButton = memo(({ className }: AnnotateToggleProps) => {
  const { showAnnotations, isAnnotationsEnabled, hideAnnotations } = useAnnotationsToggle();
  const { noPreviewInVisualizer } = useNoPreviewInVisualizer();

  return (
    <div className={className}>
      {isAnnotationsEnabled ? (
        <AnnotationUtilsPanel onCloseClick={hideAnnotations} />
      ) : (
        <CreateAnnotationButton onClick={showAnnotations} shouldDisableAnnotations={noPreviewInVisualizer} />
      )}
    </div>
  );
});

EditAnnotationsButton.displayName = 'EditAnnotationsButton';
