import { useTrackCommentModeToggledOn } from '@air/analytics';
import { memo, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';

import { useClearAnnotationOnMouseDownOutside } from '~/components/Annotations/shared/useClearAnnotationOnMouseDownOutside';
import { useFullscreenContext } from '~/components/AssetModal/shared/context/FullscreenContext';
import { stopEscKeyPropagationOnAssetDetailsModal } from '~/components/AssetModal/shared/hooks/useAssetModalEscKeyHandler';
import { QueryParamNames } from '~/constants/search';
import { useAnnotationContext } from '~/providers/AnnotationProvider/AnnotationProvider';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';
import { isViewingAnnotatedCommentSelector } from '~/store/router/selectors';
import { setQueryParams } from '~/utils/PathUtils';

interface AnnotationsKeyShortcutsProps {
  enabled: boolean;
}

export const AnnotationsKeyShortcuts = memo(({ enabled }: AnnotationsKeyShortcutsProps) => {
  const hasActiveDiscussion = useSelector(isViewingAnnotatedCommentSelector);
  const prevHasActiveDiscussion = usePrevious(hasActiveDiscussion);

  const { isFullscreen } = useFullscreenContext();
  const isAnnotatingEnabled = enabled && !isFullscreen;
  const { setAnnotationsEnabled, setAnnotationType, undo, redo } = useAnnotationContext();
  // TODO: when we move activeTab to url, move this component to a hook
  // currently it is in a separate component to prevent parent rerendering when activeTab changes
  const { togglePanel } = useAssetModalPanelContext();
  const { trackCommentModeToggledOn } = useTrackCommentModeToggledOn();

  useClearAnnotationOnMouseDownOutside();

  useEffect(() => {
    if (prevHasActiveDiscussion !== hasActiveDiscussion) {
      stopEscKeyPropagationOnAssetDetailsModal(hasActiveDiscussion);
    }
  }, [hasActiveDiscussion, prevHasActiveDiscussion]);

  useHotkeys(
    'esc',
    () => {
      setQueryParams({ [QueryParamNames.discussionId]: null }, 'replace');
    },
    {
      enabled: hasActiveDiscussion,
    },
  );

  useHotkeys(
    'meta+shift+c,ctrl+shift+c',
    () => {
      togglePanel(AssetModalPanel.COMMENTS);
    },
    {
      enabled: !isFullscreen,
      enableOnFormTags: ['TEXTAREA'],
      preventDefault: true,
    },
    [togglePanel],
  );

  useHotkeys(
    'meta+shift+a,ctrl+shift+a',
    () => {
      setAnnotationType('box');
      trackCommentModeToggledOn();
      setAnnotationsEnabled(true);
    },
    {
      enabled: isAnnotatingEnabled,
      enableOnFormTags: ['TEXTAREA'],
      preventDefault: true,
    },
    [setAnnotationType, setAnnotationsEnabled],
  );

  useHotkeys(
    'meta+shift+d,ctrl+shift+d',
    () => {
      setAnnotationType('pencil');
      setAnnotationsEnabled(true);
    },
    {
      enabled: isAnnotatingEnabled,
      enableOnFormTags: ['TEXTAREA'],
      preventDefault: true,
    },
    [setAnnotationType, setAnnotationsEnabled],
  );

  useHotkeys(
    'meta+z,ctrl+z',
    () => undo(),
    {
      enabled: isAnnotatingEnabled,
      enableOnFormTags: ['TEXTAREA'],
      preventDefault: true,
    },
    [undo],
  );

  useHotkeys(
    'meta+shift+z,ctrl+shift+z',
    () => redo(),
    {
      enabled: isAnnotatingEnabled,
      enableOnFormTags: ['TEXTAREA'],
      preventDefault: true,
    },
    [redo],
  );

  return null;
});

AnnotationsKeyShortcuts.displayName = 'AnnotationsKeyShortcuts';
