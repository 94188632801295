import { memo, useCallback, useEffect, useMemo, useRef } from 'react';

import { AnnotationAvatarButton } from '~/components/Annotations/AnnotationsAvatarsContainer/AnnotationAvatarContainer/components/AnnotationAvatarButton';
import { AnnotationComment } from '~/components/Annotations/AnnotationsAvatarsContainer/AnnotationAvatarContainer/components/AnnotationComment';
import { getAnnotationCommentPos } from '~/components/Annotations/shared/annotationUtils';
import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { useDiscussionPanelContextSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { DiscussionsPanelContextValue } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { BOUNDING_BOX_AVATAR_SIZE } from '~/components/BoundingBox/components/BoundingBoxAvatar';
import { useBoundingBoxTextPosition } from '~/components/BoundingBox/hooks/useBoundingBoxTextPosition';
import { PopperOptions, usePopperContainer } from '~/hooks/usePopperContainer';
import { isViewingDiscussionWithId } from '~/store/router/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface AnnotationAvatarContainerProps {
  discussion: ClipDiscussion;
  onAvatarClick: (discussion: ClipDiscussion) => void;

  containerWidth: number;
  containerHeight: number;
  containerLeft: number;
  containerTop: number;

  canEdit: boolean;
  canDelete: boolean;
  canResolve: boolean;
  canShowMentionsButton: boolean;
  canReply: boolean;
}

export const AnnotationAvatarWithComment = memo(
  ({
    containerHeight,
    containerLeft,
    containerWidth,
    containerTop,
    onAvatarClick,
    discussion,
    canResolve,
    canReply,
    canShowMentionsButton,
    canEdit,
    canDelete,
  }: AnnotationAvatarContainerProps) => {
    const containerRef = useRef(null);
    const isActive = useAirSelector((st) => isViewingDiscussionWithId(st, discussion.id));

    const isReplyingCurrentDiscussionIdSelector = useCallback(
      (state: DiscussionsPanelContextValue) => state.replyingDiscussionId === discussion.id,
      [discussion.id],
    );

    const isReplyingToDiscussion = useDiscussionPanelContextSelector(isReplyingCurrentDiscussionIdSelector);

    const { minX, maxX, maxY } = useMemo(() => getAnnotationCommentPos(discussion.annotation), [discussion.annotation]);

    const { offsetLeft, offsetBottom, position } = useBoundingBoxTextPosition({
      startX: minX,
      endY: maxY,
      endX: maxX,
      containerLeft,
      containerWidth,
      containerHeight,
    });

    const popperOptions = useMemo<PopperOptions>(
      () => ({
        modifiers: [
          {
            placement: 'left-start',
            name: 'offset',
            options: {
              offset: ({ popper: { width } }: { popper: { width: number } }) => {
                return [position.includes('left') ? -width / 2 - 26 : width / 2 + 26, -BOUNDING_BOX_AVATAR_SIZE - 8];
              },
            },
          },
        ],
      }),
      [position],
    );

    const buttonPos = useMemo(() => {
      if (position.includes('left')) {
        return {
          left: offsetLeft - BOUNDING_BOX_AVATAR_SIZE + 2,
          bottom: offsetBottom - 1,
        };
      } else {
        return {
          left: offsetLeft + 1,
          bottom: offsetBottom - 1,
        };
      }
    }, [offsetBottom, offsetLeft, position]);

    const { PopperContainer, popperInstance } = usePopperContainer({
      containerRef,
      usePortal: true,
      options: popperOptions,
    });

    useEffect(() => {
      popperInstance?.current?.update();
    }, [containerHeight, containerWidth, containerLeft, popperInstance, containerTop]);

    const comment = useMemo(
      () =>
        isActive && !isReplyingToDiscussion ? (
          <PopperContainer>
            <AnnotationComment
              canEdit={canEdit}
              canReply={canReply}
              canResolve={canResolve}
              canDelete={canDelete}
              canShowMentionsButton={canShowMentionsButton}
              discussion={discussion}
              position={position}
            />
          </PopperContainer>
        ) : null,
      [
        PopperContainer,
        canDelete,
        canEdit,
        canReply,
        canResolve,
        canShowMentionsButton,
        discussion,
        isActive,
        isReplyingToDiscussion,
        position,
      ],
    );

    return (
      <div
        className="absolute flex"
        ref={containerRef}
        style={{
          ...buttonPos,
          pointerEvents: 'all',
        }}
        /**
         * Stop click propagation to prevent the popper from closing when clicking
         * on any the menu items. This is because the menu are rendered inside a
         * portal and the popper is rendered inside the annotation container.
         */
        onClick={(event) => event.stopPropagation()}
        onMouseDown={(event) => event.stopPropagation()}
      >
        {position.includes('left') && comment}
        <AnnotationAvatarButton discussion={discussion} onAvatarClick={onAvatarClick} position={position} />
        {position.includes('right') && comment}
      </div>
    );
  },
);

AnnotationAvatarWithComment.displayName = 'AnnotationAvatarWithComment';
