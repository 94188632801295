import { ClipStatus, ClipType } from '@air/api/types';
import { useSelector } from 'react-redux';

import {
  centralizedClipStatusSelector,
  centralizedClipTypeSelector,
  centralizedClipVideoUrlSelector,
} from '~/store/centralizedClip/selectors';
import { getAssetIsProcessing } from '~/utils/ClipUtils';

export const useNoPreviewInVisualizer = () => {
  const clipStatus = useSelector(centralizedClipStatusSelector);
  const clipType = useSelector(centralizedClipTypeSelector);
  const videoUrl = useSelector(centralizedClipVideoUrlSelector);
  const isAssetProcessing = getAssetIsProcessing({ status: clipStatus });

  const noPreviewInVisualizer =
    clipStatus === ClipStatus.created ||
    (clipType === ClipType.video && !videoUrl) ||
    (isAssetProcessing && clipType !== ClipType.video);

  return { noPreviewInVisualizer };
};
