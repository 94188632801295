import { memo, ReactNode } from 'react';

import { PanelProps } from './Panel/Panel';

export interface AssetModalDesktopLayoutProps {
  arrows?: ReactNode;
  panel: PanelProps['panel'];
  header: ReactNode;
  content: ReactNode;
}

export const AssetModalDesktopLayout = memo(({ arrows, panel, header, content }: AssetModalDesktopLayoutProps) => {
  return (
    <>
      <div className="fixed left-0 top-0 flex h-full w-full flex-col items-start justify-start overflow-hidden bg-grey-2">
        {header}
        {/* MAIN CONTENT WRAPPER */}
        <div className="flex max-h-[calc(100%-90px)] min-h-0 w-full flex-1 items-start justify-start">
          {/* VISUALIZER & VERSIONS TRAY WRAPPER */}
          <div className="flex h-full max-h-full flex-1 flex-col items-start justify-start">{content}</div>
          {panel}
        </div>
      </div>
      {arrows}
    </>
  );
});

AssetModalDesktopLayout.displayName = 'AssetModalDesktopLayout';
