import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { TabPanel, Tabs } from '@air/primitive-tabs';
import { memo, useCallback, useMemo, useState } from 'react';

import { AnnotationsShortcutsInfo } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/components/AnnotationsShortcutsInfo';
import { BasicShortcutsInfo } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/components/BasicShortcutsInfo';
import { VideoShortcutsInfo } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/components/VideoShortcutsInfo';
import { KEYBOARD_SHORTCUTS_MODAL } from '~/constants/testIDs';

export const KeyboardShortcutsInfoModal = memo(({ onClose }: AirModalProps) => {
  const [activeTab, setActiveTab] = useState<string>('general');

  const panels = useMemo<TabPanel[]>(
    () => [
      {
        id: 'general',
        label: 'General',
        content: <BasicShortcutsInfo />,
      },
      {
        id: 'comments',
        label: 'Comments',
        content: <AnnotationsShortcutsInfo />,
      },
      {
        id: 'video',
        label: 'Video',
        content: <VideoShortcutsInfo />,
      },
    ],
    [],
  );

  const onTabChange = useCallback((value: string) => {
    setActiveTab(value);
  }, []);

  return (
    <Modal data-testid={KEYBOARD_SHORTCUTS_MODAL} isOpen onDismiss={onClose} dangerouslyBypassFocusLock>
      <header className="mb-6 flex items-center justify-between gap-2">
        <ModalTitle>Keyboard Shortcuts</ModalTitle>
        <ModalCloseButton onClick={onClose} />
      </header>
      <Tabs
        className="-mx-6 -mt-1"
        defaultValue={panels[0].id}
        listClassName="px-6"
        panels={panels}
        panelsClassName="px-6 pt-4"
        onValueChange={onTabChange}
        value={activeTab}
      />
    </Modal>
  );
});

KeyboardShortcutsInfoModal.displayName = 'KeyboardShortcutsInfoModal';
