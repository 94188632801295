import { CloudUpload } from '@air/next-icons';
import { MONTH_DAY_YEAR_FORMAT, parseAirDateToISO } from '@air/utilities';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { InfoPanelDetailValue } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailValue';
import { SOURCE_MAP } from '~/constants/sourceMap';
import {
  centralizedClipCreatedDateSelector,
  centralizedClipOwnerSelector,
  centralizedClipSourceSelector,
} from '~/store/centralizedClip/selectors';

export const InfoPanelUploadedBy = memo(() => {
  const owner = useSelector(centralizedClipOwnerSelector);
  const source = useSelector(centralizedClipSourceSelector);
  const uploadedAt = useSelector(centralizedClipCreatedDateSelector);

  return (
    <InfoPanelDetailsSection Icon={CloudUpload} title="Uploaded">
      <InfoPanelDetailValue
        value={format(parseAirDateToISO(uploadedAt), MONTH_DAY_YEAR_FORMAT)}
        additionalInfo={`Uploaded via ${SOURCE_MAP?.[source] ?? capitalize(source)} by ${owner.ownerName}`}
      />
    </InfoPanelDetailsSection>
  );
});

InfoPanelUploadedBy.displayName = 'InfoPanelUploadedBy';
