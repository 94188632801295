import { Account } from '@air/api/types';
import React, { memo, PropsWithChildren, RefObject, useEffect, useMemo } from 'react';

import { BoundingBoxAvatar } from '~/components/BoundingBox/components/BoundingBoxAvatar';
import { BOUNDING_BOX_LAYER_Z_INDEX } from '~/components/BoundingBox/constants';
import { useBoundingBoxTextPosition } from '~/components/BoundingBox/hooks/useBoundingBoxTextPosition';
import { getHorizontalOpposite } from '~/components/BoundingBox/types';
import { PopperOptions, usePopperContainer } from '~/hooks/usePopperContainer';

export interface BoundingBoxTextContainerProps {
  containerLeft: number;
  containerRef: RefObject<HTMLElement>;
  containerWidth: number;
  containerHeight: number;

  startX: number;
  endX: number;
  endY: number;

  user?: Account | null;
}

const BOUNDING_BOX_AVATAR_TOP_PADDING = 8;

export const BoundingBoxTextContainer = memo(
  ({
    startX,
    endY,
    endX,
    containerLeft,
    containerWidth,
    containerHeight,
    containerRef,
    children,
    user,
  }: PropsWithChildren<BoundingBoxTextContainerProps>) => {
    const { offsetBottom, offsetLeft, position } = useBoundingBoxTextPosition({
      containerWidth,
      containerHeight,
      containerLeft,
      endY,
      endX,
      startX,
    });

    const floatingCommentOptions: PopperOptions = useMemo(
      () => ({
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: ({ popper }: { popper: { height: number; width: number } }) => {
                const leftOffset = position.includes('right') ? offsetLeft + 1 : offsetLeft - popper.width + 2;

                return [leftOffset, -offsetBottom - popper.height + 26];
              },
            },
          },
        ],
      }),
      [offsetLeft, offsetBottom, position],
    );

    const { PopperContainer, popperInstance } = usePopperContainer({
      containerRef,
      options: floatingCommentOptions,
      usePortal: true,
    });

    useEffect(() => {
      popperInstance.current?.update();
    }, [containerLeft, popperInstance]);

    const Avatar = useMemo(
      () => (
        <div data-theme="dark" className="relative" style={{ paddingTop: BOUNDING_BOX_AVATAR_TOP_PADDING }}>
          <BoundingBoxAvatar
            // avatar's direction is opposite to comment's position
            direction={getHorizontalOpposite(position)}
            account={user}
          />
        </div>
      ),
      [position, user],
    );

    return (
      <PopperContainer style={{ zIndex: BOUNDING_BOX_LAYER_Z_INDEX + 1 }}>
        <div className="flex">
          {position.includes('right') && Avatar}
          {children}
          {position.includes('left') && Avatar}
        </div>
      </PopperContainer>
    );
  },
);

BoundingBoxTextContainer.displayName = 'BoundingBoxTextContainer';
