import { Account, CommentResponse } from '@air/api/types';
import { CognitoUser } from 'amazon-cognito-identity-js';

/**
 * This method checks is a user is an author of the comment:
 * - for full account - it returns true if account id matches comment account id
 * - for anonymous account - it returns true if session id matches comment account session id
 */
export const isCommentAuthor = ({
  comment,
  account,
  userSession,
}: {
  comment: CommentResponse;
  account?: Account;
  userSession?: CognitoUser;
}) => {
  if (!account) {
    return false;
  }

  if (account.type === 'full') {
    return comment.account.id === account?.id;
  } else if (account.type === 'anonymous' && !!userSession) {
    const sessionId = userSession.getSignInUserSession()?.getIdToken().payload.sub;
    return comment.account.anonymousAccountSessionId === sessionId;
  }

  return false;
};
