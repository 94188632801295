import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { centralizedDisplayTitleSelector } from '~/store/centralizedClip/selectors';

export const InfoPanelTitle = memo(() => {
  const title = useSelector(centralizedDisplayTitleSelector);

  return (
    <div className="flex w-full items-center justify-between px-2 py-1.5">
      <p className="w-full break-words text-16 font-semibold text-grey-12">{title}</p>
    </div>
  );
});

InfoPanelTitle.displayName = 'InfoPanelTitle';
