import {
  BaseMouseEvent,
  DrawRect,
  DrawRectFunction,
  PercentagePoint,
  PercentageRect,
  Point,
} from '~/components/BoundingBox/types';
import { MovableCorner } from '~/components/BoundingBox/utils/drawSquareAroundPoint';
import { GetDrawingCanvasHandle } from '~/components/BoundingBox/utils/types';
import { BOUNDING_BOX } from '~/constants/testIDs';
import { isCypressRun } from '~/utils/PathUtils';

const BORDER_WIDTH = 2;

type DrawBoundingBoxRectParams = {
  rectToDraw: PercentageRect;
  drawRectFunction: DrawRectFunction;
};

export type DrawPercentageRectOnCanvas = (params: { rect: PercentageRect; canvas: BoundingBoxCanvas }) => void;

export class BoundingBoxCanvas {
  private _containerWidth: number;
  private _containerHeight: number;
  public getDrawingCanvasHandle: GetDrawingCanvasHandle;

  private _overlayColor = '';

  constructor({
    containerWidth,
    containerHeight,
    getDrawingCanvasHandle,
    overlayColor,
  }: {
    containerWidth: number;
    containerHeight: number;
    getDrawingCanvasHandle: GetDrawingCanvasHandle;
    overlayColor?: string;
  }) {
    this._containerWidth = containerWidth;
    this._containerHeight = containerHeight;
    this.getDrawingCanvasHandle = getDrawingCanvasHandle;
    this._overlayColor = overlayColor || '#00000066';
  }

  set containerWidth(containerWidth: number) {
    this._containerWidth = containerWidth;
  }

  set containerHeight(containerHeight: number) {
    this._containerHeight = containerHeight;
  }

  getMouseCoords = (event: BaseMouseEvent) =>
    this.getDrawingCanvasHandle()?.getMouseCoords({ event, lineWidth: BORDER_WIDTH });

  drawOverlay = () => {
    const ctx = this.getDrawingCanvasHandle()?.getCanvas()?.getContext('2d');

    if (!ctx) {
      return;
    }

    ctx.shadowBlur = 0;
    ctx.fillStyle = this._overlayColor;
    ctx.fillRect(0, 0, this._containerWidth, this._containerHeight);
  };

  drawBoundingBoxRect = ({ rectToDraw, drawRectFunction }: DrawBoundingBoxRectParams) => {
    const ctx = this.getDrawingCanvasHandle()?.getCanvas()?.getContext('2d');
    if (!ctx) return;

    this.drawCypressBox(rectToDraw);

    // We could use the canvas context dimensions, but they're
    // not as precise (e.g. ctx.canvas.height could be 580 and containerHeight could be 580.99)
    // Not a huge deal, but it adds up especially at high zoom levels.
    drawRectFunction({
      context: ctx,
      rect: this.convertPercentageRectToDrawRect(rectToDraw),
    });
  };

  private drawCypressBox = (rect: PercentageRect) => {
    if (isCypressRun()) {
      const drawRect = this.convertPercentageRectToDrawRect(rect);
      const x = drawRect.startX;
      const y = drawRect.startY;
      const h = drawRect.endY - drawRect.startY;
      const w = drawRect.endX - drawRect.startX;
      const container = this.getDrawingCanvasHandle()?.getCanvas()?.parentElement;
      const elem = document.createElement('div');
      elem.dataset.testid = BOUNDING_BOX;
      elem.style.position = 'absolute';
      elem.style.width = `${w}px`;
      elem.style.height = `${h}px`;
      elem.style.top = `${y}px`;
      elem.style.left = `${x}px`;
      elem.style.border = '1px solid black';
      container?.appendChild(elem);
    }
  };

  drawPercentageRect = ({
    percentageRect,
    drawRectFunction,
  }: {
    percentageRect: PercentageRect;
    drawRectFunction: DrawRectFunction;
  }) => {
    if (!this._containerWidth || !this._containerHeight) return;

    if (percentageRect.pctStartX === percentageRect.pctEndX && percentageRect.pctStartY === percentageRect.pctEndY) {
      return;
    }

    this.drawBoundingBoxRect({ rectToDraw: percentageRect, drawRectFunction });
  };

  private clearCypressBoxes = () => {
    if (isCypressRun()) {
      const container = this.getDrawingCanvasHandle()?.getCanvas()?.parentElement;
      if (container) {
        const boundingBoxes = container?.querySelectorAll(`[data-testid=${BOUNDING_BOX}]`);
        boundingBoxes?.forEach((an) => container?.removeChild(an));
      }
    }
  };

  clearRect = () => {
    this.clearCypressBoxes();
    this.getDrawingCanvasHandle()?.clearCanvas();
  };

  convertPercentageRectToDrawRect = (box: PercentageRect): DrawRect => ({
    startX: box.pctStartX * this._containerWidth,
    startY: box.pctStartY * this._containerHeight,
    endX: box.pctEndX * this._containerWidth,
    endY: box.pctEndY * this._containerHeight,
  });

  convertDrawRectToPercentageRect = (rect: DrawRect): PercentageRect => ({
    pctStartX: rect.startX / this._containerWidth,
    pctStartY: rect.startY / this._containerHeight,
    pctEndX: rect.endX / this._containerWidth,
    pctEndY: rect.endY / this._containerHeight,
  });

  convertPointToPercentagePoint = (point: Point): PercentagePoint => ({
    pctX: point.x / this._containerWidth,
    pctY: point.y / this._containerHeight,
  });

  setResizeCursor = (hoveredCorner: MovableCorner) => {
    const canvas = this.getDrawingCanvasHandle()?.getCanvas();
    if (!canvas) return;

    switch (hoveredCorner) {
      case 'top-left':
      case 'bottom-right':
        canvas.style.cursor = 'nwse-resize';
        break;
      case 'top-right':
      case 'bottom-left':
        canvas.style.cursor = 'nesw-resize';
        break;
      default:
        canvas.style.removeProperty('cursor');
        break;
    }
  };

  setCursor = (cursor: string) => {
    const canvas = this.getDrawingCanvasHandle()?.getCanvas();
    if (!canvas) return;
    canvas.style.cursor = cursor;
  };

  setDefaultCursor = () => {
    const canvas = this.getDrawingCanvasHandle()?.getCanvas();
    if (!canvas) return;
    canvas.style.removeProperty('cursor');
  };

  getCanvasSize = () => {
    return { width: this._containerWidth, height: this._containerHeight };
  };
}
