import { useHotkeys } from 'react-hotkeys-hook';

export const useAssetHotkeys = ({
  zoomIn,
  zoomOut,
  openFullscreen,
  isFullscreen,
}: {
  zoomIn?: () => void;
  zoomOut?: (zoom?: number) => void;
  openFullscreen?: () => void;
  isFullscreen?: boolean;
}) => {
  useHotkeys(
    'meta-=,ctrl-=,meta-+,ctrl-+,meta-shift-=,ctrl-shift-=',
    () => zoomIn?.(),
    {
      combinationKey: '-',
      enabled: !!zoomIn,
      preventDefault: true,
    },
    [zoomIn],
  );

  useHotkeys(
    'meta+minus,ctrl+minus',
    () => zoomOut?.(),
    {
      enabled: !!zoomOut,
      preventDefault: true,
    },
    [zoomOut],
  );

  useHotkeys(
    'meta+shift+0,ctrl+shift+0',
    () => zoomOut?.(1),
    {
      enabled: !!zoomOut,
      preventDefault: true,
    },
    [zoomOut],
  );

  useHotkeys(
    'meta+0,ctrl+0',
    () => openFullscreen?.(),
    {
      enabled: !isFullscreen && !!openFullscreen,
      preventDefault: true,
    },
    [zoomOut],
  );
};
