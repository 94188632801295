import { EmojiMenuProps } from '@air/component-emoji-menu';
import { At, Emoji, SendFilled } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useBreakpointsContext } from '@air/provider-media-query';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { memo } from 'react';

const EmojiMenu = dynamic<EmojiMenuProps>(() => import('@air/component-emoji-menu').then((mod) => mod.EmojiMenu), {
  ssr: false,
});

import {
  DISCUSSION_FORM_BUTTON_CANCEL,
  DISCUSSION_FORM_BUTTON_SUBMIT,
  DISCUSSION_FORM_BUTTONS_CONTAINER,
} from '~/constants/testIDs';

interface CommentActionsProps {
  onCancel: () => void;
  onEmojiPick: (emoji: string) => void;
  hasCancelButton?: boolean;
  isDisabled: boolean;
  addTriggerProgrammatically: () => void;
  shouldShowMentionsButton?: boolean;
  hasInternalAvatar?: boolean;
}
export const CommentActions = memo(
  ({
    onCancel,
    onEmojiPick,
    hasCancelButton,
    isDisabled,
    addTriggerProgrammatically,
    shouldShowMentionsButton = true,
    hasInternalAvatar,
  }: CommentActionsProps) => {
    const { isAboveMediumScreen } = useBreakpointsContext();

    return (
      <div
        className={classNames(
          'ml-1 flex max-h-[40px] flex-1 items-center justify-end gap-2',
          hasInternalAvatar ? 'mr-0 mt-1' : 'mr-1 mt-0',
        )}
        data-testid={DISCUSSION_FORM_BUTTONS_CONTAINER}
      >
        {hasCancelButton && (
          <Button
            appearance="ghost"
            color="grey"
            data-testid={DISCUSSION_FORM_BUTTON_CANCEL}
            onClick={onCancel}
            size="small"
          >
            Cancel
          </Button>
        )}

        {isAboveMediumScreen && (
          <EmojiMenu
            trigger={<IconButton appearance="ghost" color="grey" label="Insert emoji" icon={Emoji} size="small" />}
            onSelect={(emoji) => onEmojiPick(emoji.native)}
          />
        )}

        {shouldShowMentionsButton && (
          <Tooltip label="Mention someone" side="top">
            <IconButton
              appearance="ghost"
              color="grey"
              label="Mention"
              size="small"
              onClick={addTriggerProgrammatically}
              icon={At}
            />
          </Tooltip>
        )}
        <IconButton
          appearance="filled"
          color="blue"
          data-testid={DISCUSSION_FORM_BUTTON_SUBMIT}
          disabled={isDisabled}
          icon={SendFilled}
          label="Send"
          size="small"
          type="submit"
        />
      </div>
    );
  },
);

CommentActions.displayName = 'CommentActions';
