import { Discussions } from '@air/api';
import { CommentResponse } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { Query, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { ClipDiscussionsCacheType } from '~/swr-hooks/discussions/types';
import { deleteCommentInCache, isClipDiscussionsKey } from '~/swr-hooks/discussions/utils';

interface DeleteCommentParams {
  existingComment: CommentResponse;
  boardId?: string;
  shortId?: string;
  assetId: string;
}

export const useDeleteComment = () => {
  const queryClient = useQueryClient();
  const deleteComment = useCallback(
    async ({ existingComment, shortId, boardId, assetId }: DeleteCommentParams) => {
      try {
        const queryKeyPredicate = ({ queryKey }: Query) =>
          isClipDiscussionsKey(queryKey) && queryKey[1].assetId === assetId;

        queryClient.setQueriesData<ClipDiscussionsCacheType>(
          {
            predicate: queryKeyPredicate,
          },
          (existingDiscussions) => deleteCommentInCache({ existingComment }, existingDiscussions),
        );

        // TODO: fix boardId assertion once backend updates with proper typings.
        // BOARDID *IS* POSSIBLY UNDEFINED
        // see: https://github.com/AirLabsTeam/backend/pull/2783
        await Discussions.deleteComment({ commentId: existingComment.id, boardId: boardId!, shortId });

        queryClient.invalidateQueries({ predicate: queryKeyPredicate });
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to delete comment',
          metadata: { data: { existingComment, shortId, boardId } },
        });
      }
    },
    [queryClient],
  );

  return {
    deleteComment,
  };
};
