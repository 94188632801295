import { ClipMetadata } from '@air/api';
import { Copyright } from '@air/next-icons';
import { memo } from 'react';

import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { InfoPanelDetailValue } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailValue';
import { isStringMetadataType } from '~/utils/iptcDataTypes';

interface InfoPanelCopyrightProps {
  clipMetadata: ClipMetadata[];
}

export const InfoPanelCopyright = memo(({ clipMetadata }: InfoPanelCopyrightProps) => {
  const copyrightNotice = clipMetadata.find((metadata) => metadata.fieldName === 'CopyrightNotice');
  const usageTerms = clipMetadata.find((metadata) => metadata.fieldName === 'UsageTerms');

  if (!copyrightNotice || !isStringMetadataType(copyrightNotice) || !copyrightNotice.data) {
    return null;
  }

  return (
    <InfoPanelDetailsSection Icon={Copyright} title="Copyright">
      <InfoPanelDetailValue
        value={copyrightNotice.data}
        additionalInfo={!!usageTerms && isStringMetadataType(usageTerms) ? usageTerms.data : undefined}
      />
    </InfoPanelDetailsSection>
  );
});

InfoPanelCopyright.displayName = 'InfoPanelCopyright';
