import { Check, FullScreenEnter, FullScreenOut, ZoomIn, ZoomOut } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import classNames from 'classnames';
import { forwardRef, memo } from 'react';

import { useAssetHotkeys } from '~/components/AssetModal/Visualizer/shared/useAssetHotkeys';

import { MAX_ZOOM, MIN_ZOOM } from '../shared/constants';

interface ImageVisualizerControlsProps {
  isFullscreen: boolean;
  isShowOriginal: boolean;
  onShowOriginalChange?: (value: boolean) => void;
  onToggleFullScreen: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  zoom: number;
}

const ForwardedImageVisualizerControls = forwardRef<HTMLDivElement, ImageVisualizerControlsProps>(
  (
    {
      isFullscreen,
      isShowOriginal = false,
      onShowOriginalChange,
      onToggleFullScreen,
      onZoomIn,
      onZoomOut,
      zoom,
    }: ImageVisualizerControlsProps,
    ref,
  ) => {
    useAssetHotkeys({
      zoomOut: onZoomOut,
      zoomIn: onZoomIn,
      openFullscreen: onToggleFullScreen,
      isFullscreen,
    });

    return (
      <div
        ref={ref}
        className={classNames(
          'pointer-events-none absolute bottom-8 left-0 z-[5] flex h-12 w-full justify-center px-8',
          isFullscreen ? 'bottom-6 h-14' : undefined,
        )}
      >
        <div className="pointer-events-none relative flex h-full w-full max-w-[600px] items-center justify-center gap-2 rounded-lg bg-black/80 px-3 py-2 opacity-0 backdrop-blur transition-opacity duration-200 ease-in-out group-hover/visualizer:pointer-events-auto group-hover/visualizer:opacity-100">
          <div className="absolute left-3 top-0 flex h-full items-center justify-start gap-2">
            {!!onShowOriginalChange && (
              <Button
                appearance="ghost"
                color="grey"
                onClick={() => onShowOriginalChange(!isShowOriginal)}
                prefix={isShowOriginal ? <Check className="h-4 w-4" /> : null}
                size="small"
              >
                Show original
              </Button>
            )}
          </div>

          <Tooltip label="Zoom out">
            <IconButton
              appearance="ghost"
              className="disabled:bg-transparent disabled:text-grey-8"
              color="grey"
              disabled={zoom === MIN_ZOOM}
              icon={ZoomOut}
              label="Zoom out"
              onClick={() => onZoomOut()}
              size="small"
            />
          </Tooltip>

          <div className="flex h-full min-w-[40px] items-center justify-center text-center">
            <div className={classNames('text-12 font-medium text-grey-10', isFullscreen ? 'text-ui-14' : 'text-ui-12')}>
              {`${Math.round(100 * zoom)}%`}
            </div>
          </div>

          <IconButton
            appearance="ghost"
            className="disabled:bg-transparent disabled:text-grey-8"
            color="grey"
            disabled={zoom === MAX_ZOOM}
            icon={ZoomIn}
            label="Zoom in"
            onClick={onZoomIn}
            size="small"
          />

          <div className="absolute right-3 top-0 flex h-full items-center justify-end gap-2">
            <IconButton
              appearance="ghost"
              color="grey"
              icon={isFullscreen ? FullScreenOut : FullScreenEnter}
              label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
              onClick={onToggleFullScreen}
              size="small"
            />
          </div>
        </div>
      </div>
    );
  },
);

export const ImageVisualizerControls = memo(ForwardedImageVisualizerControls);

ForwardedImageVisualizerControls.displayName = 'ImageVisualizerControls';

ImageVisualizerControls.displayName = 'MemoizedImageVisualizerControls';
