import { RefObject, useCallback, useEffect, useRef } from 'react';

/**
 * This hook runs an interval every X ms to get the current time of the video
 * and calls the onTimeUpdate callback with the current time.
 * There is an event listener for the 'timeupdate' event on the video element,
 * but it is called every 50-250ms, which is not frequent enough for our use case.
 */
export const useVideoCurrentTimeObserver = ({
  videoRef,
  onTimeUpdate,
  interval,
}: {
  videoRef: RefObject<HTMLVideoElement>;
  onTimeUpdate: (currentTime: number) => void;
  interval: number;
}) => {
  const intervalRef = useRef<number | null>(null);
  const currentTimeRef = useRef<number | null>(null);

  const stopObservingCurrentTime = useCallback(() => {
    if (intervalRef.current !== null) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const startObservingCurrentTime = useCallback(() => {
    stopObservingCurrentTime();
    intervalRef.current = window.setInterval(() => {
      if (!videoRef.current) {
        return;
      }
      const currentTime = videoRef.current.currentTime;
      if (currentTimeRef.current !== currentTime) {
        currentTimeRef.current = currentTime;
        onTimeUpdate(currentTime);
      }
    }, interval);
  }, [interval, onTimeUpdate, stopObservingCurrentTime, videoRef]);

  useEffect(() => {
    return () => {
      stopObservingCurrentTime();
    };
  }, [stopObservingCurrentTime]);

  return {
    startObservingCurrentTime,
    stopObservingCurrentTime,
  };
};
