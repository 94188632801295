import { Discussion } from '@air/api/types';
import { RefObject, useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import {
  useDiscussionPanelContext,
  useDiscussionPanelContextSelector,
} from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { DiscussionsPanelContextValue } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { DiscussionFormHandle } from '~/components/Discussions/DiscussionForm';

export const useCommentReply = ({
  formRef,
  onSubmit,
  discussionId,
}: {
  discussionId: Discussion['id'];
  formRef: RefObject<DiscussionFormHandle>;
  onSubmit: (body: string) => void;
}) => {
  const { clearReplyingDiscussionId } = useDiscussionPanelContext();

  const isReplyingCurrentDiscussionIdSelector = useCallback(
    (state: DiscussionsPanelContextValue) => state.replyingDiscussionId === discussionId,
    [discussionId],
  );

  const isAddingReply = useDiscussionPanelContextSelector(isReplyingCurrentDiscussionIdSelector);

  useEffect(() => {
    if (isAddingReply) {
      formRef.current?.focus();
    }
  }, [formRef, isAddingReply]);

  useHotkeys(
    'esc',
    () => {
      clearReplyingDiscussionId();
    },
    { enabled: isAddingReply, enableOnFormTags: ['TEXTAREA'] },
    [],
  );

  const onCommentSubmit = useCallback(
    (body: string) => {
      onSubmit(body);
      clearReplyingDiscussionId();
    },
    [clearReplyingDiscussionId, onSubmit],
  );

  return {
    clearReplyingDiscussionId,
    onCommentSubmit,
    isAddingReply,
  };
};
