import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentProps } from 'react';

export type DiscussionsButtonProps = ComponentProps<'button'>;

export const DiscussionsButton = ({ className, ...restOfProps }: DiscussionsButtonProps) => {
  return (
    <button
      className={tailwindMerge('rounded-sm p-1 text-12 font-semibold text-grey-11 hover:bg-grey-5', className)}
      data-testid="DISCUSSIONS_BUTTON"
      {...restOfProps}
    />
  );
};
