import { DiscussionLocation, useTrackEditedComment } from '@air/analytics';
import { Annotation, Clip, CommentResponse } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';

import { getAnnotationAnalyticsType, isAnnotationCorrect } from '~/components/Annotations/shared/annotationUtils';
import { centralizedClipAssetIdSelector, centralizedClipTypeSelector } from '~/store/centralizedClip/selectors';
import { useEditComment } from '~/swr-hooks/discussions/useEditComment';
import { getBoardIdFromPath, getShortIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useSaveEditedCommentClick = ({ trackLocation }: { trackLocation: DiscussionLocation }) => {
  const store = useAirStore();
  const {
    editComment: { mutateAsync: editComment },
  } = useEditComment();
  const { trackEditedComment } = useTrackEditedComment();
  const { showToast } = useToasts();

  const handleSaveEditedCommentClick = useCallback(
    async ({
      newComment,
      comment,
      clipId,
      timestamp,
      annotation,
    }: {
      newComment: string;
      comment: CommentResponse;
      clipId: Clip['id'];
      timestamp?: number;
      annotation?: Annotation;
    }) => {
      const assetId = centralizedClipAssetIdSelector(store.getState());
      const boardId = getBoardIdFromPath(window.location.pathname);
      const shortId = getShortIdFromPath(window.location.pathname);

      trackEditedComment({
        boardId,
        clipId,
        comment: newComment,
        location: trackLocation,
        has_timestamp: !isUndefined(timestamp),
        has_annotation: isAnnotationCorrect(annotation),
        annotationType: getAnnotationAnalyticsType(annotation),
        assetType: centralizedClipTypeSelector(store.getState()),
      });

      try {
        await editComment(
          {
            existingComment: comment,
            newComment,
            boardId,
            shortId,
            assetId,
          },
          {
            onError: () => {
              showToast('Failed to edit comment', { color: 'red' });
            },
          },
        );
      } catch (_e) {
        showToast('Failed to edit comment', { color: 'red' });
      }
    },
    [store, trackEditedComment, trackLocation, editComment, showToast],
  );

  return {
    handleSaveEditedCommentClick,
  };
};
