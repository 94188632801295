import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { hasAnnotation } from '~/components/Annotations/shared/annotationUtils';
import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { QueryParamNames } from '~/constants/search';
import { useAnnotationContext } from '~/providers/AnnotationProvider/AnnotationProvider';
import { activeDiscussionIdParamSelector } from '~/store/router/selectors';
import { setQueryParams } from '~/utils/PathUtils';

export const NEW_DISCUSSION_ID = 'newDiscussionId';

export const useActiveDiscussionIdObserver = (discussions: ClipDiscussion[]) => {
  const activeDiscussionId = useSelector(activeDiscussionIdParamSelector);
  const { setActiveAnnotation } = useAnnotationContext();

  useEffect(() => {
    if (activeDiscussionId === NEW_DISCUSSION_ID) {
      return;
    }
    if (activeDiscussionId) {
      const discussion = discussions.find((d) => d.id === activeDiscussionId && !!d.annotation);

      if (discussion && hasAnnotation(discussion)) {
        setActiveAnnotation(discussion.annotation);
      } else {
        setQueryParams({ [QueryParamNames.discussionId]: null }, 'replace');
        setActiveAnnotation(undefined);
      }
    } else {
      setActiveAnnotation(undefined);
    }
  }, [activeDiscussionId, discussions, setActiveAnnotation]);
};
