import { Ai } from '@air/next-icons';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { centralizedClipKeywordsSelector } from '~/store/centralizedClip/selectors';

import { InfoPanelKeyword, InfoPanelKeywordProps } from './component/InfoPanelKeyword';

export interface InfoPanelKeywordsProps {
  onKeywordClick?: InfoPanelKeywordProps['onClick'];
}

export const InfoPanelKeywords = memo(({ onKeywordClick }: InfoPanelKeywordsProps) => {
  const keywords = useSelector(centralizedClipKeywordsSelector);

  if (!keywords.length) return null;

  return (
    <InfoPanelDetailsSection Icon={Ai} title="AI Keywords" className="[&>svg]:mt-3">
      <div className="flex flex-wrap items-baseline space-y-2 pb-2 [&>button]:mr-1.5">
        {keywords.map(({ label, id }) => (
          <InfoPanelKeyword label={label} key={id} onClick={onKeywordClick} />
        ))}
      </div>
    </InfoPanelDetailsSection>
  );
});

InfoPanelKeywords.displayName = 'InfoPanelKeywords';
