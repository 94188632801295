import { CommentResponse } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import { formatFullName, formatInitials } from '@air/utilities';
import { memo } from 'react';

import { COMMENT_AVATAR_SIZE } from '~/components/Discussions/Discussion/shared/constants';
import { DISCUSSION_COMMENT_AVATAR } from '~/constants/testIDs';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

export interface CommentAvatarProps {
  account: CommentResponse['account'];
  isResolved: boolean;
}

export const CommentAvatar = memo(
  ({ account: { avatar, firstName, lastName, id }, isResolved }: CommentAvatarProps) => {
    const fullName = formatFullName(firstName, lastName);
    const initials = formatInitials(firstName, lastName);

    return (
      <Avatar
        alt={fullName}
        data-testid={DISCUSSION_COMMENT_AVATAR}
        size={COMMENT_AVATAR_SIZE}
        src={getAvatarImageSrc(avatar, COMMENT_AVATAR_SIZE)}
        text={initials}
        colorSeed={id}
        disabled={isResolved}
      />
    );
  },
);

CommentAvatar.displayName = 'CommentAvatar';
