import { Spinner } from '@air/primitive-spinner';
import { memo, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { FACE_ROW_HEIGHT } from '~/components/AssetModal/shared/components/FacesPanel/components/ClipFacesList/ClipFacesVirtualizedList';

interface DiscussionsLoadMoreItemProps {
  isLoading: boolean;
  loadMore: () => void;
  scrollElementRef: HTMLDivElement | null | undefined;
}

export const DiscussionsLoadMoreItem = memo(
  ({ loadMore, isLoading, scrollElementRef }: DiscussionsLoadMoreItemProps) => {
    const { ref, inView } = useInView({
      threshold: 0.01,
      rootMargin: '150px',
      root: scrollElementRef,
    });

    const isLoadingRef = useRef(isLoading);
    isLoadingRef.current = isLoading;

    useEffect(() => {
      if (inView && !isLoadingRef.current) {
        loadMore();
      }
    }, [inView, loadMore]);

    return (
      <div ref={ref} className="flex items-center justify-center py-1.5" style={{ height: FACE_ROW_HEIGHT }}>
        <Spinner className="h-8 w-8 text-teal-9" />
      </div>
    );
  },
);

DiscussionsLoadMoreItem.displayName = 'DiscussionsLoadMoreItem';
