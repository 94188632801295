import { IconButton, type IconButtonProps } from '@air/primitive-icon-button';
import { ElementType, memo } from 'react';

export const MOBILE_ASSET_MODAL_NAVIGATION_ITEM_WIDTH = 40;

export type MobileAssetModalNavigationItemProps = Pick<IconButtonProps, 'label' | 'onClick'> & {
  icon: ElementType;
  id: string;
  isActive?: boolean;
  order: number;
};

export const MobileAssetModalNavigationItem = memo(
  ({ icon, id, isActive, label, onClick, order }: MobileAssetModalNavigationItemProps) => {
    return (
      <IconButton
        appearance={isActive ? 'filled' : 'ghost'}
        color="grey"
        data-testid="MOBILE_ASSET_MODAL_NAVIGATION_ITEM"
        data-navigation-id={id}
        icon={icon}
        label={label}
        onClick={onClick}
        size="large"
        style={{ order }}
      />
    );
  },
);

MobileAssetModalNavigationItem.displayName = 'MobileAssetModalNavigationItem';
