import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AnnotationAvatarWithComment } from '~/components/Annotations/AnnotationsAvatarsContainer/AnnotationAvatarContainer/AnnotationAvatarWithComment';
import { PrivateAnnotationsAvatars } from '~/components/Annotations/AnnotationsAvatarsContainer/components/PrivateAnnotationsAvatars';
import { PublicAnnotationsAvatars } from '~/components/Annotations/AnnotationsAvatarsContainer/components/PublicAnnotationsAvatars';
import {
  AnnotationAvatarsFetcherProps,
  BaseAnnotationsAvatarsProps,
} from '~/components/Annotations/AnnotationsAvatarsContainer/shared/annotationAvatarsTypes';
import { getAnnotatedDiscussions } from '~/components/Annotations/AnnotationsAvatarsContainer/shared/annotationsAvatarsUtils';
import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { useDiscussionPanelContextSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { discussionsFilterSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextUtils';
import { useURLShortIdSelector } from '~/hooks/useURLShortIdSelector';
import { useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import { isAnnotationsEnabledSelector } from '~/providers/AnnotationProvider/annotationProviderUtils';
import { centralizedClipVersionNumberSelector } from '~/store/centralizedClip/selectors';
import { activeDiscussionIdParamSelector } from '~/store/router/selectors';

export interface AnnotationsAvatarsContainerProps extends BaseAnnotationsAvatarsProps {
  pageNumber: number;
  timestamp?: number;
  hideAnnotations?: boolean;
}

export const AnnotationsAvatarsContainer = memo(
  ({
    containerWidth,
    containerHeight,
    containerLeft,
    containerTop,
    onAvatarClick,
    pageNumber,
    timestamp,
    hideAnnotations,
  }: AnnotationsAvatarsContainerProps) => {
    const isOnPublicRoute = !!useURLShortIdSelector();
    const activeDiscussionId = useSelector(activeDiscussionIdParamSelector);

    const versionNumber = useSelector(centralizedClipVersionNumberSelector);
    const isAnnotationsEnabled = useAnnotationContextSelector(isAnnotationsEnabledSelector);

    const discussionsFilter = useDiscussionPanelContextSelector(discussionsFilterSelector);

    const renderAnnotations: AnnotationAvatarsFetcherProps['renderAnnotations'] = useCallback(
      ({ canReply, canShowMentionsButton, canDelete, discussions, canEdit, canResolve }) => {
        const activeDiscussion = activeDiscussionId ? discussions.find((d) => d.id === activeDiscussionId) : null;
        let annotatedDiscussions: ClipDiscussion[] = [];
        if (!hideAnnotations && (isAnnotationsEnabled || activeDiscussion)) {
          annotatedDiscussions = getAnnotatedDiscussions({
            discussions: activeDiscussion ? [activeDiscussion] : discussions,
            timestamp,
            pageNumber,
            assetVersion: versionNumber,
            filter: discussionsFilter,
          });
        }

        return (
          <div className="relative" style={{ width: containerWidth, height: containerHeight }}>
            {annotatedDiscussions.map((discussion) => (
              <AnnotationAvatarWithComment
                containerLeft={containerLeft}
                containerTop={containerTop}
                containerHeight={containerHeight}
                containerWidth={containerWidth}
                onAvatarClick={onAvatarClick}
                discussion={discussion}
                key={discussion.id}
                canReply={canReply}
                canDelete={canDelete}
                canEdit={canEdit}
                canResolve={canResolve}
                canShowMentionsButton={canShowMentionsButton}
              />
            ))}
          </div>
        );
      },
      [
        activeDiscussionId,
        hideAnnotations,
        isAnnotationsEnabled,
        containerWidth,
        containerHeight,
        timestamp,
        pageNumber,
        versionNumber,
        discussionsFilter,
        containerLeft,
        containerTop,
        onAvatarClick,
      ],
    );

    // TODO: compose these during fix-it week
    return isOnPublicRoute ? (
      <PublicAnnotationsAvatars renderAnnotations={renderAnnotations} />
    ) : (
      <PrivateAnnotationsAvatars renderAnnotations={renderAnnotations} />
    );
  },
);

AnnotationsAvatarsContainer.displayName = 'AnnotationsAvatarsContainer';
