import { Button, ButtonProps } from '@air/primitive-button';
import { memo } from 'react';

import { ASSET_SHARE_BUTTON_PUBLIC } from '~/constants/testIDs';

type HeaderShareButtonProps = ButtonProps;

export const HeaderShareButton = memo((props: HeaderShareButtonProps) => {
  return (
    <Button appearance="filled" color="grey" data-testId={ASSET_SHARE_BUTTON_PUBLIC} size="large" {...props}>
      Share
    </Button>
  );
});

HeaderShareButton.displayName = 'HeaderShareButton';
