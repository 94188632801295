import { memo } from 'react';

interface VersionListContentHeaderProps {
  hasOtherVersions: boolean;
}

export const VersionListContentHeader = memo(({ hasOtherVersions }: VersionListContentHeaderProps) => {
  return (
    <div className="mb-2 flex w-full items-center justify-start">
      <div className="mr-8 flex h-5 w-[120px] items-center justify-start pl-1">
        <div className="text-10 font-bold text-grey-9">COVER</div>
      </div>
      {hasOtherVersions && (
        <div className="flex h-5 items-center justify-start pl-1">
          <div className="text-10 font-bold text-grey-9">OTHER VERSIONS</div>
        </div>
      )}
    </div>
  );
});

VersionListContentHeader.displayName = 'VersionListContentHeader';
