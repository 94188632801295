import { noop } from 'lodash';
import { createContext, ReactNode, useContext, useMemo, useRef } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type TimestampObserver = (timeInSeconds: number | null) => void;
export type RegisterTimestampObserverReturn = { unsubscribe: () => void };

export type VideoTimestampContextType = {
  subscribe: (observer: TimestampObserver) => RegisterTimestampObserverReturn;
  updateTime: (timeInSeconds: number | null) => void;
  getCurrentTime: () => number | null;
};

const defaultValue: VideoTimestampContextType = {
  subscribe: () => ({ unsubscribe: noop }),
  updateTime: noop,
  getCurrentTime: () => null,
};

const VideoTimestampObserverContext = createContext(defaultValue);

interface VideoTimestampObserverProviderProps {
  children: ReactNode;
}

export const VideoTimestampObserverProvider = ({ children }: VideoTimestampObserverProviderProps) => {
  const currentTime = useRef<number | null>(null);
  const observers = useRef<TimestampObserver[]>([]);

  const value = useMemo<VideoTimestampContextType>(
    () => ({
      updateTime: (timeInSeconds) => {
        currentTime.current = timeInSeconds;

        observers.current.forEach((observer) => observer(currentTime.current));
      },
      subscribe: (observer: TimestampObserver) => {
        observers.current.push(observer);

        return {
          unsubscribe: () => {
            observers.current = observers.current.filter((o) => o !== observer);
          },
        };
      },
      getCurrentTime: () => currentTime.current,
    }),
    [],
  );

  return <VideoTimestampObserverContext.Provider value={value}>{children}</VideoTimestampObserverContext.Provider>;
};

export const useVideoTimestampObserverContext = () => {
  const context = useContext(VideoTimestampObserverContext);

  if (context === defaultValue) {
    const error = 'useVideoTimestampObserverContext must be used within a VideoTimestampObserverProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
