import { AnnotationOutline } from '@air/next-icons';
import { formatDuration } from '@air/utilities';
import { isUndefined } from 'lodash';
import { memo, useMemo } from 'react';

import { getPageNumber, isAnnotationCorrect } from '~/components/Annotations/shared/annotationUtils';
import { ClipDiscussion } from '~/components/Annotations/shared/types';
import {
  DiscussionMetadata,
  DiscussionMetadataProps,
} from '~/components/Discussions/Discussion/DiscussionComment/components/DiscussionMetadata';
import { ANNOTATION_PAGE_NUMBER, DISCUSSION_HEADER_TIMESTAMP_PILL } from '~/constants/testIDs';

export type DiscussionAnnotationInfoProps = Pick<DiscussionMetadataProps, 'color'> & {
  discussion: ClipDiscussion;
};

export const DiscussionAnnotationInfo = memo(({ discussion: { annotation }, color }: DiscussionAnnotationInfoProps) => {
  const timestamp = annotation?.timestamp;
  const pageNumber = getPageNumber(annotation);

  const textInfo = useMemo(() => {
    if (!isUndefined(timestamp)) {
      return {
        info: formatDuration(Math.round(timestamp)),
        textTestId: DISCUSSION_HEADER_TIMESTAMP_PILL,
      };
    } else if (!isUndefined(pageNumber) && pageNumber !== -1) {
      return {
        info: `pg.${pageNumber}`,
        textTestId: ANNOTATION_PAGE_NUMBER,
      };
    }
  }, [pageNumber, timestamp]);

  if (!isAnnotationCorrect(annotation)) {
    return null;
  }

  return (
    <DiscussionMetadata
      Icon={AnnotationOutline}
      text={textInfo?.info}
      color={color}
      textTestId={textInfo?.textTestId}
    />
  );
});

DiscussionAnnotationInfo.displayName = 'DiscussionAnnotationInfo';
