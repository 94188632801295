import { Tooltip } from '@air/primitive-tooltip';
import classNames from 'classnames';
import { ComponentProps, memo, ReactNode } from 'react';

type PanelTabButtonProps = Required<Pick<ComponentProps<'button'>, 'onClick'>> & {
  pillCount?: number;
  isSelected: boolean;
  icon: ReactNode;
  label: string;
  testId?: string;
};

export const PanelTabButton = memo(({ icon, isSelected, label, pillCount, onClick, testId }: PanelTabButtonProps) => {
  return (
    <Tooltip label={label} side="left">
      <button
        className={classNames(
          'relative mb-6 flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-lg text-grey-12 transition-colors',
          isSelected ? 'bg-grey-6 hover:bg-grey-7' : 'bg-grey-3 hover:bg-grey-4',
        )}
        data-testid={testId}
        onClick={onClick}
      >
        {icon}
        {!!pillCount && (
          <div className="absolute -right-0.5 -top-0.5 z-[2] flex items-center justify-center rounded-lg bg-red-9 px-1 py-0.5 text-8 font-bold uppercase tracking-wider text-white">
            {pillCount}
          </div>
        )}
      </button>
    </Tooltip>
  );
});

PanelTabButton.displayName = 'PanelTabButton';
