import React, { memo, ReactNode } from 'react';

import { INFO_PANEL_DESCRIPTION } from '~/constants/testIDs';

interface AssetTitleAndDescriptionContainerProps {
  title: ReactNode;
  description: ReactNode;
}

export const AssetTitleAndDescriptionContainer = memo(
  ({ title, description }: AssetTitleAndDescriptionContainerProps) => {
    return (
      <div className="w-full bg-grey-2 px-6 py-4">
        {title}
        <div data-testid={INFO_PANEL_DESCRIPTION} className="mt-1 flex w-full shrink-0">
          {description}
        </div>
      </div>
    );
  },
);

AssetTitleAndDescriptionContainer.displayName = 'AssetTitleAndDescriptionContainer';
