import { Clip, Discussion } from '@air/api/types';
import { Skeleton } from '@air/component-skeleton';
import { TriangleDown, TriangleRight } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { isUndefined } from 'lodash';
import pluralize from 'pluralize';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { hasAnnotation } from '~/components/Annotations/shared/annotationUtils';
import { ClipDiscussion as ClipDiscussionType } from '~/components/Annotations/shared/types';
import {
  useDiscussionPanelContext,
  useDiscussionPanelContextSelector,
} from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { DiscussionsPanelContextValue } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { isDiscussionRepliesOpenSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextUtils';
import { CommentReply } from '~/components/Discussions/Discussion/components/CommentReply';
import { ReplyButton } from '~/components/Discussions/Discussion/components/ReplyButton';
import { TopCommentContainer } from '~/components/Discussions/Discussion/components/TopCommentContainer';
import { CommentTimestampInfo } from '~/components/Discussions/Discussion/DiscussionComment/components/CommentTimestampInfo';
import { DiscussionAnnotationInfo } from '~/components/Discussions/Discussion/DiscussionComment/components/DiscussionAnnotationInfo';
import {
  DiscussionComment,
  DiscussionCommentProps,
} from '~/components/Discussions/Discussion/DiscussionComment/DiscussionComment';
import {
  COMMENT_AVATAR_RIGHT_MARGIN,
  COMMENT_AVATAR_SIZE,
  COMMENT_PADDING,
} from '~/components/Discussions/Discussion/shared/constants';
import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { DISCUSSION } from '~/constants/testIDs';
import { useAccountContext } from '~/providers/AccountProvider';
import { centralizedClipVersionNumberSelector } from '~/store/centralizedClip/selectors';
import { isViewingDiscussionWithId } from '~/store/router/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface ClipDiscussionProps {
  discussion: ClipDiscussionType;
  canReply?: boolean;
  /**
   * @see https://github.com/signavio/react-mentions
   */
  mentionsList: MentionsData[];

  onToggleResolve: DiscussionCommentProps['onToggleResolve'];
  onDelete: DiscussionCommentProps['onDelete'];
  onEdit: DiscussionCommentProps['onSave'];
  onAnnotatedCommentClick: (props: {
    clipId: Clip['id'];
    discussionId: Discussion['id'];
    timestamp?: number;
    hasAnnotation: boolean;
  }) => void;

  onSubmit?: (comment: string, discussion: ClipDiscussionType, clipId: Clip['id']) => void;

  onSwitchVersion?: DiscussionCommentProps['onSwitchVersion'];
  shouldShowMentionsButton?: boolean;
  clipId: Clip['id'];
}

export const ClipDiscussion = memo(
  ({
    discussion,
    mentionsList,
    canReply,
    onEdit,
    onDelete,
    onToggleResolve,
    onSubmit,
    onSwitchVersion,
    shouldShowMentionsButton,
    clipId,
    onAnnotatedCommentClick,
  }: ClipDiscussionProps) => {
    const { data } = useAccountContext();
    const currentAssetVersion = useSelector(centralizedClipVersionNumberSelector);
    const isDiscussionActive = useAirSelector((st) => isViewingDiscussionWithId(st, discussion.id));
    const { clearReplyingDiscussionId, setRepliesOpen } = useDiscussionPanelContext();

    const isReplyingCurrentDiscussionIdSelector = useCallback(
      (state: DiscussionsPanelContextValue) => state.replyingDiscussionId === discussion.id,
      [discussion.id],
    );

    const repliesVisibleSelector = useCallback(
      (state: DiscussionsPanelContextValue) => {
        return isDiscussionRepliesOpenSelector(state, discussion.id);
      },
      [discussion.id],
    );

    const isAddingReply = useDiscussionPanelContextSelector(isReplyingCurrentDiscussionIdSelector);
    const areRepliesVisible = useDiscussionPanelContextSelector(repliesVisibleSelector);

    useEffect(() => {
      if (isAddingReply) {
        setRepliesOpen(discussion.id, true);
      }
    }, [discussion.id, isAddingReply, setRepliesOpen]);

    const commentProps = useMemo(
      (): Omit<DiscussionCommentProps, 'comment'> => ({
        currentVersion: currentAssetVersion,
        discussion,
        mentionsList,
        onDelete,
        onSave: onEdit,
        shouldShowMentionsButton,
        onToggleResolve,
        onSwitchVersion: onSwitchVersion ? () => onSwitchVersion(discussion.clipId) : undefined,
        showAvatar: true,
      }),
      [
        currentAssetVersion,
        discussion,
        mentionsList,
        onDelete,
        onEdit,
        onSwitchVersion,
        onToggleResolve,
        shouldShowMentionsButton,
      ],
    );

    const canReplyToComment = canReply && !discussion.resolved;

    const onReplySubmit = useCallback(
      (newComment: string) => {
        onSubmit?.(newComment, discussion, clipId);
        setRepliesOpen(discussion.id, true);
      },
      [clipId, discussion, onSubmit, setRepliesOpen],
    );

    const comments = discussion.comments.data;

    const toggleReplies = useCallback(() => {
      setRepliesOpen(discussion.id, !areRepliesVisible);
    }, [areRepliesVisible, discussion.id, setRepliesOpen]);

    const handleCommentClick = useCallback(() => {
      onAnnotatedCommentClick?.({
        clipId: discussion.clipId,
        discussionId: discussion.id,
        timestamp: discussion.timestamp,
        hasAnnotation: hasAnnotation(discussion),
      });
    }, [discussion, onAnnotatedCommentClick]);

    useUnmount(() => {
      if (isAddingReply) {
        clearReplyingDiscussionId();
      }
    });

    const commentMetadataInfo = useMemo(() => {
      const timestamp = discussion.timestamp || discussion.annotation?.timestamp;
      const color = isDiscussionActive ? 'blue' : 'grey';
      if (hasAnnotation(discussion)) {
        return <DiscussionAnnotationInfo discussion={discussion} color={color} />;
      } else if (!isUndefined(timestamp)) {
        return <CommentTimestampInfo timestamp={timestamp} color={color} />;
      }
      return null;
    }, [discussion, isDiscussionActive]);

    return (
      <div data-discussionid={discussion.id} data-testid={DISCUSSION}>
        <TopCommentContainer discussion={discussion} onClick={handleCommentClick}>
          <DiscussionComment
            className={areRepliesVisible ? 'bg-grey-2' : ''}
            commentMetadata={commentMetadataInfo}
            comment={comments[0]}
            secondaryActions={
              <div className="-ml-2">
                {comments.length === 1 ? (
                  canReplyToComment && !isAddingReply && <ReplyButton discussionId={discussion.id} />
                ) : (
                  <Button
                    appearance="ghost"
                    color="grey"
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleReplies();
                    }}
                    size="small"
                    prefix={
                      areRepliesVisible ? <TriangleDown className="h-4 w-4" /> : <TriangleRight className="h-4 w-4" />
                    }
                  >
                    {pluralize('Reply', comments.length - 1, true)}{' '}
                  </Button>
                )}
              </div>
            }
            {...commentProps}
          />
        </TopCommentContainer>

        {(areRepliesVisible || isAddingReply) && (
          <div className="border-t border-t-grey-5 bg-grey-2">
            {areRepliesVisible && (
              <div>
                {comments.slice(1).map((comment, index) => (
                  <DiscussionComment
                    className="border-t border-t-grey-5 first:border-t-0"
                    key={comment.id}
                    comment={comment}
                    secondaryActions={
                      canReplyToComment &&
                      !isAddingReply &&
                      index === comments.length - 2 && <ReplyButton discussionId={discussion.id} />
                    }
                    style={{ paddingLeft: COMMENT_AVATAR_SIZE + COMMENT_AVATAR_RIGHT_MARGIN + COMMENT_PADDING }}
                    {...commentProps}
                  />
                ))}
              </div>
            )}
            {isAddingReply && (
              <CommentReply
                user={data}
                discussionId={discussion.id}
                shouldShowMentionsButton={shouldShowMentionsButton}
                mentionsList={mentionsList}
                onSubmit={onReplySubmit}
              />
            )}
          </div>
        )}
      </div>
    );
  },
);

ClipDiscussion.displayName = 'ClipDiscussion';

export const DiscussionSkeleton = () => {
  return (
    <div className="pt-4">
      <div className="mb-3 mr-7 flex items-center lg:mr-11">
        <Skeleton height={24} style={{ marginRight: 12 }} variant="round" width={24} />
        <Skeleton height={10} width={60} />
      </div>
      <div className="mr-7 pb-5 pt-2 lg:mr-11">
        <Skeleton height={10} style={{ marginBottom: 8, marginLeft: 36 }} width="calc(90% - 36px)" />
        <Skeleton height={10} style={{ marginBottom: 8, marginLeft: 36 }} width="calc(100% - 36px)" />
        <Skeleton height={10} style={{ marginLeft: 36 }} width="calc(70% - 36px)" />
      </div>
    </div>
  );
};
