import { useRouter } from 'next/router';

import { getClipIdFromPath } from '~/utils/PathUtils';

export const useURLClipIdSelector = () => {
  const { asPath } = useRouter();
  const clipId = getClipIdFromPath(asPath);

  return clipId;
};
