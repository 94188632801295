import { Portal } from '@air/primitive-portal';
import { memo, RefObject } from 'react';

interface AnnotationTooltipProps {
  innerRef: RefObject<HTMLDivElement>;
  shouldDisplay?: boolean;
  text: string;
}
export const AnnotationTooltip = memo(({ innerRef, shouldDisplay, text }: AnnotationTooltipProps) => {
  return (
    <Portal>
      {shouldDisplay && (
        <div className="fixed z-1 rounded bg-black/80 px-2 py-1 transition-opacity" ref={innerRef}>
          <div className="text-12 font-semibold text-white">{text}</div>
        </div>
      )}
    </Portal>
  );
});

AnnotationTooltip.displayName = 'AnnotationTooltip';
