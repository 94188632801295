import { DrawRect, PercentagePoint, PercentageRect, Point } from '~/components/BoundingBox/types';
import { AirCanvasRect } from '~/components/BoundingBox/utils/drawRoundedRect';

export const isPointInRect = (point: Point, rect: AirCanvasRect): boolean =>
  point.x >= rect.x && point.x <= rect.x + rect.w && point.y >= rect.y && point.y <= rect.y + rect.h;

export const isPointInDrawRect = (point: Point, rect: DrawRect): boolean => {
  const minX = Math.min(rect.startX, rect.endX);
  const minY = Math.min(rect.startY, rect.endY);
  const maxX = Math.max(rect.startX, rect.endX);
  const maxY = Math.max(rect.startY, rect.endY);

  return point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;
};

export const isPercentagePointInPercentageRect = (point: PercentagePoint, rect: PercentageRect): boolean => {
  const minX = Math.min(rect.pctStartX, rect.pctEndX);
  const minY = Math.min(rect.pctStartY, rect.pctEndY);
  const maxX = Math.max(rect.pctStartX, rect.pctEndX);
  const maxY = Math.max(rect.pctStartY, rect.pctEndY);

  return point.pctX >= minX && point.pctX <= maxX && point.pctY >= minY && point.pctY <= maxY;
};
