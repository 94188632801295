import { MemoizedContextAction } from '@air/react-memoized-context';
import produce from 'immer';

import {
  AnnotationContextValue,
  AnnotationProviderActions,
} from '~/providers/AnnotationProvider/annotationProviderTypes';

export const annotationsDispatch = (state: AnnotationContextValue, action: MemoizedContextAction) => {
  switch (action.type) {
    case AnnotationProviderActions.setNewAnnotation: {
      return produce(state, (draft) => {
        draft.newAnnotation = action.data;
      });
    }
    case AnnotationProviderActions.setActiveAnnotation: {
      return produce(state, (draft) => {
        draft.activeAnnotation = action.data;
        if (action.data) {
          draft.annotationType = action.data.type;
        }
      });
    }
    case AnnotationProviderActions.setAnnotationType: {
      return produce(state, (draft) => {
        if (action.data !== draft.annotationType) {
          draft.annotationType = action.data;
          draft.newAnnotation = undefined;
          draft.activeAnnotation = undefined;
          draft.revertedLines = [];
        }
      });
    }
    case AnnotationProviderActions.setAnnotationColor: {
      return produce(state, (draft) => {
        draft.annotationColor = action.data;

        if (draft.newAnnotation && draft.newAnnotation.type === 'pencil') {
          draft.newAnnotation = produce(draft.newAnnotation, (annotationDraft) => {
            annotationDraft.lines = annotationDraft.lines.map((line) => ({
              ...line,
              color: draft.annotationColor,
            }));
          });
        }
      });
    }
    case AnnotationProviderActions.setAnnotationSize: {
      return produce(state, (draft) => {
        draft.annotationSize = action.data;

        if (draft.newAnnotation && draft.newAnnotation.type === 'pencil') {
          draft.newAnnotation = produce(draft.newAnnotation, (annotationDraft) => {
            annotationDraft.lines = annotationDraft.lines.map((line) => ({
              ...line,
              lineWidth: draft.annotationSize,
            }));
          });
        }
      });
    }
    case AnnotationProviderActions.setAnnotationsEnabled: {
      return produce(state, (draft) => {
        draft.isAnnotationsEnabled = action.data;
        draft.newAnnotation = undefined;
        draft.revertedLines = [];
      });
    }
    case AnnotationProviderActions.clearRevertedLines: {
      return produce(state, (draft) => {
        draft.revertedLines = [];
      });
    }
    case AnnotationProviderActions.addRevertedLine: {
      return produce(state, (draft) => {
        draft.revertedLines.push(action.data);
      });
    }
    case AnnotationProviderActions.redoLine: {
      return produce(state, (draft) => {
        draft.revertedLines.pop();
      });
    }
    case AnnotationProviderActions.clearNewAnnotation: {
      return produce(state, (draft) => {
        draft.revertedLines = [];
        draft.newAnnotation = undefined;
      });
    }
  }

  return state;
};

export const activeAnnotationSelector = (state: AnnotationContextValue) => state.activeAnnotation;
export const newAnnotationInfoSelector = (state: AnnotationContextValue) => state.newAnnotation;
export const annotationTypeSelector = (state: AnnotationContextValue) => state.annotationType;
export const annotationColorSelector = (state: AnnotationContextValue) => state.annotationColor;
export const annotationSizeSelector = (state: AnnotationContextValue) => state.annotationSize;
export const isAnnotationsEnabledSelector = (state: AnnotationContextValue) => state.isAnnotationsEnabled;
export const canUndoAnnotationSelector = (state: AnnotationContextValue) =>
  state.newAnnotation?.type === 'pencil' && state.newAnnotation?.lines.length > 0;
export const canRedoAnnotationSelector = (state: AnnotationContextValue) =>
  state.newAnnotation?.type === 'pencil' && state.revertedLines.length > 0;
export const hasNewAnnotationSelector = (state: AnnotationContextValue) => !!state.newAnnotation;
