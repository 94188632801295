import { DrawRect, Point } from '~/components/BoundingBox/types';
import { isPointInRect } from '~/components/BoundingBox/utils/isPointInRect';

export const drawSquareAroundPoint = ({
  ctx,
  point: { x, y },
  size,
  strokeColor,
}: {
  ctx: CanvasRenderingContext2D | undefined;
  point: Point;
  strokeColor: string;
  size: number;
}) => {
  if (!ctx) {
    return;
  }

  const startX = Math.round(x - size / 2);
  const startY = Math.round(y - size / 2);

  ctx.strokeStyle = strokeColor;
  ctx.lineWidth = 1;
  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(startX, startY, 8, 8);
  ctx.strokeRect(startX, startY, 8, 8);
};

const MOVABLE_CORNER_SIZE = 8;

export const drawMovableCornerForRect = ({
  ctx,
  rect,
  strokeColor,
}: {
  ctx: CanvasRenderingContext2D;
  rect: DrawRect;
  strokeColor: string;
}) => {
  drawSquareAroundPoint({ point: { x: rect.startX, y: rect.startY }, ctx, size: MOVABLE_CORNER_SIZE, strokeColor });
  drawSquareAroundPoint({ point: { x: rect.endX, y: rect.startY }, ctx, size: MOVABLE_CORNER_SIZE, strokeColor });
  drawSquareAroundPoint({ point: { x: rect.startX, y: rect.endY }, ctx, size: MOVABLE_CORNER_SIZE, strokeColor });
  drawSquareAroundPoint({ point: { x: rect.endX, y: rect.endY }, ctx, size: MOVABLE_CORNER_SIZE, strokeColor });
};

export type MovableCorner = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export const isMouseWithinMovableCorner = ({
  point,
  rect,
}: {
  rect: DrawRect;
  point: Point;
}): MovableCorner | undefined => {
  const halfSize = MOVABLE_CORNER_SIZE / 2;
  const minX = Math.round(Math.min(rect.startX, rect.endX) - halfSize) + 1;
  const maxX = Math.round(Math.max(rect.startX, rect.endX) - halfSize) + 1;
  const minY = Math.round(Math.min(rect.startY, rect.endY) - halfSize) + 1;
  const maxY = Math.round(Math.max(rect.startY, rect.endY) - halfSize) + 1;

  const isWithinTopLeft = isPointInRect(point, {
    x: minX,
    y: minY,
    w: MOVABLE_CORNER_SIZE,
    h: MOVABLE_CORNER_SIZE,
  });
  if (isWithinTopLeft) {
    return 'top-left';
  }

  const isWithinTopRight = isPointInRect(point, {
    x: maxX,
    y: minY,
    w: MOVABLE_CORNER_SIZE,
    h: MOVABLE_CORNER_SIZE,
  });
  if (isWithinTopRight) {
    return 'top-right';
  }

  const isWithinBottomLeft = isPointInRect(point, {
    x: minX,
    y: maxY,
    w: MOVABLE_CORNER_SIZE,
    h: MOVABLE_CORNER_SIZE,
  });
  if (isWithinBottomLeft) {
    return 'bottom-left';
  }

  const isWithinBottomRight = isPointInRect(point, {
    x: maxX,
    y: maxY,
    w: MOVABLE_CORNER_SIZE,
    h: MOVABLE_CORNER_SIZE,
  });
  if (isWithinBottomRight) {
    return 'bottom-right';
  }
};
