import { User, WorkspaceMemberType, WorkspaceUserRolesDefinition } from '@air/api/types';
import { useRect } from '@reach/rect';
import { noop } from 'lodash';
import {
  type ComponentProps,
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Mention } from 'react-mentions';

import { MENTION_SUGGESTIONS_CONTAINER } from '~/constants/testIDs';
import { isiOS, isSafariBrowser } from '~/utils/BrowserUtils';

import { CommentActions } from './CommentActions';
import { getRealStringPositionWithinMentions } from './getRealStringPositionWithinMentions';
import { inputStyles, MENTION_SUGGESTION_ID, MentionsContainer, Suggestion } from './ui';

interface FormFields {
  comment?: string;
  placeholder?: string;
}

export interface MentionsData extends Pick<User, 'avatar' | 'firstName' | 'lastName'> {
  id: string | null;
  /** This is needed for MentionsInput */
  display: string;
  roles: WorkspaceUserRolesDefinition;
  type: WorkspaceMemberType;
}
export interface DiscussionFormProps extends FormFields, Pick<ComponentProps<'form'>, 'style'> {
  /**
   * An array of users that will populate the mentions menu
   * when a user types the trigger `@`.
   * @see https://github.com/signavio/react-mentions
   */
  mentionsList: MentionsData[];

  /**
   * onCancel event that fires when the user clicks on the cancel button.
   */
  onCancel?: () => void;

  /**
   * onSubmit event that fires when the user clicks on the submit button.
   */
  onSubmit: (comment: string) => void;

  /**
   * Determines whether or not the cancel button is visible next to the submit button,
   */
  hasCancelButton?: boolean;
  shouldShowMentionsButton?: boolean;
  hasInternalAvatar?: boolean;
  leftActions?: ReactNode;
  onOutsideClick?: () => void;
  'data-testid'?: string;
}

export interface DiscussionFormHandle {
  focus: () => void;
}

export const DISCUSSION_FORM_ID = 'discussion-form';

export const DiscussionForm = memo(
  forwardRef<DiscussionFormHandle, DiscussionFormProps>(
    (
      {
        comment,
        onCancel = noop,
        onSubmit,
        placeholder,
        hasCancelButton,
        mentionsList,
        shouldShowMentionsButton,
        hasInternalAvatar,
        leftActions,
        onOutsideClick: onClickOutside,
        'data-testid': testId,
        style,
      }: DiscussionFormProps,
      ref,
    ) => {
      const [commentValue, setCommentValue] = useState(comment ?? '');

      const textAreaRef = useRef<HTMLTextAreaElement>(null);
      const hostRef = useRef(document.createElement('div'));
      const rect = useRect(textAreaRef);
      const offset = rect?.top || 0;
      const host = hostRef.current;

      useImperativeHandle(ref, () => ({
        focus: () => {
          textAreaRef.current?.focus();
          // the following code sets focus caret at the end of the text
          if (textAreaRef?.current && !!comment) {
            textAreaRef.current.value = '';
            textAreaRef.current.value = comment;
          }
        },
      }));

      const addTriggerProgrammatically = useCallback(() => {
        setCommentValue(commentValue + `${commentValue.charAt(commentValue.length - 1) === ' ' ? '' : ' '}@`);
        textAreaRef.current?.focus();
      }, [commentValue]);

      const onEmojiPick = useCallback(
        (emoji: string) => {
          const position = textAreaRef.current?.selectionStart;
          const realStringPosition = getRealStringPositionWithinMentions(commentValue, position);
          const nextVal = commentValue.slice(0, realStringPosition) + emoji + commentValue.slice(realStringPosition);
          setCommentValue(nextVal);
          textAreaRef.current?.focus();
        },
        [commentValue],
      );

      const handleSubmit = (e?: any) => {
        e?.preventDefault();
        if (!commentValue.trim()) return;

        onSubmit(commentValue);
        setCommentValue('');
      };

      useLayoutEffect(() => {
        document.body.appendChild(host);
        return () => {
          document.body.removeChild(host);
        };
      }, [host]);

      useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
          const clickedInside = event.composedPath().some((el) => {
            const elementId = (el as HTMLElement).id;
            const isEmojiPicker =
              (el as HTMLElement).classList?.contains('emoji-mart-emoji') ||
              (el as HTMLElement).classList?.contains('emoji-mart');

            return elementId === DISCUSSION_FORM_ID || elementId === MENTION_SUGGESTION_ID || isEmojiPicker;
          });
          if (!clickedInside) {
            onClickOutside?.();
          }
        };

        if (onClickOutside) {
          window.addEventListener('click', handleOutsideClick);
        }

        return () => {
          window.removeEventListener('click', handleOutsideClick);
        };
      }, [onClickOutside]);

      /**
       * Calculates the height for the mentions menu based on the number of items
       * provided in the `mentionsList` prop. The maximum height for the menu
       * is 300.
       */
      const suggestionsHeight = mentionsList ? (mentionsList.length > 4 ? 300 : mentionsList.length * 52) : 0;
      const inverted = window.innerHeight - offset < suggestionsHeight || offset === 0;
      let containerStyles: any = inputStyles;
      containerStyles = {
        ...containerStyles,

        suggestions: {
          ...containerStyles.suggestions,
          zIndex: 4,
          width: '280px',
          bottom: inverted ? `${window.innerHeight - offset}px` : 'auto',
          top: inverted ? 'auto' : `${offset + 20}px`,
          transform: inverted ? 'translateY(0px)' : 'translateY(20px)',
          item: {
            '&focused': {
              backgroundColor: 'var(--colors-grey4)',
            },
          },
        },
      };

      return (
        <form
          className="flex w-full flex-col justify-between rounded border border-grey-5 bg-grey-2 pb-1 text-grey-11 focus-within:ring-2 focus-within:ring-blue-9"
          onSubmit={handleSubmit}
          data-testid={testId}
          id={DISCUSSION_FORM_ID}
          style={style}
        >
          <MentionsContainer
            data-testid={MENTION_SUGGESTIONS_CONTAINER}
            data-discussion-comment-textarea
            value={commentValue}
            onFocus={() => {
              // this is a hack for safari on iOS - it does not scroll input enough, so address bar covers it
              if (isSafariBrowser && isiOS()) {
                setTimeout(
                  () => document.documentElement.scrollTo({ top: document.documentElement.scrollHeight }),
                  100,
                );
              }
            }}
            onChange={(e: any) => setCommentValue(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();

                return handleSubmit();
              }
            }}
            onClick={(event) => event.stopPropagation()}
            placeholder={placeholder}
            inputRef={textAreaRef}
            style={containerStyles}
            suggestionsPortalHost={host}
            allowSpaceInQuery
          >
            <Mention
              className="relative z-1 max-w-[250px] rounded bg-grey-1 py-0.5 text-grey-12"
              trigger="@"
              // @ts-ignore
              data={mentionsList}
              // @ts-ignore
              renderSuggestion={(data) => <Suggestion {...data} />}
              appendSpaceOnAdd
            />
          </MentionsContainer>
          <div className="flex items-center justify-between">
            {leftActions}
            <CommentActions
              onEmojiPick={onEmojiPick}
              isDisabled={!commentValue}
              addTriggerProgrammatically={addTriggerProgrammatically}
              hasCancelButton={hasCancelButton}
              onCancel={onCancel}
              shouldShowMentionsButton={shouldShowMentionsButton}
              hasInternalAvatar={hasInternalAvatar}
            />
          </div>
        </form>
      );
    },
  ),
);

DiscussionForm.displayName = 'DiscussionForm';
