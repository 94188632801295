import { Check } from '@air/next-icons';
import { memo } from 'react';

import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { BOUNDING_BOX_AVATAR_WIDTH, BoundingBoxAvatar } from '~/components/BoundingBox/components/BoundingBoxAvatar';
import { BoundingBoxAvatarDirection, getHorizontalOpposite } from '~/components/BoundingBox/types';
import { ANNOTATION_AVATAR_BUTTON } from '~/constants/testIDs';

export interface AnnotationAvatarButtonProps {
  discussion: ClipDiscussion;
  onAvatarClick: (discussion: ClipDiscussion) => void;
  position: BoundingBoxAvatarDirection;
}

export const AnnotationAvatarButton = memo(({ discussion, position, onAvatarClick }: AnnotationAvatarButtonProps) => {
  return (
    <button
      data-testid={ANNOTATION_AVATAR_BUTTON}
      onClick={(event) => {
        event.stopPropagation();
        onAvatarClick(discussion);
      }}
    >
      <BoundingBoxAvatar
        account={discussion.comments.data[0]?.account}
        className={discussion.resolved ? 'light:bg-grey-5 dark:bg-grey-11' : 'light:bg-grey-3 dark:bg-grey-12'}
        direction={getHorizontalOpposite(position)}
      />
      {discussion.resolved && (
        <div
          className="absolute flex items-center justify-center bg-black/60"
          style={{
            borderRadius: BOUNDING_BOX_AVATAR_WIDTH,
            height: BOUNDING_BOX_AVATAR_WIDTH,
            width: BOUNDING_BOX_AVATAR_WIDTH,
          }}
        >
          <Check className="h-4 w-4 text-white" />
        </div>
      )}
    </button>
  );
});

AnnotationAvatarButton.displayName = 'AnnotationAvatarButton';
