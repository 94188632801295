import { isUndefined } from 'lodash';
import React, { memo, useCallback, useEffect, useRef } from 'react';

import { DrawingCanvas, DrawingCanvasHandle } from '~/components/BoundingBox/components/DrawingCanvas';
import { DrawRect, PercentagePoint, PercentageRect } from '~/components/BoundingBox/types';
import { BoundingBoxCanvas } from '~/components/BoundingBox/utils/BoundingBoxCanvas';
import { BoundingBoxDrawer } from '~/components/BoundingBox/utils/BoundingBoxDrawer';
import { BoundingBoxHandler } from '~/components/BoundingBox/utils/BoundingBoxHandler';
import { BoundingBoxMover } from '~/components/BoundingBox/utils/BoundingBoxMover';
import { BoundingBoxResizer } from '~/components/BoundingBox/utils/BoundingBoxResizer';
import { isMouseWithinMovableCorner, MovableCorner } from '~/components/BoundingBox/utils/drawSquareAroundPoint';
import { findHoveredBox } from '~/components/BoundingBox/utils/findHoveredBox';
import { isPointInDrawRect } from '~/components/BoundingBox/utils/isPointInRect';

export type BoundingBoxLayerMouseEventHandler = (params: {
  point: PercentagePoint;
  event: React.MouseEvent;
  isHoveringBox: boolean;
}) => boolean;

export interface BoundingBoxLayerProps<T extends PercentageRect> {
  shouldDrawOverlay: boolean;
  disabled?: boolean;
  onIsSelectingChange?: (isSelecting: boolean) => void;
  containerWidth: number;
  containerHeight: number;
  containerLeft: number;
  containerTop: number;
  onClear?: () => void;
  onBoundingBoxDrawn?: (box: PercentageRect) => void;
  boundingBoxes: T[];
  hasCustomCursor?: boolean;
  editableBoundingBoxIndex?: number;
  drawNewBox?: (params: { rect: PercentageRect; canvas: BoundingBoxCanvas }) => void;
  /** MouseDown event handler. Return true if event was handled and shouldn't be processed in this component */
  onMouseDown?: BoundingBoxLayerMouseEventHandler;
  /** MouseUp event handler. Return true if event was handled and shouldn't be processed in this component */
  onMouseUp?: BoundingBoxLayerMouseEventHandler;
  overlayColor?: string;
  drawBoxes: (params: { boxes: T[]; canvas: BoundingBoxCanvas }) => void;
  onBoxHovered?: (box: T | undefined) => void;
}

function _BoundingBoxLayer<T extends PercentageRect>({
  boundingBoxes,
  disabled,
  onIsSelectingChange,
  containerWidth,
  containerHeight,
  onBoundingBoxDrawn,
  onClear,
  shouldDrawOverlay,
  containerTop,
  containerLeft,
  hasCustomCursor,
  editableBoundingBoxIndex,
  drawNewBox,
  overlayColor,
  onBoxHovered,
  onMouseUp: _onMouseUp,
  onMouseDown: _onMouseDown,
  drawBoxes,
}: BoundingBoxLayerProps<T>) {
  const drawingCanvasRef = useRef<DrawingCanvasHandle>(null);

  const boundingBoxCanvas = useRef<BoundingBoxCanvas | null>(
    new BoundingBoxCanvas({
      containerWidth,
      containerHeight,
      getDrawingCanvasHandle: () => drawingCanvasRef.current,
      overlayColor,
    }),
  );

  const boundingBoxHandler = useRef<BoundingBoxHandler | null>(null);

  useEffect(() => {
    if (boundingBoxCanvas.current) {
      boundingBoxCanvas.current.containerWidth = containerWidth;
      boundingBoxCanvas.current.containerHeight = containerHeight;
    }
  }, [containerHeight, containerWidth]);

  const onIsSelectingChangeRef = useRef(onIsSelectingChange);
  onIsSelectingChangeRef.current = onIsSelectingChange;

  const onMouseDownRef = useRef(_onMouseDown);
  onMouseDownRef.current = _onMouseDown;

  const onMouseUpRef = useRef(_onMouseUp);
  onMouseUpRef.current = _onMouseUp;

  const onBoundingBoxDrawnRef = useRef(onBoundingBoxDrawn);
  onBoundingBoxDrawnRef.current = onBoundingBoxDrawn;

  const hoveredCornerRef = useRef<MovableCorner | null>();
  const isHoveringBoxRef = useRef(false);

  const finishBoxDrawOnMouseUp = useCallback((rect: PercentageRect | undefined) => {
    if (rect) {
      onBoundingBoxDrawnRef.current?.(rect);
    }

    boundingBoxHandler.current?.cleanup();
    boundingBoxHandler.current = null;

    hoveredCornerRef.current = null;
    isHoveringBoxRef.current = false;

    onIsSelectingChangeRef.current?.(false);
  }, []);

  useEffect(() => {
    return () => {
      boundingBoxHandler.current?.cleanup();
    };
  }, []);

  const editableBoundingBox =
    editableBoundingBoxIndex !== undefined ? boundingBoxes[editableBoundingBoxIndex] : undefined;

  const onCanvasMouseUp = useCallback((event: React.MouseEvent) => {
    const boxCanvas = boundingBoxCanvas.current;
    if (!boxCanvas) return;

    const mouseCoords = boxCanvas.getMouseCoords(event);
    if (mouseCoords) {
      const percentageMouseCoords = boxCanvas.convertPointToPercentagePoint(mouseCoords);
      const handled = onMouseUpRef.current?.({
        point: percentageMouseCoords,
        event,
        isHoveringBox: isHoveringBoxRef.current || !!hoveredCornerRef.current,
      });
      if (handled) {
        return;
      }
    }
  }, []);

  // This will clear any existing rects / in context,
  // and set up info to allow for drawing in onMouseMove.
  const onCanvasMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (!boundingBoxCanvas.current) return;

      const boxCanvas = boundingBoxCanvas.current;
      if (!boxCanvas) return;

      const mouseCoords = boxCanvas.getMouseCoords(event);
      if (mouseCoords) {
        const percentageMouseCoords = boxCanvas.convertPointToPercentagePoint(mouseCoords);
        const handled = onMouseDownRef.current?.({
          point: percentageMouseCoords,
          event,
          isHoveringBox: isHoveringBoxRef.current || !!hoveredCornerRef.current,
        });
        if (handled) {
          return;
        }
      }

      if (drawNewBox) {
        if (editableBoundingBox) {
          if (isHoveringBoxRef.current) {
            boundingBoxHandler.current = new BoundingBoxMover({
              getBoundingBoxCanvas: () => boundingBoxCanvas.current,
              shouldDrawOverlay: true,
              drawNewBox,
            });
          } else if (hoveredCornerRef.current) {
            boundingBoxHandler.current = new BoundingBoxResizer({
              getBoundingBoxCanvas: () => boundingBoxCanvas.current,
              hoveredCorner: hoveredCornerRef.current,
              shouldDrawOverlay: true,
              drawNewBox,
            });
          } else {
            onClear?.();
          }
        } else {
          onClear?.();

          boundingBoxHandler.current = new BoundingBoxDrawer({
            getBoundingBoxCanvas: () => boundingBoxCanvas.current,
            shouldDrawOverlay: false,
            drawNewBox,
          });
        }
      }

      if (boundingBoxHandler.current) {
        boundingBoxHandler.current.startDrawing({
          event,
          boundingBox: editableBoundingBox,
          onDrawFinished: finishBoxDrawOnMouseUp,
        });

        onIsSelectingChangeRef.current?.(true);
      }
    },
    [drawNewBox, editableBoundingBox, onClear, finishBoxDrawOnMouseUp],
  );

  // this callback checks if user hovers over movable corner to set cursor and initialize resizing/moving
  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      if (!!boundingBoxHandler.current) {
        return;
      }

      const boxCanvas = boundingBoxCanvas.current;
      if (!boxCanvas) return;

      const mouseCoords = boxCanvas.getMouseCoords(event);
      if (!mouseCoords) {
        return;
      }

      if (onBoxHovered) {
        const hoveredBox = findHoveredBox({ boundingBoxes, boxCanvas, mouseCoords });
        onBoxHovered(hoveredBox);
        if (hoveredBox) {
          boxCanvas.setCursor('pointer');
        } else {
          boxCanvas.setDefaultCursor();
        }
      }

      if (editableBoundingBox) {
        const existingRect: DrawRect = boxCanvas.convertPercentageRectToDrawRect(editableBoundingBox);

        const hoveredCorner = isMouseWithinMovableCorner({ point: mouseCoords, rect: existingRect });

        hoveredCornerRef.current = hoveredCorner;
        isHoveringBoxRef.current = false;

        if (hoveredCorner) {
          boxCanvas.setResizeCursor(hoveredCorner);
        } else if (isPointInDrawRect(mouseCoords, existingRect)) {
          isHoveringBoxRef.current = true;
          boxCanvas.setCursor('move');
        } else {
          boxCanvas.setDefaultCursor();
        }
      }
    },
    [boundingBoxes, editableBoundingBox, onBoxHovered],
  );

  const redrawTimer = useRef<number | null>();

  const redrawLayer = useCallback(() => {
    redrawTimer.current = window.requestAnimationFrame(() => {
      boundingBoxCanvas.current?.clearRect();
      if (shouldDrawOverlay) {
        boundingBoxCanvas.current?.drawOverlay();
      }

      if (boundingBoxCanvas.current) {
        drawBoxes({ boxes: boundingBoxes, canvas: boundingBoxCanvas.current });
      }
    });
  }, [boundingBoxes, drawBoxes, shouldDrawOverlay]);

  // This effect will clear current boxes, and draw new ones
  useEffect(() => {
    redrawLayer();
    return () => {
      if (redrawTimer.current) {
        window.cancelAnimationFrame(redrawTimer.current);
      }
    };
  }, [redrawLayer, containerHeight, containerWidth]);

  useEffect(() => {
    if (isUndefined(editableBoundingBoxIndex)) {
      boundingBoxCanvas.current?.setDefaultCursor();
    }
  }, [editableBoundingBoxIndex]);

  if (!containerWidth || !containerHeight) {
    return null;
  }

  const canHandleMouseMove = !disabled && (!!editableBoundingBox || !!onBoxHovered);

  return (
    <DrawingCanvas
      hasCustomCursor={hasCustomCursor}
      onMouseMove={canHandleMouseMove ? onMouseMove : undefined}
      onSizeChanged={redrawLayer}
      containerWidth={containerWidth}
      containerHeight={containerHeight}
      onMouseDown={disabled ? undefined : onCanvasMouseDown}
      ref={drawingCanvasRef}
      containerLeft={containerLeft}
      containerTop={containerTop}
      onMouseUp={onCanvasMouseUp}
    />
  );
}

export const BoundingBoxLayer = memo(_BoundingBoxLayer) as typeof _BoundingBoxLayer;

// @ts-ignore - can't get displayName to work with a generic prop - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
BoundingBoxLayer.displayName = 'BoundingBoxLayer';
