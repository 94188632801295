import { Breadcrumbs, BreadcrumbsItem } from '@air/component-breadcrumbs';
import { useSelector } from 'react-redux';

import { AncestorImageContainer } from '~/components/AncestorImageContainer';
import { centralizedClipBoardInfoSelector } from '~/store/centralizedClip/selectors';

export const AssetLocationBreadcrumbs = () => {
  const { current } = useSelector(centralizedClipBoardInfoSelector);

  if (!current || !current.library || !current.ancestors?.length) return null;

  return (
    <div className="flex items-center text-grey-11">
      in
      <Breadcrumbs
        className="ml-2 truncate text-grey-11"
        isCompact
        items={[
          {
            className: 'text-inherit',
            id: current.library.id,
            prefix: (
              <AncestorImageContainer backgroundColor={current.library.color.backgroundHex}>
                <p className="text-14">{current.library.icon}</p>
              </AncestorImageContainer>
            ),
            label: current.library.title,
            type: 'item',
          },
          ...current.ancestors.map<BreadcrumbsItem>((ancestor) => ({
            className: 'text-inherit',
            id: ancestor.id,
            label: ancestor.title,
            type: 'item',
          })),
          {
            className: 'text-inherit',
            id: current.id,
            label: current.title,
            type: 'item',
          },
        ]}
      />
    </div>
  );
};
