import { QueryParamNames } from '~/constants/search';
import { getCurrentQuery } from '~/utils/Url';

export const assetModalParamsToOmit: QueryParamNames[] = [
  QueryParamNames.timestamp,
  QueryParamNames.tab,
  QueryParamNames.discussionId,
  QueryParamNames.versions,
  QueryParamNames.capturedTextId,
  QueryParamNames.faceId,
  QueryParamNames.page,
];

export const getQueryWithoutAssetModalParams = () => {
  const query = getCurrentQuery();
  assetModalParamsToOmit.forEach((param) => delete query[param]);
  return query;
};
