import { getDrawRectMaxMinsCoords } from '~/components/AssetModal/Visualizer/CapturedTextBoundingBox/components/CapturedTextBoundingBoxForm/utils/getPercentageRectMaxMinsCoords';
import { PercentageRect, Point } from '~/components/BoundingBox/types';
import { BoundingBoxCanvas } from '~/components/BoundingBox/utils/BoundingBoxCanvas';
import { isPointInDrawRect } from '~/components/BoundingBox/utils/isPointInRect';

interface FindHoveredBoxParams<T extends PercentageRect> {
  boundingBoxes: T[];
  boxCanvas: BoundingBoxCanvas;
  mouseCoords: Point;
}

export const findHoveredBox = <T extends PercentageRect>({
  boundingBoxes,
  boxCanvas,
  mouseCoords,
}: FindHoveredBoxParams<T>): T | undefined => {
  // Get hovered box
  // boxes may overlap, we there may be more than one hovered
  const hoveredBoxes = boundingBoxes.filter((box) => {
    const existingRect = boxCanvas.convertPercentageRectToDrawRect(box);
    return isPointInDrawRect(mouseCoords, existingRect);
  });

  if (hoveredBoxes.length === 1) {
    return hoveredBoxes[0];
  } else {
    // iterate over hovered boxes and determine which should be hovered:
    // - if border is hovered - top, left, right, bottom +- 2px is equal to rect coords, it should be hovered
    // - else sellect the smallest
    let smallestArea = Infinity;
    let smallestBox: T | undefined;

    for (const boxToCheck of hoveredBoxes) {
      const drawRect = boxCanvas.convertPercentageRectToDrawRect(boxToCheck);
      const { maxY, maxX, minX, minY } = getDrawRectMaxMinsCoords(drawRect);

      const hoveredVerticalBorder = Math.abs(maxX - mouseCoords.x) < 2 || Math.abs(minX - mouseCoords.x) < 2;
      const hoveredHorizontalBorder = Math.abs(maxY - mouseCoords.y) < 2 || Math.abs(minY - mouseCoords.y) < 2;

      if (hoveredVerticalBorder || hoveredHorizontalBorder) {
        return boxToCheck;
      } else {
        const area = (drawRect.endX - drawRect.startX) * (drawRect.endY - drawRect.startY);
        if (area < smallestArea) {
          smallestArea = area;
          smallestBox = boxToCheck;
        }
      }
    }
    return smallestBox;
  }
};
