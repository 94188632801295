import { Discussions } from '@air/api';
import { Board, Clip, User } from '@air/api/types';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ClipDiscussion, FreeformAnnotation } from '~/components/Annotations/shared/types';
import { NEW_DISCUSSION_ID } from '~/components/Annotations/shared/useActiveDiscussionIdObserver';
import { ASSET_MODAL_DISCUSSION_FILTERS } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { ClipDiscussionsCacheType } from '~/swr-hooks/discussions/types';
import {
  createDiscussionInCache,
  getDiscussionsParams,
  isClipDiscussionsKey,
  mockDiscussion,
} from '~/swr-hooks/discussions/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface CreateDiscussionParams {
  account: Pick<User, 'avatar' | 'firstName' | 'id' | 'lastName'>;
  board?: Partial<Pick<Board, 'id' | 'title'>>;
  clipId: Clip['id'];
  currentVersion: number;
  key: QueryKey;
  newComment: string;
  shortId?: string;
  assetId: Clip['assetId'];
  type: 'clip';
  timestamp?: number;
  annotation?: FreeformAnnotation;
  beforeCacheUpdate?: (discussion: ClipDiscussion) => void;
}

export const useCreateDiscussion = () => {
  const queryClient = useQueryClient();
  const createDiscussion = useCallback(
    async ({
      account,
      board,
      clipId,
      currentVersion,
      key,
      newComment,
      shortId,
      assetId,
      type,
      timestamp,
      annotation,
      beforeCacheUpdate,
    }: CreateDiscussionParams) => {
      try {
        const params = getDiscussionsParams(newComment, type, clipId, board, timestamp, annotation);

        const newDiscussionWithTempId = { ...params.discussion, id: NEW_DISCUSSION_ID };
        const newCommentWithTempId = { ...params.comment, id: uuidv4() };

        const newDiscussion = mockDiscussion({
          assetId,
          account,
          clipId,
          currentVersion,
          board,
          newComment: newCommentWithTempId,
          newDiscussion: newDiscussionWithTempId,
        });

        beforeCacheUpdate?.(newDiscussion);

        queryClient.setQueriesData<ClipDiscussionsCacheType>(
          {
            predicate: ({ queryKey }) => {
              if (isClipDiscussionsKey(queryKey)) {
                const canAddToFilter =
                  !queryKey[1].discussionsFilter ||
                  queryKey[1].discussionsFilter === ASSET_MODAL_DISCUSSION_FILTERS.OPENED ||
                  queryKey[1].discussionsFilter === ASSET_MODAL_DISCUSSION_FILTERS.ALL;

                const isSameClip = queryKey[1].clipId === clipId || !queryKey[1].clipId;

                return isSameClip && queryKey[1].assetId === assetId && canAddToFilter;
              }

              return false;
            },
          },
          (data) => createDiscussionInCache(newDiscussion, data),
        );

        const createdDiscussion = await Discussions.create(params, shortId);

        await queryClient.invalidateQueries({ queryKey: key });

        return createdDiscussion;
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to create discussion',
          metadata: { data: { newComment, board, clipId, assetId, type, timestamp } },
        });
      }
    },
    [queryClient],
  );

  return {
    createDiscussion,
  };
};
