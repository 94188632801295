import { Clock } from '@air/next-icons';
import { formatDuration } from '@air/utilities';
import { memo } from 'react';

import {
  DiscussionMetadata,
  DiscussionMetadataProps,
} from '~/components/Discussions/Discussion/DiscussionComment/components/DiscussionMetadata';

export type CommentTimestampInfoProps = Pick<DiscussionMetadataProps, 'color'> & {
  timestamp: number;
};

export const CommentTimestampInfo = memo(({ timestamp }: CommentTimestampInfoProps) => {
  return <DiscussionMetadata Icon={Clock} text={formatDuration(Math.round(timestamp))} />;
});

CommentTimestampInfo.displayName = 'CommentTimestampInfo';
