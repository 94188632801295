import { useTrackSelectedComment } from '@air/analytics';
import { Discussion } from '@air/api/types';
import { formatInitials } from '@air/utilities';
import { isUndefined, sortBy } from 'lodash';
import { useCallback } from 'react';

import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { ASSET_MODAL_DISCUSSION_FILTERS } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { QueryParamNames } from '~/constants/search';
import { activeDiscussionIdParamSelector } from '~/store/router/selectors';
import { setQueryParams } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { TimestampMarkers } from './shared/types';

export const getTimestampMarkers = ({
  data,
  currentVersion,
  filter,
}: {
  data: ClipDiscussion[];
  currentVersion: number;
  filter: ASSET_MODAL_DISCUSSION_FILTERS;
}) => {
  /** for consistent marker overlap */
  const markersData = sortBy(data, ['timestamp']);

  return markersData.reduce((acc, curr) => {
    if (!isUndefined(curr.timestamp) && curr.assetVersion === currentVersion) {
      const account = curr.comments.data[0].account;
      if (
        filter === ASSET_MODAL_DISCUSSION_FILTERS.ALL ||
        (filter === ASSET_MODAL_DISCUSSION_FILTERS.OPENED && !curr.resolved) ||
        (filter === ASSET_MODAL_DISCUSSION_FILTERS.RESOLVED && curr.resolved)
      ) {
        acc.push({
          timestamp: curr.timestamp,
          discussionId: curr.id,
          userAvatar: account.avatar || '',
          initials: formatInitials(account.firstName, account.lastName),
          userId: account.id,
        });
      }
    }
    return acc;
  }, [] as TimestampMarkers);
};

export interface OnAnnotatedCommentClickProps {
  onSwitchVersion: () => void;
  discussionId: Discussion['id'];
  timestamp?: number;
  hasAnnotation: boolean;
}

export const useOnAnnotatedCommentClick = () => {
  const store = useAirStore();
  const { trackSelectedComment } = useTrackSelectedComment();

  const onAnnotatedCommentClick = useCallback(
    async ({ onSwitchVersion, discussionId, timestamp, hasAnnotation }: OnAnnotatedCommentClickProps) => {
      const activeDiscussionId = activeDiscussionIdParamSelector(store.getState());

      if (activeDiscussionId !== discussionId) {
        await setQueryParams(
          { [QueryParamNames.discussionId]: [discussionId], [QueryParamNames.timestamp]: timestamp },
          'replace',
        );
      } else {
        await setQueryParams(
          { [QueryParamNames.discussionId]: null, [QueryParamNames.timestamp]: timestamp },
          'replace',
        );
      }

      onSwitchVersion();

      trackSelectedComment({
        location: 'discussions tab',
        has_annotation: hasAnnotation,
        has_timestamp: !isUndefined(timestamp),
      });
    },
    [store, trackSelectedComment],
  );

  return { onAnnotatedCommentClick };
};
