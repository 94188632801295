import { BaseMouseEvent, PercentagePoint, PercentageRect } from '~/components/BoundingBox/types';
import { BoundingBoxCanvas, DrawPercentageRectOnCanvas } from '~/components/BoundingBox/utils/BoundingBoxCanvas';

export type GetBoundingBoxCanvas = () => BoundingBoxCanvas | null;

export interface BoundingBoxHandlerConstructor {
  getBoundingBoxCanvas: GetBoundingBoxCanvas;
  shouldDrawOverlay: boolean;
  drawNewBox: DrawPercentageRectOnCanvas;
}

export interface StartDrawingParams {
  event: BaseMouseEvent;
  boundingBox: PercentageRect | undefined;
  onDrawFinished: (rect: PercentageRect | undefined) => void;
}

export type GetNewRectType = (params: {
  point: PercentagePoint;
  currentRect: PercentageRect | undefined;
}) => PercentageRect | undefined;

export type InternalStartDrawingType = (params: {
  point: PercentagePoint;
  currentRect: PercentageRect | undefined;
}) => void;

export abstract class BoundingBoxHandler {
  protected getBoundingBoxCanvas: GetBoundingBoxCanvas;
  protected shouldDrawOverlay: boolean;
  protected currentRect: PercentageRect | undefined = undefined;
  protected readonly drawNewBox: DrawPercentageRectOnCanvas;

  private onDrawFinished: ((rect: PercentageRect | undefined) => void) | null = null;

  constructor({ getBoundingBoxCanvas, shouldDrawOverlay = true, drawNewBox }: BoundingBoxHandlerConstructor) {
    this.getBoundingBoxCanvas = getBoundingBoxCanvas;
    this.shouldDrawOverlay = shouldDrawOverlay;
    this.drawNewBox = drawNewBox;
  }

  abstract _finishDrawing: () => void;
  abstract _getNewRect: GetNewRectType;

  abstract _startDrawing: InternalStartDrawingType;

  startDrawing = ({ boundingBox, onDrawFinished, event }: StartDrawingParams) => {
    this.onDrawFinished = onDrawFinished;
    const boundingBoxCanvas = this.getBoundingBoxCanvas();
    if (!boundingBoxCanvas) return;

    const mouseCoords = boundingBoxCanvas.getMouseCoords(event);
    if (!mouseCoords) return;

    this.currentRect = boundingBox;

    this._startDrawing({
      point: boundingBoxCanvas.convertPointToPercentagePoint(mouseCoords),
      currentRect: this.currentRect,
    });

    window.addEventListener('mousemove', this.drawBoxOnMouseMove);
    window.addEventListener('mouseup', this.finishDrawing, { once: true });
  };

  drawBoxOnMouseMove = (event: MouseEvent) => {
    const boundingBoxCanvas = this.getBoundingBoxCanvas();
    if (!boundingBoxCanvas) return;

    const mouseCoords = boundingBoxCanvas.getMouseCoords(event);
    if (mouseCoords) {
      this.currentRect = this._getNewRect({
        point: boundingBoxCanvas.convertPointToPercentagePoint(mouseCoords),
        currentRect: this.currentRect,
      });

      const rectToDraw = this.currentRect;
      if (!rectToDraw) return;

      boundingBoxCanvas.clearRect();
      if (this.shouldDrawOverlay) {
        boundingBoxCanvas.drawOverlay();
      }
      boundingBoxCanvas.drawBoundingBoxRect({
        rectToDraw: rectToDraw,
        drawRectFunction: () => this.drawNewBox({ rect: rectToDraw, canvas: boundingBoxCanvas }),
      });
    }
  };

  finishDrawing = () => {
    const boundingBoxCanvas = this.getBoundingBoxCanvas();
    if (!boundingBoxCanvas) return;

    this.onDrawFinished?.(this.currentRect);

    window.removeEventListener('mousemove', this.drawBoxOnMouseMove);
    this._finishDrawing();
  };

  cleanup = () => {
    window.removeEventListener('mousemove', this.drawBoxOnMouseMove);
    window.removeEventListener('mouseup', this.finishDrawing);
  };

  protected getCanvasSize = () => this.getBoundingBoxCanvas()?.getCanvasSize();
}
