import { Discussions } from '@air/api';
import { CommentResponse } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { Query, useMutation, useQueryClient } from '@tanstack/react-query';

import { ClipDiscussionsCacheType } from '~/swr-hooks/discussions/types';
import { editCommentInCache, isClipDiscussionsKey } from '~/swr-hooks/discussions/utils';

interface EditCommentParams {
  existingComment: CommentResponse;
  newComment: string;
  boardId?: string;
  shortId?: string;
  assetId: string;
}

export const useEditComment = () => {
  const queryClient = useQueryClient();

  const editComment = useMutation({
    // TODO: fix boardId assertion once backend updates with proper typings.
    // BOARDID *IS* POSSIBLY UNDEFINED
    // see: https://github.com/AirLabsTeam/backend/pull/2783
    mutationFn: ({ existingComment, newComment, boardId, shortId }: EditCommentParams) =>
      Discussions.updateComment({
        input: { ...existingComment, body: newComment },
        boardId: boardId!,
        shortId,
      }),
    onMutate: ({ existingComment, newComment, assetId }) => {
      const queryKeyPredicate = ({ queryKey }: Query) =>
        isClipDiscussionsKey(queryKey) && queryKey[1].assetId === assetId;

      const previousComments = queryClient.getQueriesData<ClipDiscussionsCacheType>({ predicate: queryKeyPredicate });

      queryClient.setQueriesData<ClipDiscussionsCacheType>({ predicate: queryKeyPredicate }, (data) =>
        editCommentInCache({ newComment, existingComment }, data),
      );

      return { previousComments };
    },
    onError: (error, { existingComment, newComment, boardId, shortId }, context) => {
      if (context?.previousComments?.length) {
        context.previousComments.forEach((cacheData) => {
          queryClient.setQueryData(cacheData[0], cacheData[1]);
        });
      }

      reportErrorToBugsnag({
        error,
        context: 'Failed to edit comment',
        metadata: { data: { existingComment, newComment, shortId, boardId } },
      });
    },
    onSuccess: (_, { assetId }) => {
      const queryKeyPredicate = ({ queryKey }: Query) =>
        isClipDiscussionsKey(queryKey) && queryKey[1].assetId === assetId;

      queryClient.invalidateQueries({ predicate: queryKeyPredicate });
    },
  });

  return {
    editComment,
  };
};
