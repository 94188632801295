import { formatDuration } from '@air/utilities';
import classNames from 'classnames';
import Hls from 'hls.js';
import { memo, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useMountedState } from 'react-use';

import { BufferProgressBar } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/SeekBar/components/BufferProgressBar';
import { SeekProgressBar } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/SeekBar/components/SeekProgressBar';
// TODO: These should be zephyr Boxes
import { SeekBarContainer } from '~/components/AssetModal/Visualizer/VideoVisualizer/VideoVisualizerInner/SeekBar/style';
import { BOUNDING_BOX_LAYER_Z_INDEX } from '~/components/BoundingBox/constants';
import { CentralizedClip } from '~/store/centralizedClip/types';
import ClipUtils from '~/utils/ClipUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { loadHls } from '~/utils/HlsUtils';
import { getPosition } from '~/utils/PlayerUtils';

import { TimestampMarkers } from '../../shared/types';
import { ACTIVE_TIMESTAMP_MARKER_Z_INDEX } from '../shared/constants';
import { TimestampMarker } from './components/TimestampMarker';
import { MARKER_CONTAINER_WIDTH } from './shared/constants';

interface SeekBarProps {
  activeDiscussionId?: string | null;
  activeTimestamp?: number;
  currentVideoWidth: number;
  clip: CentralizedClip;
  duration?: number;
  isSeeking: boolean;
  isViewingAnnotatedComment?: boolean;
  markers?: TimestampMarkers;
  onMarkerClick: (markerTimestamp: number) => void;
  onSeekStart: () => void;
  onSeekEnd: (value: number) => void;
  onSeekChange: (value: number) => void;
  processedPercentage: number;
  progressPercentage: number;
}

interface State {
  previewAspectRatio: number | null;
  previewPos: number;
  seekPosition: number;
  previewTime: string | null;
}

type HLSLoadingState = 'loading' | 'loaded' | 'idle';

export const SeekBar = memo(
  ({
    activeDiscussionId,
    activeTimestamp,
    clip,
    currentVideoWidth,
    duration,
    isSeeking,
    isViewingAnnotatedComment,
    markers,
    onMarkerClick,
    onSeekStart,
    onSeekEnd,
    onSeekChange,
    processedPercentage,
    progressPercentage,
  }: SeekBarProps) => {
    const videoSrc = useMemo(() => {
      // TODO: Backend needs to be sending us the v4. This should future-proof it for now.
      if (!clip.assets.seekVideo) return undefined;
      if (clip.assets.seekVideo.includes('preview_v4')) return clip.assets.seekVideo;
      return clip.assets.seekVideo.replace(/preview?\w*/, 'preview_v4');
    }, [clip.assets.seekVideo]);
    const previewHls = useRef<Hls | null>(null);
    const seekPlayerLoaded = useRef<HLSLoadingState>('idle');
    const seekBar = useRef<HTMLInputElement | null>(null);
    const seekVideo = useRef<HTMLVideoElement | null>(null);
    const [showSeekPreview, setShowSeekPreview] = useState(false);
    const [previewAspectRatio, setPreviewAspectRatio] = useState<State['previewAspectRatio']>(null);
    const [previewPos, setPreviewPos] = useState<State['previewPos']>(0);
    const [seekPosition, setSeekPosition] = useState<State['seekPosition']>(0);
    const [previewTime, setPreviewTime] = useState<State['previewTime']>(null);
    const [currentVideoSrc, setCurrentVideoSrc] = useState(videoSrc);

    const isMounted = useMountedState();

    const hidePreview = !previewAspectRatio || !showSeekPreview || seekPosition < 0 || seekPosition > 1;

    const onSeekPlayerLoaded = () => {
      const element = seekVideo.current;

      if (!element) return;

      if (element.videoHeight && element.videoWidth) {
        setPreviewAspectRatio(element.videoWidth / element.videoHeight);
      }
    };

    const reloadHls = () => {
      previewHls.current?.destroy();
      setPreviewAspectRatio(null);
    };

    useEffect(() => {
      // when moving through assets, ensure that seek bar is not using old Hls src
      reloadHls();
    }, [clip.id]);

    useEffect(() => {
      previewHls.current?.destroy();
    }, []);

    useEffect(() => {
      const element = seekVideo.current;

      if (element) {
        element.addEventListener('loadeddata', onSeekPlayerLoaded);
        element.addEventListener('seeked', onSeekPlayerLoaded);

        reloadHls();
      }

      return () => {
        if (element) {
          element.removeEventListener('loadeddata', onSeekPlayerLoaded);
          element.removeEventListener('seeked', onSeekPlayerLoaded);
        }
      };
    }, []);

    useEffect(() => {
      onSeekPlayerLoaded();

      seekPlayerLoaded.current = 'idle';

      if (seekVideo.current) {
        reloadHls();
      }
    }, [isSeeking]);

    useEffect(() => {
      if (currentVideoSrc !== videoSrc) {
        seekPlayerLoaded.current = 'idle';
      }
    }, [currentVideoSrc, videoSrc]);

    const previewSeeking = async (event: MouseEvent<HTMLDivElement>) => {
      if (!seekBar.current || !seekVideo.current) return;

      const xPos = event.clientX - getPosition(seekBar.current).x;

      if (
        seekVideo.current &&
        videoSrc &&
        (seekPlayerLoaded.current === 'idle' || currentVideoSrc !== videoSrc) &&
        seekVideo.current
      ) {
        seekVideo.current.src = videoSrc;
        setCurrentVideoSrc(videoSrc);
        if (clip.type === 'video') {
          try {
            previewHls.current?.destroy();
            seekPlayerLoaded.current = 'loading';
            previewHls.current = await loadHls({
              src: videoSrc,
              videoElement: seekVideo.current,
            });
          } catch (error) {
            reportErrorToBugsnag({
              error,
              context: 'Failed to load new Hls source in Seek Bar',
              metadata: {
                key: 'Data',
                data: { clip },
              },
            });
          }
        }

        seekPlayerLoaded.current = 'loaded';
      }

      if (!isMounted()) return;

      let left = xPos / seekBar.current.offsetWidth;
      if (Number.isNaN(left)) {
        left = 0;
      }

      let newTime = left * (duration || clip.duration || 0);
      let seekTime = left * seekVideo.current.duration;
      if (Number.isNaN(newTime) || Number.isNaN(seekTime)) {
        newTime = 0;
        seekTime = 0;
      }
      setPreviewPos(xPos);
      setSeekPosition(left);
      setPreviewTime(formatDuration(newTime));
      seekVideo.current.currentTime = seekTime;
      setShowSeekPreview(true);
    };

    const hideSeekPreview = () => {
      setShowSeekPreview(false);
      setPreviewPos(0);
      setSeekPosition(0);
    };

    // @TODO: change `markers` data type earlier on in `getTimestampMarkers` to avoid double loops through `markers`
    const getSharedTimestampMarkers = (timestamp: number) => {
      const sharedTimestampMarkers = markers?.filter((marker) => marker.timestamp === timestamp);

      const activeMarker = sharedTimestampMarkers?.find((marker) => activeDiscussionId === marker.discussionId);

      const markerToShow = activeMarker || (sharedTimestampMarkers && sharedTimestampMarkers[0]);
      const count = sharedTimestampMarkers?.length;

      return {
        markerToShow,
        count,
        discussionIds: sharedTimestampMarkers?.map(({ discussionId }) => discussionId) ?? [],
      };
    };

    const VIDEO_PREVIEW_POSITION = previewPos - (previewAspectRatio || 0) * 50;

    /** There's no significance to this number below (210), it's just
     * what makes the video preview align properly to not overflow
     */
    const VIDEO_PREVIEW_MAX_LEFT = currentVideoWidth - 210;
    const VIDEO_PREVIEW_LEFT =
      VIDEO_PREVIEW_POSITION >= 0 && VIDEO_PREVIEW_POSITION <= VIDEO_PREVIEW_MAX_LEFT
        ? VIDEO_PREVIEW_POSITION
        : VIDEO_PREVIEW_POSITION >= 0
        ? VIDEO_PREVIEW_MAX_LEFT
        : 0;

    return (
      <SeekBarContainer
        className="relative mx-4 flex h-4 grow cursor-pointer select-none items-center justify-center"
        onMouseEnter={previewSeeking}
        onMouseMove={previewSeeking}
        onMouseLeave={hideSeekPreview}
        style={{
          WebkitTouchCallout: 'none',
        }}
      >
        <input
          className="absolute bottom-0 h-5 w-full appearance-none bg-transparent"
          type="range"
          ref={seekBar}
          min={0}
          max={1}
          step="any"
          value={progressPercentage}
          onChange={() => onSeekChange(seekPosition)}
          onMouseDown={onSeekStart}
          onMouseUp={() => onSeekEnd(seekPosition)}
          onTouchStart={onSeekStart}
          onTouchEnd={() => onSeekEnd(seekPosition)}
        />
        {ClipUtils.isProcessing(clip.status) ? <BufferProgressBar percentage={processedPercentage * 100 || 0} /> : null}
        <SeekProgressBar percentage={progressPercentage * 100 || 0} />
        <div
          className={classNames(
            'pointer-events-none absolute bottom-9 border-2 border-pigeon-50 bg-black',
            hidePreview ? 'invisible' : 'visible',
          )}
          hidden={hidePreview}
          style={{
            left: VIDEO_PREVIEW_LEFT,
            zIndex: BOUNDING_BOX_LAYER_Z_INDEX + 1,
          }}
        >
          <video className="max-h-[100px] object-contain" ref={seekVideo} style={{ width: 172 }} />
          <div className="absolute bottom-0 left-1.5 text-12 text-white">{previewTime}</div>
        </div>
        {markers?.map((marker, index) => {
          const { markerToShow, count } = getSharedTimestampMarkers(marker.timestamp);
          const duration = clip.duration || 1;
          return (
            <TimestampMarker
              key={index}
              userAvatar={markerToShow ? markerToShow?.userAvatar : undefined}
              style={{
                marginLeft: -MARKER_CONTAINER_WIDTH / 2,
                left: `${(marker.timestamp * 100) / duration}%`,
                zIndex:
                  isViewingAnnotatedComment &&
                  (activeDiscussionId === marker.discussionId || activeTimestamp === marker.timestamp)
                    ? ACTIVE_TIMESTAMP_MARKER_Z_INDEX
                    : undefined,
              }}
              onClick={() => {
                onMarkerClick(marker.timestamp);
              }}
              text={markerToShow ? markerToShow.initials : ''}
              commenterId={markerToShow ? markerToShow.userId : marker.userId}
              count={count}
            />
          );
        })}
      </SeekBarContainer>
    );
  },
);

SeekBar.displayName = 'SeekBar';
