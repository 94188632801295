import { DrawRect } from '~/components/BoundingBox/types';
import { AirCanvasRect, drawRoundedRect } from '~/components/BoundingBox/utils/drawRoundedRect';

export type DoBeforeDrawRect = (params: { ctx: CanvasRenderingContext2D; rect: AirCanvasRect }) => void;

export type DrawBoundingBoxRectParams = {
  ctx: CanvasRenderingContext2D;
  rect: DrawRect;
  clearInside?: boolean;
  strokeColor: string;
  borderWidth: number;
  doBeforeDrawRect?: DoBeforeDrawRect;
};

export const drawBoundingBoxOnCanvas = ({
  ctx,
  rect,
  strokeColor,
  borderWidth,
  doBeforeDrawRect,
}: DrawBoundingBoxRectParams) => {
  // Draw a rectangle on the canvas.
  // adding 0.5 fixes blurry lines: http://diveintohtml5.info/canvas.html#pixel-madness
  // subtract borderWidth to get the correct size of the rectangle after stroke
  const rectToDraw: AirCanvasRect = {
    x: Math.round(rect.startX) + 0.5,
    y: Math.round(rect.startY) + 0.5,
    h: Math.round(rect.endY - rect.startY) + 0.5 - borderWidth,
    w: Math.round(rect.endX - rect.startX) + 0.5 - borderWidth,
  };

  doBeforeDrawRect?.({ ctx, rect: rectToDraw });

  ctx.strokeStyle = strokeColor;
  ctx.lineWidth = borderWidth;
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.shadowBlur = 2;
  ctx.shadowColor = 'rgba(0, 0, 0, 0.9)';

  drawRoundedRect(ctx, rectToDraw);
};

export const clearInsideRect = ({ rect, ctx }: { ctx: CanvasRenderingContext2D; rect: AirCanvasRect }) => {
  // to draw a transparent rectangle in overlay, we have to draw a rectangle first and fill it with some color
  // then we have to draw a bounding box, which will be added to canvas without changes
  ctx.globalCompositeOperation = 'destination-out';
  ctx.fillStyle = 'rgba(1,1,1,1)';
  drawRoundedRect(ctx, rect);
  ctx.fill();

  ctx.globalCompositeOperation = 'source-over';
};
