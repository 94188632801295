import classNames from 'classnames';
import { memo, PropsWithChildren } from 'react';

type VersionsListContainerProps = object;

export const VersionsListContainer = memo(({ children }: PropsWithChildren<VersionsListContainerProps>) => {
  return (
    <div
      className={classNames(
        'versions-list-container flex h-full w-full flex-1 shrink-0 items-center justify-start overflow-auto pb-4 [&>*]:shrink-0',
      )}
    >
      {children}
    </div>
  );
});

VersionsListContainer.displayName = 'VersionsListContainer';
