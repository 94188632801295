import type { Clip } from '@air/api/types';
import { memo } from 'react';

import { AssetMobileVersionsItem } from '~/_components/AssetMobileVersionsListItem';
import { AssetMobileVersionsListTitle } from '~/_components/AssetMobileVersionsListTitle';

export type AssetMobileVersionsListProps = {
  renderVersion?: (version: Clip) => JSX.Element;
  title: string;
  versions: Clip[];
};

export const AssetMobileVersionsList = memo(({ renderVersion, title, versions }: AssetMobileVersionsListProps) => {
  return (
    <div className="flex flex-col gap-4">
      <AssetMobileVersionsListTitle>{title}</AssetMobileVersionsListTitle>
      {versions.map((version) =>
        !!renderVersion ? renderVersion(version) : <AssetMobileVersionsItem key={version.id} version={version} />,
      )}
    </div>
  );
});

AssetMobileVersionsList.displayName = 'AssetMobileVersionsList';
