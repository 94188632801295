import { AssetCustomFieldValue, BoardCustomFieldValue } from '@air/api/types';
import { ReactNode } from 'react';

import {
  CustomFieldLabel,
  CustomFieldLabelProps,
} from '~/components/CustomFields/CustomFieldInputLabel/CustomFieldLabel';

export interface PanelCustomFieldContainerProps<Field extends BoardCustomFieldValue | AssetCustomFieldValue> {
  canEditCustomFields: boolean;
  canDeleteCustomFields: boolean;
  field: Field;
  onClick?: CustomFieldLabelProps['onClick'];
  children: ReactNode;
}

export const PanelCustomFieldContainer = <Field extends BoardCustomFieldValue | AssetCustomFieldValue>({
  canEditCustomFields,
  canDeleteCustomFields,
  field,
  onClick,
  children,
}: PanelCustomFieldContainerProps<Field>) => {
  return (
    <div className="mb-4 flex w-full flex-col items-start justify-start gap-1">
      <CustomFieldLabel
        field={field}
        canDeleteCustomFields={canDeleteCustomFields}
        canEditCustomFields={canEditCustomFields}
        onClick={onClick}
      />
      {children}
    </div>
  );
};
