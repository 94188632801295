import { AnnotationOutlinePlus, Comment, LineweightMedium, Redo, Undo } from '@air/next-icons';
import { memo } from 'react';

import { ShortcutsList } from '~/components/ShortcutsList';

export const ANNOTATIONS_SHORTCUTS = [
  {
    id: 'open-comments',
    icon: Comment,
    label: 'Open/close comments panel',
    keys: ['meta+shift+c'],
  },
  {
    id: 'add-annotation',
    icon: AnnotationOutlinePlus,
    label: 'Add annotation',
    keys: ['meta+shift+a'],
  },
  {
    id: 'draw-annotation',
    icon: LineweightMedium,
    label: 'Draw annotation',
    keys: ['meta+shift+d'],
  },
  {
    id: 'undo',
    icon: Undo,
    label: 'Undo',
    keys: ['meta+z'],
  },
  {
    id: 'redo',
    icon: Redo,
    label: 'Redo',
    keys: ['meta+shift+z'],
  },
];

export const AnnotationsShortcutsInfo = memo(() => <ShortcutsList shortcuts={ANNOTATIONS_SHORTCUTS} />);

AnnotationsShortcutsInfo.displayName = 'AnnotationsShortcutsInfo';
