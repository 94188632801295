/**
 * @description This is used to calculate the respective height/width based on the provided ratio
 * @param length The height or the width
 * @param ratio The aspect ratio that the calculation should use
 * @returns the updated value of the height or width
 */
export const calculateDimensionWithAspectRatio = (length: number, ratio: number): number => {
  const value = length * ratio;
  return Math.round(value);
};

/**
 * @description This is used to calculate the ratio, given a height and width.
 * @param height The height of the item
 * @param width The width of the item
 * @returns the ratio between the 2 values
 */
export const calculateAspectRatio = (width: number, height: number): number | null => {
  if (!width || !height) return null;
  return height / width;
};
