import { LockFilled, LockOpen } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { tailwindMerge } from '@air/tailwind-variants';
import { useField } from 'formik';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import {
  DIMENSION_BAR_ASPECT_RATIO_BUTTON,
  DIMENSION_BAR_HEIGHT_INPUT,
  DIMENSION_BAR_WIDTH_INPUT,
} from '~/constants/testIDs';

import { DimensionBarError } from './components/DimensionBarError';
import { DimensionBarInput } from './components/DimensionBarInput';
import { calculateAspectRatio, calculateDimensionWithAspectRatio } from './utils';

export type DimensionUnits = 'px';
export type DimensionMeasurement = 'width' | 'height';

/**
 * @description These are the minimum requirements the consuming Formik form must have as fields (and their resp. types) to use the Dimension Bar.`Size` refers to the aspect ratio and will be converted to a number (i.e. '2' becomes the number 2, and '1.78' becomes 1.78).
 */
export interface DimensionBarFormData {
  width: number;
  height: number;
  size: string | SizeValue;
}

/**
 * @description These are the minimum requirements for the  options in the size single select
 */
export enum SizeValue {
  Custom = '0',
  Original = '1',
}

export interface DimensionBarProps {
  className?: string;
  dimensionUnits?: DimensionUnits;
  disabled?: boolean;
  onError?: (hasError: boolean) => void;
  onLinkClick?: (isAspectRatioLocked: boolean) => void;
  setIsEditing: (isEditing: boolean) => void;
}

/**
 * @description This DimensionBar component is intended to be used in conjunction with a Formik form that contains an adjacent SingleSelect.
 * The form should include a `height` and a `width` field where each field is an integer/number. The Single Select should have at
 * least 2 values (Original and Custom).
 */
export const DimensionBar = ({
  dimensionUnits = 'px',
  disabled = false,
  onError,
  onLinkClick,
  setIsEditing,
  className,
}: DimensionBarProps) => {
  const [
    { onChange: updateFormikWidth },
    { error: widthError, initialValue: initialWidth = 0, value: width, touched: touchedWidth },
    { setValue: setWidthValue },
  ] = useField<DimensionBarFormData['width']>('width');

  const [
    { onChange: updateFormikHeight },
    { error: heightError, initialValue: initialHeight = 0, value: height },
    { setValue: setHeightValue },
  ] = useField<DimensionBarFormData['height']>('height');

  const [{ value: formAspectRatio }, _a, { setValue: setFormAspectRatio }] =
    useField<DimensionBarFormData['size']>('size');

  const aspectRatio = Number(formAspectRatio);
  const isSelectValueCustom = formAspectRatio === SizeValue.Custom;
  const isSelectValueOriginal = formAspectRatio === SizeValue.Original;
  const isSelectValue = !(isSelectValueOriginal || isSelectValueCustom);

  const [isWidthFocus, setIsWidthFocus] = useState(false);
  const [isHeightFocus, setIsHeightFocus] = useState(false);
  const [isAspectRatioLocked, setIsAspectRatioLocked] = useState(!!aspectRatio);
  const [hasRecalculatedBothFields, setHasRecalculatedBothFields] = useState(false);
  const [customAspectRatio, setCustomAspectRatio] = useState<number | null>();

  const originalAspectRatio = useMemo(
    () => calculateAspectRatio(initialWidth, initialHeight),
    [initialHeight, initialWidth],
  );

  const lockAspectRatio = () => setIsAspectRatioLocked(true);
  const unlockAspectRatio = () => setIsAspectRatioLocked(false);

  const updateCustomAspectRatio = useCallback(() => {
    const cAspectRatio = calculateAspectRatio(width, height);
    setCustomAspectRatio(cAspectRatio);
  }, [height, width]);

  const onClick = () => {
    isAspectRatioLocked ? unlockAspectRatio() : lockAspectRatio();
    const shouldSetCustomAspectRatio = formAspectRatio === SizeValue.Custom && !isAspectRatioLocked;

    if (shouldSetCustomAspectRatio) updateCustomAspectRatio();
    onLinkClick && onLinkClick(isAspectRatioLocked);
  };

  const resetValues = useCallback(() => {
    setWidthValue(Number(initialWidth));
    setHeightValue(Number(initialHeight));
    // setHeightValue and setWidthValue are not stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialHeight, initialWidth]);

  const updateWidthAndHeight = useCallback(
    () => {
      if (!hasRecalculatedBothFields) {
        setWidthValue(calculateDimensionWithAspectRatio(initialWidth || 1, aspectRatio));
        setHeightValue(calculateDimensionWithAspectRatio(initialHeight || 1, aspectRatio));
        setHasRecalculatedBothFields(true);
      } else {
        setHasRecalculatedBothFields(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [height, width, aspectRatio],
  );

  const handleInputChange = (hasHeightChanged: boolean, value: number) => {
    setFormAspectRatio(SizeValue.Custom);

    if (!isAspectRatioLocked) return;

    const aspectRatio = customAspectRatio ? customAspectRatio : originalAspectRatio;

    if (aspectRatio) {
      hasHeightChanged
        ? setWidthValue(calculateDimensionWithAspectRatio(value, 1 / aspectRatio))
        : setHeightValue(calculateDimensionWithAspectRatio(value, aspectRatio));
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value } = event.target;
    const hasHeightChanged = inputName === 'height';
    const latestValue = Number(value);

    // // NOTE: We need to call the onChange provided from Formik in order to keep formik updated
    // // see: https://stackoverflow.com/a/48980424/8560463
    hasHeightChanged ? updateFormikHeight(event) : updateFormikWidth(event);

    handleInputChange(hasHeightChanged, latestValue);
  };

  useEffect(() => {
    const allFieldsUnFocused = !isWidthFocus && !isHeightFocus;
    if (!allFieldsUnFocused) return;

    if (isSelectValue) updateWidthAndHeight();
    if (isSelectValueOriginal) resetValues();
  }, [isHeightFocus, isSelectValue, isSelectValueOriginal, isWidthFocus, resetValues, updateWidthAndHeight]);

  useEffect(() => {
    const hasError = !!heightError || !!widthError;
    if (onError) onError(hasError);
  }, [heightError, onError, widthError]);

  return (
    <div className={tailwindMerge('flex max-h-[62px] flex-col justify-center text-grey-9', className)}>
      <div className="flex items-center gap-4" onBlur={() => setIsEditing(false)} onFocus={() => setIsEditing(true)}>
        <DimensionBarInput
          data-testid={DIMENSION_BAR_WIDTH_INPUT}
          dimensionMeasurement="width"
          dimensionUnits={dimensionUnits}
          disabled={disabled}
          onChange={onChange}
          onFocus={setIsWidthFocus}
        />
        <Tooltip label={isAspectRatioLocked ? 'Unlock aspect ratio' : 'Lock aspect ratio'} side="top">
          <IconButton
            data-testid={`${DIMENSION_BAR_ASPECT_RATIO_BUTTON}${isAspectRatioLocked ? 'LOCKED' : 'UNLOCKED'}`}
            disabled={disabled}
            icon={isAspectRatioLocked ? LockFilled : LockOpen}
            onClick={onClick}
            appearance="ghost"
            color="grey"
            size="small"
            label={isAspectRatioLocked ? 'Unlock aspect ratio' : 'Lock aspect ratio'}
          />
        </Tooltip>
        <DimensionBarInput
          data-testid={DIMENSION_BAR_HEIGHT_INPUT}
          dimensionMeasurement="height"
          dimensionUnits={dimensionUnits}
          onChange={onChange}
          disabled={disabled}
          onFocus={setIsHeightFocus}
        />
      </div>
      <DimensionBarError primaryField="width" shouldShowPrimaryFieldError={!!widthError && touchedWidth} />
    </div>
  );
};
