import { Close, FullScreenEnter, FullScreenOut, Images, ZoomIn, ZoomOut } from '@air/next-icons';
import { memo } from 'react';

import { Shortcut, ShortcutsList } from '~/components/ShortcutsList';

const BASIC_SHORTCUTS: Shortcut[] = [
  {
    id: 'next-previous-asset',
    icon: Images,
    label: 'Next/previous asset',
    keys: ['left', 'right'],
    separator: '/',
  },
  {
    id: 'zoom-in',
    icon: ZoomIn,
    label: 'Zoom in',
    keys: ['meta+plus'],
  },
  {
    id: 'zoom-out',
    icon: ZoomOut,
    label: 'Zoom out',
    keys: ['meta+minus'],
  },
  {
    id: 'zoom-to-100',
    icon: FullScreenOut,
    label: 'Zoom to 100%',
    keys: ['meta+shift+0'],
  },
  {
    id: 'full-screen',
    icon: FullScreenEnter,
    label: 'Full screen',
    keys: ['meta+0'],
  },
  {
    id: 'close-asset',
    icon: Close,
    label: 'Close asset',
    keys: ['esc'],
  },
];

export const BasicShortcutsInfo = memo(() => <ShortcutsList shortcuts={BASIC_SHORTCUTS} />);

BasicShortcutsInfo.displayName = 'BasicShortcutsInfo';
