import { useTrackClickedAssetModalTab } from '@air/analytics';
import { Info } from '@air/next-icons';
import { memo } from 'react';

import { PanelTabButton } from '~/components/AssetModal/Panel/Tabs/components/PanelTabButton';
import { INFO_TAB } from '~/constants/testIDs';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';

export const InfoTabButton = memo(() => {
  const { panel, togglePanel } = useAssetModalPanelContext();
  const { trackClickedAssetModalTab } = useTrackClickedAssetModalTab();

  return (
    <PanelTabButton
      isSelected={panel === AssetModalPanel.INFO}
      label="Info"
      testId={INFO_TAB}
      onClick={(event) => {
        event.stopPropagation();
        togglePanel(AssetModalPanel.INFO);
        trackClickedAssetModalTab({
          tab: AssetModalPanel.INFO,
          panelAction: panel ? (panel === AssetModalPanel.INFO ? 'close' : 'switch') : 'open',
        });
      }}
      icon={<Info className="h-6 w-6" />}
    />
  );
});

InfoTabButton.displayName = 'InfoTabButton';
