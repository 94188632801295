import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { updateCentralizedClipAction } from '~/store/centralizedClip/actions';
import { centralizedClipBoardsSelector } from '~/store/centralizedClip/selectors';
import { useUpdateAssetInAllViews } from '~/utils/mutateUtils/GalleryAssets';
import { useAirStore } from '~/utils/ReduxUtils';

export interface UpdateStateAfterAddingOrRemovingDiscussionProps {
  newCommentCount: number;
  newDiscussionCount: number;
  boardId?: Board['id'];
  clipId: Clip['id'];
}

export const useUpdateStateAfterAddingOrRemovingDiscussion = () => {
  const dispatch = useDispatch();
  const { updateAssetInAllViews } = useUpdateAssetInAllViews();
  const store = useAirStore();

  const updateStateAfterAddingOrRemovingDiscussion = useCallback(
    ({ boardId, clipId, newCommentCount, newDiscussionCount }: UpdateStateAfterAddingOrRemovingDiscussionProps) => {
      const boards = centralizedClipBoardsSelector(store.getState());

      updateAssetInAllViews({
        asset: {
          id: clipId,
          openCommentCount: newCommentCount,
          openDiscussionCount: newDiscussionCount,
        },
        parentBoardId: boardId,
      });
      dispatch(
        updateCentralizedClipAction({
          clip: {
            id: clipId,
            openDiscussionCount: newDiscussionCount,
            openCommentCount: newCommentCount,
            boards: boards.map((board) => {
              if (board.id === boardId) {
                return {
                  ...board,
                  openCommentCount: newDiscussionCount,
                };
              }

              return board;
            }),
          },
        }),
      );
    },
    [dispatch, store, updateAssetInAllViews],
  );

  return { updateStateAfterAddingOrRemovingDiscussion };
};
