import { Board } from '@air/api/types';
import { useMemo } from 'react';
import { SharedImigixAndSourceProps } from 'react-imgix';

import { ImgixImage } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';

const THUMBNAIL_SIZE = 40;

const IMGX_OPTIONS: SharedImigixAndSourceProps['imgixParams'] = {
  fit: 'crop',
  auto: 'compress',
  w: THUMBNAIL_SIZE,
  h: THUMBNAIL_SIZE,
};

export const BoardThumbnail = ({ thumbnails }: Pick<Board, 'thumbnails'>) => {
  const fallbackUrl = useMemo(() => {
    if (!!thumbnails && thumbnails.length) {
      return getCloudFrontUrlFromImgixUrl(thumbnails[0]);
    }

    return '';
  }, [thumbnails]);

  if (!thumbnails?.length) return null;

  return (
    <ImgixImage
      alt=""
      fallbackUrl={fallbackUrl}
      imgixParams={IMGX_OPTIONS}
      height={THUMBNAIL_SIZE}
      src={thumbnails[0]}
      width={THUMBNAIL_SIZE}
    />
  );
};
