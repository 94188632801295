import { Caution } from '@air/next-icons';
import { Banner } from '@air/primitive-banner';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { type HotkeyCallback, useHotkeys } from 'react-hotkeys-hook';

import { DimensionBar } from '~/components/DimensionBar/DimensionBar';
import { ExportImageExtSelect } from '~/components/ExportImagesModal/components/ExportImagesModalForm/ExportImageExtSelect';
import { ExportImageSizeSelect } from '~/components/ExportImagesModal/components/ExportImagesModalForm/ExportImageSizeSelect';

import { ExportImagesFormData } from '../../ExportImagesModal';
import { ImageSuffixFormField } from './components/ImageSuffixFormField';

export interface ExportImagesModalFormProps {
  exportImage: (formValues: ExportImagesFormData) => void;
  imageProcessingError: boolean;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  setIsExportDisabled: (isDisabled: boolean) => void;
}

export const ExportImagesModalForm = ({
  exportImage,
  imageProcessingError,
  isEditing,
  setIsEditing,
  setIsExportDisabled,
}: ExportImagesModalFormProps) => {
  const { values } = useFormikContext<ExportImagesFormData>();

  const keyboardHandler = useCallback<HotkeyCallback>(() => exportImage(values), [exportImage, values]);

  useHotkeys('enter', keyboardHandler, { enableOnFormTags: ['INPUT'], enabled: !isEditing });

  return (
    <>
      <div className="flex justify-between pb-4">
        <ExportImageSizeSelect setIsEditing={setIsEditing} />
      </div>
      <DimensionBar onError={setIsExportDisabled} setIsEditing={setIsEditing} className="mb-2" />
      <div className="flex flex-col justify-end">
        <div className="flex justify-between">
          <ImageSuffixFormField setIsEditing={setIsEditing} />
          <div className="ml-4 shrink-0">
            <ExportImageExtSelect setIsEditing={setIsEditing} />
          </div>
        </div>
      </div>
      {imageProcessingError && (
        <Banner className="mt-4 items-center" appearance="danger" prefix={<Caution className="h-4 w-4" />}>
          <p>Error: File cannot be processed.</p>
        </Banner>
      )}
    </>
  );
};
