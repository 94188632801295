import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import Linkify from '~/components/Linkify';
import { centralizedClipDescriptionSelector } from '~/store/centralizedClip/selectors';

export const InfoPanelDescription = memo(() => {
  const description = useSelector(centralizedClipDescriptionSelector);

  return (
    <div className="flex w-full items-center justify-between px-2 py-1.5">
      <span className="w-full break-words text-14 text-grey-11">
        <Linkify>{description}</Linkify>
      </span>
    </div>
  );
});

InfoPanelDescription.displayName = 'InfoPanelDescription';
