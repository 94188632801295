import { memo, ReactNode } from 'react';

export interface HeaderProps {
  headerLeft?: ReactNode;
  headerRight: ReactNode;
}

export const Header = memo(({ headerLeft, headerRight }: HeaderProps) => {
  return (
    <div className="flex h-[90px] w-full shrink-0 items-center justify-start border-b border-b-grey-5 bg-grey-1 pr-4">
      {/* LEFT SIDE */}
      <div className="flex h-full min-w-0 flex-1 shrink-0 flex-col items-start justify-center overflow-hidden">
        {headerLeft}
      </div>
      {/* RIGHT SIDE */}
      <div className="ml-auto flex h-full items-center justify-end">{headerRight}</div>
    </div>
  );
});

Header.displayName = 'Header';
