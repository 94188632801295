import { ClipMetadata } from '@air/api';
import { formatBytes, formatDuration } from '@air/utilities';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { InfoPanelDetailDescription } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/IntoPanelDetailDescription';
import { InfoPanelDetailTitle } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/IntoPanelDetailTitle';
import {
  centralizedClipDimensionsSelector,
  centralizedClipDurationSelector,
  centralizedClipExtSelector,
  centralizedClipSizeSelector,
} from '~/store/centralizedClip/selectors';
import { getExtStyles } from '~/utils/FileExtensionUtils';
import { isStringMetadataType } from '~/utils/iptcDataTypes';

export interface InfoPanelFileDetailsProps {
  clipMetadata: ClipMetadata[] | undefined;
}

export const InfoPanelFileDetails = memo(({ clipMetadata }: InfoPanelFileDetailsProps) => {
  const dimensions = useSelector(centralizedClipDimensionsSelector);
  const extension = useSelector(centralizedClipExtSelector);
  const duration = useSelector(centralizedClipDurationSelector);
  const size = useSelector(centralizedClipSizeSelector);

  const stats = [formatBytes(size)];
  if (dimensions.width && dimensions.height) {
    stats.push(`${dimensions.width} x ${dimensions.height}`);
  }

  const videoFormat = clipMetadata?.find((metadata) => metadata.fieldName === 'VideoFieldOrder');
  const aspectRatio = clipMetadata?.find((metadata) => metadata.fieldName === 'VideoDisplayAspectRatio');

  const additionalStats: string[] = [];

  if (aspectRatio && isStringMetadataType(aspectRatio)) {
    additionalStats.push(aspectRatio.data);
  }

  if (videoFormat && isStringMetadataType(videoFormat)) {
    additionalStats.push(videoFormat.data);
  }

  const formattedDuration = duration ? formatDuration(duration) : null;

  const Icon = getExtStyles(extension).iconComponent;

  return (
    <InfoPanelDetailsSection Icon={Icon} title="Details">
      {!!extension && <InfoPanelDetailTitle>{extension.toUpperCase()}</InfoPanelDetailTitle>}
      <InfoPanelDetailDescription>{stats.join(', ')}</InfoPanelDetailDescription>
      {additionalStats.length > 0 && (
        <InfoPanelDetailDescription>{additionalStats.join(', ')}</InfoPanelDetailDescription>
      )}
      {!!formattedDuration && <InfoPanelDetailDescription>{formattedDuration}</InfoPanelDetailDescription>}
    </InfoPanelDetailsSection>
  );
});

InfoPanelFileDetails.displayName = 'InfoPanelFileDetails';
