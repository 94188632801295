import { Tooltip } from '@air/primitive-tooltip';
import { tailwindMerge } from '@air/tailwind-variants';
import { FunctionComponent, memo, PropsWithChildren } from 'react';

import { IconType } from '~/types/icons';

interface InfoPanelSectionProps {
  Icon: IconType | FunctionComponent;
  title: string;
  className?: string;
}

export const InfoPanelDetailsSection = memo(
  ({ title, Icon, children, className }: PropsWithChildren<InfoPanelSectionProps>) => {
    return (
      <div
        className={tailwindMerge('flex gap-4 border-b border-grey-3 px-2 py-3 [&:last-of-type]:border-none', className)}
      >
        <Tooltip label={title} side="left">
          <Icon className="mt-0.5 h-4 w-4 shrink-0 cursor-pointer text-grey-8" />
        </Tooltip>
        <div className="w-full">{children}</div>
      </div>
    );
  },
);

InfoPanelDetailsSection.displayName = 'InfoPanelSection';
