import { PercentageRect } from '~/components/BoundingBox/types';
import { BoundingBoxCanvas } from '~/components/BoundingBox/utils/BoundingBoxCanvas';
import { clearInsideRect, drawBoundingBoxOnCanvas } from '~/components/BoundingBox/utils/drawBoundingBoxOnCanvas';
import { drawMovableCornerForRect } from '~/components/BoundingBox/utils/drawSquareAroundPoint';

export interface DrawBoundingBoxesOnCanvasParams {
  canvas: BoundingBoxCanvas;
  boxes: PercentageRect[];
  strokeColor: string;
  borderWidth: number;
}

/**
 * This method groups drawing bounding boxes on canvas to avoid calling beginPath, closePath and stroke multiple times.
 * This is used to increase performance
 */
export const drawBoundingBoxesOnCanvas = ({
  canvas,
  boxes,
  borderWidth,
  strokeColor,
}: DrawBoundingBoxesOnCanvasParams) => {
  const ctx = canvas.getDrawingCanvasHandle()?.getCanvas()?.getContext('2d');

  if (!ctx) return;

  ctx.beginPath();
  boxes.forEach((box) => {
    canvas.drawPercentageRect({
      percentageRect: box,
      drawRectFunction: ({ rect, context }) => {
        drawBoundingBoxOnCanvas({ ctx: context, rect, strokeColor, borderWidth });
      },
    });
  });
  ctx.closePath();
  ctx.stroke();
};

export interface DrawClearedBoundingBoxOnCanvasParams {
  canvas: BoundingBoxCanvas;
  box: PercentageRect;
  strokeColor: string;
  borderWidth: number;
  isEditable?: boolean;
}

export const drawClearedBoundingBoxOnCanvas = ({
  canvas,
  box,
  strokeColor,
  borderWidth,
  isEditable,
}: DrawClearedBoundingBoxOnCanvasParams) => {
  const ctx = canvas.getDrawingCanvasHandle()?.getCanvas()?.getContext('2d');

  if (!ctx) return;

  canvas.drawPercentageRect({
    percentageRect: box,
    drawRectFunction: ({ rect, context }) => {
      ctx.beginPath();
      drawBoundingBoxOnCanvas({
        ctx: context,
        rect,
        strokeColor,
        borderWidth,
        doBeforeDrawRect: clearInsideRect,
      });
      ctx.closePath();
      ctx.stroke();

      if (isEditable) {
        drawMovableCornerForRect({ ctx, rect, strokeColor });
      }
    },
  });
};
