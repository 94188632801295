import React, { memo } from 'react';

import { FloatingCommentForm } from '~/components/Annotations/shared/FloatingCommentForm';
import {
  BoundingBoxTextContainer,
  BoundingBoxTextContainerProps,
} from '~/components/BoundingBox/components/BoundingBoxTextContainer';
import { useAccountContext } from '~/providers/AccountProvider';

export type BoxAnnotationNewCommentProps = Omit<BoundingBoxTextContainerProps, 'user'>;

export const AnnotationCommentInput = memo((props: BoxAnnotationNewCommentProps) => {
  const { data } = useAccountContext();

  return (
    <BoundingBoxTextContainer user={data} {...props}>
      <FloatingCommentForm />
    </BoundingBoxTextContainer>
  );
});

AnnotationCommentInput.displayName = 'BoxAnnotationCommentInput';
