import { Board, Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { EditableText, EditableTextProps } from '@air/zephyr-editable-text';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import {
  AssetTitleValidationSchema,
  HEADER_TITLE_INPUT_STYLES,
} from '~/components/AssetModal/Header/components/EditableAssetModalHeaderTitle';
import { ASSET_TITLE } from '~/constants/testIDs';
import { centralizedClipBoardInfoSelector, CentralizedClipSelectors } from '~/store/centralizedClip/selectors';

export type MobileHeaderProps = {
  onClose?: () => void;
  onUpdateTitle?: EditableTextProps['onSubmit'];
};

export const MobileHeader = memo(({ onClose, onUpdateTitle }: MobileHeaderProps) => {
  const title = useSelector(CentralizedClipSelectors.displayTitle);

  const { current, others } = useSelector(centralizedClipBoardInfoSelector);

  return (
    <div className="flex h-[72px] w-full shrink-0 items-center justify-start bg-pigeon-850 p-3">
      <div className="flex h-full min-w-0 flex-1 flex-col items-start justify-center gap-2 px-3">
        {!!onUpdateTitle ? (
          <EditableText
            isSingleLine
            required
            onSubmit={onUpdateTitle}
            variant="text-ui-18"
            value={title || ''}
            data-testid={ASSET_TITLE}
            label="Asset title"
            placeholder="Enter a name"
            tx={{ ...HEADER_TITLE_INPUT_STYLES, marginX: -12 }}
            formatValue={(value) => <span>{value}</span>}
            validationSchema={AssetTitleValidationSchema}
          />
        ) : (
          <h1
            className="flex h-9 w-full shrink-0 items-center truncate text-18 font-semibold text-pigeon-50"
            data-testid={ASSET_TITLE}
          >
            {title}
          </h1>
        )}
        {(!!current || !!others.length) && (
          <div className="-mt-2.5 flex items-center justify-start">
            {!!current && (
              <div className="text-14 text-pigeon-100">
                {'in'}
                <span className="ml-1 font-medium text-pigeon-50">{current.title}</span>
              </div>
            )}
            {!!others.length && (
              <>
                {!!current && <div className="text-14 text-pigeon-100">・</div>}
                <Board className="h-4 w-4 text-pigeon-50" />
                <div className="ml-1 text-14 font-medium text-pigeon-100">{others.length}</div>
              </>
            )}
          </div>
        )}
      </div>
      {!!onClose && (
        <IconButton
          icon={Close}
          appearance="ghost"
          color="grey"
          size="extra-large"
          onClick={onClose}
          className="ml-auto"
          label="Close modal"
        />
      )}
    </div>
  );
});

MobileHeader.displayName = 'MobileHeader';
