import { DropdownMenu, DropdownMenuLabel } from '@air/primitive-dropdown-menu';
import { DropdownMenuItem } from '@air/primitive-dropdown-menu/src/DropdownMenuItem';
import Router from 'next/router';
import pluralize from 'pluralize';
import { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BoardThumbnail } from '~/components/Discussions/DiscussionsMenu/components/BoardThumbnail';
import { CommentCount } from '~/components/Discussions/DiscussionsMenu/components/CommentCount';
import { DiscussionsLibraryMenuItem } from '~/components/Discussions/DiscussionsMenu/components/DiscussionsLibraryMenuItem';
import { DiscussionsMenuItem } from '~/components/Discussions/DiscussionsMenu/components/DiscussionsMenuItem';
import { createAllContentAssetRoute } from '~/constants/routes';
import { useGoToBoardAssetPage } from '~/hooks/useGoToBoardAssetPage';
import { centralizedClipBoardInfoSelector, centralizedClipSelector } from '~/store/centralizedClip/selectors';

export interface GlobalCommentsMenu {
  trigger?: ReactNode;
}

export const DiscussionsMenu = ({ trigger }: GlobalCommentsMenu) => {
  const asset = useSelector(centralizedClipSelector);
  const { current, others } = useSelector(centralizedClipBoardInfoSelector);
  const { goToBoardAssetPage } = useGoToBoardAssetPage();

  const libraryMenuItem = useMemo(() => {
    return (
      <DropdownMenuItem onClick={() => Router.push(createAllContentAssetRoute(asset.id))}>
        <DiscussionsLibraryMenuItem />
      </DropdownMenuItem>
    );
  }, [asset.id]);

  return (
    <DropdownMenu className="w-[376px]" trigger={trigger} size="large">
      <DropdownMenuLabel>Current location</DropdownMenuLabel>
      {current ? (
        <DropdownMenuItem disabled onClick={() => goToBoardAssetPage({ board: current, asset })}>
          <DiscussionsMenuItem
            adornmentRight={<CommentCount count={current.openCommentCount} />}
            title={current.title}
            thumbnail={<BoardThumbnail thumbnails={current.thumbnails} />}
          />
        </DropdownMenuItem>
      ) : (
        libraryMenuItem
      )}

      {others.length > 0 && (
        <>
          <DropdownMenuLabel>
            Appears in {others.length} other {pluralize('location', others.length)}
          </DropdownMenuLabel>

          {others.map((board) => {
            return (
              <DropdownMenuItem key={board.id} onClick={() => goToBoardAssetPage({ board, asset })}>
                <DiscussionsMenuItem
                  adornmentRight={<CommentCount count={board.openCommentCount} />}
                  ancestors={board.ancestors}
                  thumbnail={<BoardThumbnail thumbnails={board.thumbnails} />}
                  title={board.title}
                />
              </DropdownMenuItem>
            );
          })}
        </>
      )}

      {!!current && libraryMenuItem}
    </DropdownMenu>
  );
};
