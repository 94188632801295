import { useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export const useCommentEditing = () => {
  const [isEditing, setEditing] = useState(false);

  const enableEditing = useCallback(() => setEditing(true), []);
  const disableEditing = useCallback(() => setEditing(false), []);

  useHotkeys(
    'esc',
    () => {
      disableEditing();
    },
    { enabled: isEditing, enableOnFormTags: ['TEXTAREA'] },
    [disableEditing],
  );

  return {
    isEditing,
    enableEditing,
    disableEditing,
  };
};
