import { Fragment } from 'react';

import { KeyShortcut } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoModal/shared/KeyShortcut';
import { ShortcutInfo } from '~/components/ShortcutInfo';
import { IconType } from '~/types/icons';
import { formatShortcutKey } from '~/utils/KeyUtils';

export type Shortcut = {
  id: string;
  icon: IconType;
  label: string;
  keys: string[];
  separator?: string;
};

export type ShortcutsListProps = {
  shortcuts: Shortcut[];
};

export const ShortcutsList = ({ shortcuts }: ShortcutsListProps) => {
  return (
    <>
      {shortcuts.map((shortcut) => {
        return (
          <KeyShortcut key={shortcut.id} icon={shortcut.icon} text={shortcut.label}>
            {shortcut.keys.map((key, index, array) => {
              const isCombination = key.includes('+') && key.split('+').length > 1;
              const isLast = array[array.length - 1] === key;

              if (isCombination) {
                return (
                  <Fragment key={index}>
                    {key.split('+').map((keyPart, index) => {
                      return <ShortcutInfo key={index}>{formatShortcutKey(keyPart)}</ShortcutInfo>;
                    })}
                  </Fragment>
                );
              }

              return (
                <Fragment key={index}>
                  <ShortcutInfo key={key}>{formatShortcutKey(key)}</ShortcutInfo>

                  {!isLast && <span className="mx-0.5 text-12 text-pigeon-400">{shortcut.separator || 'or'}</span>}
                </Fragment>
              );
            })}
          </KeyShortcut>
        );
      })}
    </>
  );
};
