'use client';
import { BrowserUtils } from '~/utils/BrowserUtils';

export const formatShortcutKey = (key: string) => {
  switch (key) {
    case 'meta':
      return BrowserUtils.isMacOS() ? '⌘' : 'ctrl';
    case 'left':
      return '←';
    case 'right':
      return '→';
    case 'up':
      return '↑';
    case 'down':
      return '↓';
    case 'shift':
      return '⇧';
    case 'plus':
      return '+';
    case 'minus':
      return '-';
    default:
      return key;
  }
};
