import { MemoizedContextAction } from '@air/react-memoized-context';
import produce from 'immer';

import {
  DiscussionsPanelContextActions,
  DiscussionsPanelContextValue,
} from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';

export const discussionsPanelContextDispatch = (state: DiscussionsPanelContextValue, action: MemoizedContextAction) => {
  switch (action.type) {
    case DiscussionsPanelContextActions.setDiscussionsFilter:
      return produce(state, (draft) => {
        draft.discussionsFilter = action.data;
      });
    case DiscussionsPanelContextActions.setDiscussionsSort:
      return produce(state, (draft) => {
        draft.discussionsSort = action.data;
      });
    case DiscussionsPanelContextActions.setDiscussionsVersionFilter:
      return produce(state, (draft) => {
        draft.discussionsVersionFilter = action.data;
      });
    case DiscussionsPanelContextActions.setReplyingDiscussionId:
      return produce(state, (draft) => {
        draft.replyingDiscussionId = action.data;
      });
    case DiscussionsPanelContextActions.clearReplyingDiscussionId:
      return produce(state, (draft) => {
        draft.replyingDiscussionId = undefined;
      });
    case DiscussionsPanelContextActions.setRepliesOpen:
      return produce(state, (draft) => {
        draft.openReplies[action.data.discussionId] = action.data.isOpen;
      });
  }
  return state;
};

export const discussionsFilterSelector = (state: DiscussionsPanelContextValue) => state.discussionsFilter;
export const discussionsSortSelector = (state: DiscussionsPanelContextValue) => state.discussionsSort;
export const discussionsVersionFilterSelector = (state: DiscussionsPanelContextValue) => state.discussionsVersionFilter;
export const replyingDiscussionIdSelector = (state: DiscussionsPanelContextValue) => state.replyingDiscussionId;
export const isDiscussionRepliesOpenSelector = (state: DiscussionsPanelContextValue, discussionId: string) =>
  state.openReplies[discussionId];
