import { ClipStatus, ClipType } from '@air/api/types';
import { useBreakpointsContext } from '@air/provider-media-query';
import { debounce, isNull, isUndefined, noop } from 'lodash';
import {
  forwardRef,
  memo,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { buildURL } from 'react-imgix';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { Annotations } from '~/components/Annotations/Annotations';
import { MAX_ANNOTATION_ZOOM } from '~/components/AssetModal/Visualizer/ImageVisualizer/shared/constants';
import { ZoomToPointParams } from '~/components/AssetModal/Visualizer/ImageVisualizer/shared/types';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';
import { IMAGE_THEATER } from '~/constants/testIDs';
import useIsCmdPressed from '~/hooks/useIsCmdPressed';
import { useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import { isAnnotationsEnabledSelector } from '~/providers/AnnotationProvider/annotationProviderUtils';
import {
  centralizedClipAssetsSelector,
  centralizedClipDescriptionSelector,
  centralizedClipDimensionsSelector,
  centralizedClipStatusSelector,
  centralizedClipTypeSelector,
} from '~/store/centralizedClip/selectors';

const DEFAULT_IMG_SIZE = 2000;

interface ImageVisualizerChildrenParams {
  imageElementWidth: number;
  imageElementHeight: number;
  enableImageMouseEvents: (enable: boolean) => void;
  zoomToPoint: (params: ZoomToPointParams) => void;
  currentZoom: number;
  isZooming: boolean;
}

export interface ForwardedImageVisualizerClipProps {
  setIsSelecting: (isSelecting: boolean) => void;
  handleMouseStart?: (event: MouseEvent<HTMLDivElement>) => void;
  handleMouseMove?: (event: MouseEvent<HTMLDivElement>) => void;
  handleMouseStop?: (event: MouseEvent<HTMLDivElement>) => void;
  isFullScreen: boolean;
  isShowOriginal: boolean;
  zoom: number;
  imageRef: RefObject<HTMLImageElement>;
  canAnnotateClip: boolean;
  children?: (params: ImageVisualizerChildrenParams) => ReactNode;
  zoomToPoint: (params: ZoomToPointParams) => void;
}

const ForwardedImageVisualizerClip = forwardRef<HTMLDivElement, ForwardedImageVisualizerClipProps>(
  (
    {
      setIsSelecting,
      handleMouseStart = noop,
      handleMouseMove = noop,
      handleMouseStop = noop,
      isFullScreen,
      isShowOriginal,
      zoom,
      imageRef,
      canAnnotateClip,
      children,
      zoomToPoint,
    }: ForwardedImageVisualizerClipProps,
    containerRef,
  ) => {
    const { isAboveMediumScreen } = useBreakpointsContext();
    const clipAssets = useSelector(centralizedClipAssetsSelector);
    const clipStatus = useSelector(centralizedClipStatusSelector);
    const description = useSelector(centralizedClipDescriptionSelector);
    const clipType = useSelector(centralizedClipTypeSelector);
    const [error, setError] = useState(false);
    const [isZooming, setIsZooming] = useState(false);
    const [mouseEventsEnabled, setMouseEventsEnabled] = useState(true);

    const { height: originalImageHeight, width: originalImageWidth } = useSelector(centralizedClipDimensionsSelector);

    const isCmdPressed = useIsCmdPressed();

    const disableZooming = useMemo(
      () =>
        debounce(() => {
          setIsZooming(false);
        }, 200),
      [],
    );

    useEffect(() => {
      if (zoom !== 1) {
        setIsZooming(true);
        disableZooming();
      }
    }, [disableZooming, zoom]);

    const isAnnotationsEnabled = useAnnotationContextSelector(isAnnotationsEnabledSelector);

    const canStartAnnotation = canAnnotateClip && isAboveMediumScreen && !isFullScreen;
    const canDrawAnnotation = canStartAnnotation && isAnnotationsEnabled && !isCmdPressed;

    const isZoomedIn = zoom > 1 && (!canDrawAnnotation || isCmdPressed);

    const { height: measuredHeight = 0, width: measuredWidth = 0 } = useResizeDetector<HTMLDivElement>({
      handleHeight: true,
      targetRef: containerRef as MutableRefObject<HTMLDivElement | null>,
    });

    const { height: imageHeight, width: imageWidth } = useResizeDetector<HTMLDivElement>({
      handleHeight: true,
      targetRef: imageRef,
    });

    const containerWidth = measuredWidth || 0;
    const containerHeight = measuredHeight || 0;

    const { src, fallbackUrl } = useMemo(() => {
      const clipFailed = clipStatus === ClipStatus.failed;
      const _src =
        clipType === ClipType.animated && clipAssets.video
          ? clipAssets.video
          : clipFailed && !isUndefined(clipAssets.original)
          ? clipAssets.original
          : isShowOriginal && !isUndefined(clipAssets.original)
          ? clipAssets.original
          : clipAssets.image;
      const _fallbackUrl = getCloudFrontUrlFromImgixUrl(_src);

      return {
        src: _src,
        fallbackUrl: _fallbackUrl,
      };
    }, [clipStatus, clipType, clipAssets.video, clipAssets.original, clipAssets.image, isShowOriginal]);

    useEffect(() => {
      setError(false);
    }, [src]);

    const imgxUrl = useMemo(() => {
      return buildURL(
        src,
        isShowOriginal
          ? /**
             * If we want to show the original without it being resized, we need to make sure that no parameters are
             * passed over to Imigix otherwise it will apply the maximum width/height of 8192px on the image.
             */
            undefined
          : {
              w:
                !isNull(originalImageWidth) && originalImageWidth < DEFAULT_IMG_SIZE
                  ? originalImageWidth
                  : DEFAULT_IMG_SIZE,
              h:
                !isNull(originalImageHeight) && originalImageHeight > DEFAULT_IMG_SIZE
                  ? originalImageHeight
                  : DEFAULT_IMG_SIZE,
              auto: 'compress',
            },
        { src, disableSrcSet: true },
      );
    }, [isShowOriginal, originalImageHeight, originalImageWidth, src]);

    useEffect(() => {
      if (imageRef.current) {
        if (originalImageHeight > containerHeight || isFullScreen) {
          imageRef.current.style.maxHeight = '100%';
        } else {
          imageRef.current.style.maxHeight = `${originalImageHeight}px`;
        }
        if (originalImageWidth > containerWidth || isFullScreen) {
          imageRef.current.style.maxWidth = '100%';
        } else {
          imageRef.current.style.maxWidth = `${originalImageWidth}px`;
        }
        // stretch small images to 100% of they are smaller than screen
        if (isFullScreen && measuredHeight > originalImageHeight && measuredWidth > originalImageWidth) {
          imageRef.current.style.height = '100%';
          imageRef.current.style.width = '100%';
        } else {
          imageRef.current.style.height = 'auto';
          imageRef.current.style.width = 'auto';
        }

        const parentRef = containerRef as MutableRefObject<HTMLDivElement>;
        if (parentRef && parentRef.current) {
          if (isZoomedIn) {
            parentRef.current.style.cursor = 'move';
          } else {
            parentRef.current.style.cursor = 'auto';
          }
        }
      }
    }, [
      containerHeight,
      containerRef,
      containerWidth,
      imageRef,
      isFullScreen,
      isZoomedIn,
      measuredHeight,
      measuredWidth,
      originalImageHeight,
      originalImageWidth,
    ]);

    const onError = useCallback(() => {
      setError(true);
    }, []);

    const { imageElementHeight, imageElementWidth } = useMemo(() => {
      if (!originalImageHeight || !originalImageWidth) {
        return { imageElementHeight: 0, imageElementWidth: 0 };
      }

      const imageRatio = originalImageWidth / originalImageHeight;

      let imageElementHeight;
      let imageElementWidth;

      if (originalImageHeight > originalImageWidth) {
        imageElementHeight = imageHeight ?? 0;
        imageElementWidth = imageElementHeight * imageRatio;
      } else {
        imageElementWidth = imageWidth ?? 0;
        imageElementHeight = imageElementWidth / imageRatio;
      }

      return {
        imageElementHeight,
        imageElementWidth,
      };
    }, [imageHeight, imageWidth, originalImageHeight, originalImageWidth]);

    const Image = useMemo(() => {
      const tailwindClasses =
        'flex object-contain origin-center opacity-100 h-auto max-h-full w-auto max-w-full transition duration-200 ease-out hover:scale-150';
      return error ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img className={tailwindClasses} src={fallbackUrl} alt={description || ''} />
      ) : (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          data-testid="ASSET_MODAL_CLIP_IMAGE"
          className={tailwindClasses}
          ref={imageRef}
          draggable={false}
          alt={description || ''}
          src={imgxUrl}
          onError={onError}
        />
      );
    }, [description, error, fallbackUrl, imageRef, imgxUrl, onError]);

    const enableMouseEvents = useCallback(
      (enable: boolean) => {
        setMouseEventsEnabled(enable);
        setIsSelecting(!enable);
      },
      [setIsSelecting],
    );

    // Disable showing context menu when user clicks left mouse button with meta key pressed
    // we allow to move image around when meta key is pressed and annotation is being drawn
    const disableMetaLeftContextMenu = useCallback((e: MouseEvent<HTMLDivElement>) => {
      if (e.button === 0 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
      }
    }, []);

    const canUseMouseEvents = isCmdPressed || (!canDrawAnnotation && mouseEventsEnabled);

    return (
      <div
        className="relative flex h-full max-h-full w-full touch-none select-none items-center justify-center"
        onContextMenu={disableMetaLeftContextMenu}
        onMouseDown={canUseMouseEvents ? handleMouseStart : undefined}
        onMouseMove={canUseMouseEvents ? handleMouseMove : undefined}
        onMouseUp={canUseMouseEvents ? handleMouseStop : undefined}
        onMouseLeave={canUseMouseEvents ? handleMouseStop : undefined}
        ref={containerRef}
        data-testid={IMAGE_THEATER}
      >
        {Image}
        {!isZooming && zoom < MAX_ANNOTATION_ZOOM && (
          <Annotations
            onIsSelectingChange={setIsSelecting}
            width={imageElementWidth * zoom ?? 0}
            height={imageElementHeight * zoom ?? 0}
            pageNumber={-1}
            canDrawAnnotation={canDrawAnnotation}
            keyShortcutsEnabled={canStartAnnotation}
          />
        )}

        {children?.({
          imageElementWidth: imageElementWidth * zoom ?? 0,
          imageElementHeight: imageElementHeight * zoom ?? 0,
          enableImageMouseEvents: enableMouseEvents,
          zoomToPoint,
          currentZoom: zoom,
          isZooming,
        })}
      </div>
    );
  },
);

ForwardedImageVisualizerClip.displayName = 'ImageVisualizerClip';

export const ImageVisualizerClip = memo(ForwardedImageVisualizerClip);

ImageVisualizerClip.displayName = 'MemoizedImageVisualizerClip';
