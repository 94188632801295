import { useShortIdContext } from '@air/provider-short-id';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AnnotationAvatarsFetcherProps } from '~/components/Annotations/AnnotationsAvatarsContainer/shared/annotationAvatarsTypes';
import { useActiveDiscussionIdObserver } from '~/components/Annotations/shared/useActiveDiscussionIdObserver';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { centralizedClipAssetIdSelector, centralizedClipIdSelector } from '~/store/centralizedClip/selectors';
import { usePublicDiscussions } from '~/swr-hooks/discussions/usePublicDiscussions';
import { canEditAssetDiscussions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';

export const PublicAnnotationsAvatars = memo(({ renderAnnotations }: AnnotationAvatarsFetcherProps) => {
  const boardId = useURLBoardIdSelector();
  const clipId = useSelector(centralizedClipIdSelector);
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const { permissions } = usePublicPermissions();
  const canDiscuss = canViewAssetDiscussions(permissions);
  const { shortId } = useShortIdContext();

  const { data: discussions } = usePublicDiscussions({
    boardId,
    assetId,
    clipId,
    shortId,
    isEnabled: canDiscuss,
  });

  useActiveDiscussionIdObserver(discussions);

  const canEditDiscussions = canEditAssetDiscussions(permissions);

  const renderedAnnotations = useMemo(
    () =>
      renderAnnotations({
        discussions,
        canDelete: canEditDiscussions,
        canEdit: canEditDiscussions,
        canResolve: false,
        canReply: canEditDiscussions,
        canShowMentionsButton: true,
      }),
    [canEditDiscussions, discussions, renderAnnotations],
  );

  return <>{renderedAnnotations}</>;
});

PublicAnnotationsAvatars.displayName = 'PublicAnnotationsAvatars';
