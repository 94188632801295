import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react';

import { BaseMouseEvent, Point } from '~/components/BoundingBox/types';
import { isSafariBrowser } from '~/utils/BrowserUtils';
import { leftMouseEvent } from '~/utils/ClickUtils';

interface DrawingCanvasProps {
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  onSizeChanged?: () => void;

  containerWidth: number;
  containerHeight: number;

  containerLeft: number;
  containerTop: number;

  hasCustomCursor?: boolean;
}

export interface DrawingCanvasHandle {
  getCanvas: () => HTMLCanvasElement | null;
  clearCanvas: () => void;
  getMouseCoords: (params: { event: BaseMouseEvent; lineWidth?: number }) => Point | undefined;
}

const _DrawingCanvas = forwardRef<DrawingCanvasHandle, DrawingCanvasProps>(
  (
    {
      onMouseDown,
      onSizeChanged,
      containerHeight,
      containerWidth,
      containerTop,
      containerLeft,
      onMouseMove,
      onMouseUp,
      hasCustomCursor,
    }: DrawingCanvasProps,
    ref,
  ) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useImperativeHandle(ref, () => ({
      getCanvas: () => canvasRef?.current,
      clearCanvas: () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');

          if (!!ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
        }
      },
      getMouseCoords: ({ event, lineWidth = 0 }: { event: BaseMouseEvent; lineWidth?: number }) => {
        const height = containerHeight ?? 0;
        const width = containerWidth ?? 0;

        // cursor offset does not work on Safari, so add 18 (offsetY) for correct position
        const clientY = isSafariBrowser && hasCustomCursor ? event.clientY + 18 : event.clientY;

        const eventX = Math.min(event.clientX, containerLeft + width - lineWidth);
        const eventY = Math.min(clientY, containerTop + height - lineWidth);
        return {
          x: Math.max(lineWidth, eventX - containerLeft + lineWidth),
          y: Math.max(lineWidth, eventY - containerTop + lineWidth),
        };
      },
    }));

    // This desired triggers for this effect are the containerHeight or containerWidth changing.
    // We want to update the canvas elements width and height so that shapes are drawn correctly.
    // We also want to re-draw the active box, if there is one, based on the new dimensions.
    useEffect(() => {
      // Set the canvas dimensions when the parent size changes.
      if (!!canvasRef.current && !!containerHeight && !!containerWidth) {
        const ctx = canvasRef.current.getContext('2d');
        if (!!ctx) {
          // set bigger size for retina display to avoid blurred lines
          // for bigger screens, even if devicePixelRatio is 1, setting 2 gives better lines
          const devicePixelRatio = Math.max(2, typeof window === 'undefined' ? 2 : window.devicePixelRatio ?? 2);
          ctx.canvas.height = devicePixelRatio * containerHeight;
          ctx.canvas.width = devicePixelRatio * containerWidth;

          ctx.canvas.style.width = `${containerWidth}px`;
          ctx.canvas.style.height = `${containerHeight}px`;
          ctx.scale(devicePixelRatio, devicePixelRatio);
        }
      }
    }, [containerHeight, containerWidth]);

    useEffect(() => {
      onSizeChanged?.();
      // omitted onSizeChanged - we want to call it when width or height changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerWidth, containerHeight]);

    return (
      <canvas
        data-testid="DRAWING_CANVAS"
        ref={canvasRef}
        onMouseDown={onMouseDown ? leftMouseEvent(onMouseDown) : undefined}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp ? leftMouseEvent(onMouseUp) : undefined}
      />
    );
  },
);

_DrawingCanvas.displayName = 'DrawingCanvas';

export const DrawingCanvas = memo(_DrawingCanvas);

DrawingCanvas.displayName = 'DrawingCanvas';
