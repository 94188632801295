import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo, ReactNode } from 'react';

export interface VersionListHeaderProps {
  closePanel: () => void;
  buttons?: ReactNode;
}

export const VersionListHeader = memo(({ closePanel, buttons }: VersionListHeaderProps) => {
  return (
    <div className="flex h-6 w-full content-start items-center">
      <div className="flex-1">
        <h4 className="flex h-6 content-start items-center text-left text-12 font-bold uppercase text-grey-12">
          VERSIONS
        </h4>
      </div>
      <div className="flex items-center gap-2">
        {buttons}
        <IconButton
          appearance="ghost"
          color="grey"
          icon={Close}
          label="Close panel"
          onClick={closePanel}
          size="small"
        />
      </div>
    </div>
  );
});

VersionListHeader.displayName = 'VersionListHeader';
