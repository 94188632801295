import classNames from 'classnames';
import { ComponentProps, memo } from 'react';

import { KeyboardShortcutsInfoButton } from '~/components/AssetModal/Panel/Tabs/KeyboardShortcutsInfoButton/KeyboardShortcutsInfoButton';
import { _TAB } from '~/constants/testIDs';

export type TabsProps = ComponentProps<'div'>;

export const Tabs = memo(({ children, className, ...restOfProps }: TabsProps) => {
  return (
    <div
      className={classNames(
        'flex h-full flex-col items-center justify-between border-l border-l-grey-5 bg-grey-1',
        className,
      )}
      {...restOfProps}
    >
      <div className="flex h-full w-[72px] min-w-[72px] flex-col items-center justify-start overflow-y-auto pt-[30px]">
        {children}
      </div>
      <KeyboardShortcutsInfoButton />
    </div>
  );
});

Tabs.displayName = 'Tabs';
