import { Refresh as RefreshIcon } from '@air/next-icons';
import classNames from 'classnames';
import { memo } from 'react';

import { THUMBNAIL_HEIGHT } from '../../../shared/constants';

interface ProcessingVersionThumbnailProps {
  isCurrentVersion: boolean;
}

export const ProcessingVersionThumbnail = memo(({ isCurrentVersion }: ProcessingVersionThumbnailProps) => {
  return (
    <div
      className={classNames(
        'asset-modal-version-thumbnail pointer-events-none box-border flex w-full items-center justify-center overflow-hidden rounded-sm border border-black bg-pigeon-800',
      )}
      style={{
        height: THUMBNAIL_HEIGHT,
        // @TODO: use theme colors
        boxShadow: isCurrentVersion ? '0px 0px 0px 2px #CECECE' : undefined,
      }}
    >
      <RefreshIcon className="h-8 w-8 animate-spin fill-pigeon-300" />
    </div>
  );
});

ProcessingVersionThumbnail.displayName = 'ProcessingVersionThumbnail';
