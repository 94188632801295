import { Tooltip } from '@air/primitive-tooltip';
import { tailwindVariants } from '@air/tailwind-variants';
import * as RadixSlider from '@radix-ui/react-slider';
import { forwardRef, useEffect, useState } from 'react';

export const slider = tailwindVariants({
  base: 'relative flex touch-none select-none items-center justify-center',
  variants: {
    orientation: {
      horizontal: 'h-4 w-full',
      vertical: 'h-full w-4',
    },
  },
});

export const sliderTrack = tailwindVariants({
  base: 'relative rounded-full bg-grey-5',
  variants: {
    orientation: {
      horizontal: 'h-1 w-full',
      vertical: 'h-full w-1',
    },
  },
});

export const sliderRange = tailwindVariants({
  base: 'absolute rounded-full bg-teal-9',
  variants: {
    orientation: {
      horizontal: 'h-full',
      vertical: 'w-full',
    },
  },
});

export type SwitchProps = RadixSlider.SliderProps & {
  formatTooltip?: (values: number[]) => string;
};

export const Slider = forwardRef<HTMLSpanElement, SwitchProps>(
  ({ className, formatTooltip, orientation = 'horizontal', value, ...restOfProps }: SwitchProps, ref) => {
    const [isPointerDown, setIsPointerDown] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
      const handlePointerMove = () => setShowTooltip(true);
      const handlePointerUp = () => setIsPointerDown(false);

      if (isPointerDown) {
        window.addEventListener('pointermove', handlePointerMove);
        window.addEventListener('pointerup', handlePointerUp);
      }

      return () => {
        window.removeEventListener('pointermove', () => setShowTooltip(true));
        window.removeEventListener('pointerup', handlePointerUp);
      };
    }, [isPointerDown]);

    return (
      <RadixSlider.Root
        className={slider({ orientation, className })}
        orientation={orientation}
        ref={ref}
        value={value}
        onPointerDown={() => setIsPointerDown(true)}
        {...restOfProps}
      >
        <RadixSlider.Track className={sliderTrack({ orientation })}>
          <RadixSlider.Range className={sliderRange({ orientation })} />
        </RadixSlider.Track>
        <Tooltip
          label={!!value && !!formatTooltip && <div className="text-12">{formatTooltip?.(value)}</div>}
          defaultOpen={showTooltip}
          delayDuration={0}
          open={showTooltip}
          side="right"
          sideOffset={2}
        >
          <RadixSlider.Thumb className="block h-2 w-2 cursor-pointer rounded bg-white shadow-popover outline-none" />
        </Tooltip>
      </RadixSlider.Root>
    );
  },
);

Slider.displayName = 'Slider';
