import { useSelector } from 'react-redux';

import { useClipPermissions } from '~/hooks/useClipPermissions';
import { CentralizedClipSelectors } from '~/store/centralizedClip/selectors';

export const useCurrentClipPermissions = () => {
  const clipId = useSelector(CentralizedClipSelectors.clipId);

  return useClipPermissions({ clipId });
};
