import { ClipDiscussionResponse } from '@air/api/types';
import { isUndefined } from 'lodash';

import { getPageNumber, hasAnnotation } from '~/components/Annotations/shared/annotationUtils';
import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { ASSET_MODAL_DISCUSSION_FILTERS } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';

export const getAnnotatedDiscussions = ({
  discussions,
  timestamp,
  pageNumber,
  assetVersion,
  filter,
}: {
  discussions: ClipDiscussionResponse[];
  timestamp?: number;
  pageNumber: number;
  assetVersion?: number;
  filter: ASSET_MODAL_DISCUSSION_FILTERS;
}): ClipDiscussion[] =>
  discussions.filter((discussion): discussion is ClipDiscussion => {
    if (discussion.type !== 'clip') {
      return false;
    }

    if ((!isUndefined(timestamp) || discussion.timestamp) && discussion.timestamp !== timestamp) {
      return false;
    }

    if (pageNumber !== -1 && getPageNumber(discussion.annotation) !== pageNumber) {
      return false;
    }

    if (assetVersion && discussion.assetVersion !== assetVersion) {
      return false;
    }

    if (filter === ASSET_MODAL_DISCUSSION_FILTERS.OPENED && discussion.resolved) {
      return false;
    }

    if (filter === ASSET_MODAL_DISCUSSION_FILTERS.RESOLVED && !discussion.resolved) {
      return false;
    }

    return hasAnnotation(discussion);
  });
