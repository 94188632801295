import Hls from 'hls.js';

export const isHlsSupported = () => {
  return Hls.isSupported();
};

export const loadHls = ({
  src,
  videoElement,
}: {
  src?: string;
  videoElement: HTMLMediaElement | HTMLVideoElement | null;
}) =>
  new Promise<Hls>((resolve, reject) => {
    if (!src || !videoElement || !Hls.isSupported()) return;

    const hlsInstance = new Hls({
      // setting startLevel to -1 causes hls.js to automatically select quality level for the first part depending on the bandwidth
      // see https://github.com/video-dev/hls.js/blob/master/docs/API.md#hlsstartlevel
      startLevel: -1,
    });
    hlsInstance.attachMedia(videoElement);
    hlsInstance.loadSource(src);
    hlsInstance.on(Hls.Events.ERROR, (name, details) => {
      reject({ name, message: JSON.stringify(details) });
    });
    hlsInstance.on(Hls.Events.MEDIA_ATTACHED, () => {
      hlsInstance.loadSource(src);
      hlsInstance.on(Hls.Events.MANIFEST_PARSED, () => {
        resolve(hlsInstance);
      });
    });
  });
