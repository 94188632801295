import {
  BoundingBoxHandler,
  GetNewRectType,
  InternalStartDrawingType,
} from '~/components/BoundingBox/utils/BoundingBoxHandler';

export class BoundingBoxDrawer extends BoundingBoxHandler {
  private startOffsetX: number | null = null;
  private startOffsetY: number | null = null;

  _startDrawing: InternalStartDrawingType = ({ point }) => {
    this.startOffsetX = point.pctX;
    this.startOffsetY = point.pctY;
  };

  _getNewRect: GetNewRectType = ({ point }) => {
    if (this.startOffsetX === null || this.startOffsetY === null) return;

    return {
      pctStartX: this.startOffsetX,
      pctStartY: this.startOffsetY,
      pctEndX: point.pctX,
      pctEndY: point.pctY,
    };
  };

  _finishDrawing = () => {
    this.startOffsetX = null;
    this.startOffsetY = null;
  };
}
