import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, memo } from 'react';

type InfoPanelDetailDescriptionProps = ComponentProps<'p'>;

export const InfoPanelDetailDescription = memo(({ className, ...props }: InfoPanelDetailDescriptionProps) => (
  <p className={tailwindMerge('line-clamp-2 text-12 text-grey-10', className)} {...props} />
));

InfoPanelDetailDescription.displayName = 'InfoPanelDetailDescription';
