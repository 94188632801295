import { TXProp } from '@air/zephyr';
import { EditableText } from '@air/zephyr-editable-text';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ASSET_TITLE } from '~/constants/testIDs';
import {
  centralizedClipAssetIdSelector,
  centralizedClipIdSelector,
  centralizedDisplayTitleSelector,
} from '~/store/centralizedClip/selectors';
import { useUpdateAssetTitle } from '~/swr-hooks/clips/useUpdateAssetTitle';
import { containsRestrictedPathChars } from '~/utils/FileUtils';

// This amount of styling is required to get the
// EditableText component to render in darkmode, at header size.
// Hopefully in the future we'll be able to control both with props
// and wont need this level of re-styling.
export const HEADER_TITLE_INPUT_STYLES: TXProp = {
  maxWidth: '100%',
  fontWeight: 'semibold',
  wordBreak: 'break-word',
  height: 36,
  EditableTextText: {
    width: '100%',
  },
  EditableTextButton: {
    border: '2px solid',
    borderColor: 'transparent',
    width: '100%',
    py: 4,
    span: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    pr: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ['&:hover']: {
      borderColor: 'transparent',
    },
    ['&:focus, &:active']: {
      borderColor: 'transparent',
    },
  },
  EditableTextTextarea: {
    whiteSpace: 'nowrap',
  },
  textarea: {
    bg: 'transparent',
    p: 0,
    m: 0,
    pl: 2,
    height: 36,
    marginTop: -1,
    pr: 20,
    ['&::selection']: {
      bg: 'cuckoo200',
      color: 'cuckoo400',
    },
  },
};

export const AssetTitleValidationSchema = Yup.object().shape({
  ['editable-text-value']: Yup.string()
    .max(255, 'Cannot be longer than 255 characters')
    .required('This field is required')
    .test(
      'restricted-char-validation',
      'Characters : and | cannot be used',
      (val) => !containsRestrictedPathChars(val || ''),
    )
    .matches(/^[^\n]*$/, 'No line breaks allowed'),
});

export const EditableAssetModalHeaderTitle = memo(() => {
  const clipId = useSelector(centralizedClipIdSelector);
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const title = useSelector(centralizedDisplayTitleSelector);
  const { updateAssetTitle } = useUpdateAssetTitle();

  const onSubmit = useCallback(
    ({ 'editable-text-value': newTitle }: { 'editable-text-value': string }) => {
      if (title !== newTitle) {
        updateAssetTitle(clipId, newTitle, assetId);
      }
    },
    [assetId, clipId, title, updateAssetTitle],
  );

  return (
    <EditableText
      errorClassName="bg-grey-12"
      isSingleLine
      required
      onSubmit={onSubmit}
      variant="text-ui-20"
      value={title || ''}
      data-testid={ASSET_TITLE}
      label="Asset title"
      placeholder="Enter a name"
      tx={HEADER_TITLE_INPUT_STYLES}
      formatValue={(value) => <span>{value}</span>}
      validationSchema={AssetTitleValidationSchema}
    />
  );
});

EditableAssetModalHeaderTitle.displayName = 'EditableAssetModalHeaderTitle';
