import { FormikField } from '@air/formik-field';
import { Input } from '@air/primitive-input';
import { FieldInputProps } from 'formik';
import { ReactNode } from 'react';

import { DIMENSION_BAR_INPUT } from '~/constants/testIDs';

import { DimensionBarFormData, DimensionBarProps, DimensionMeasurement } from '../DimensionBar';

interface DimensionBarInputWrapperProps extends Pick<DimensionBarProps, 'dimensionUnits'> {
  children: ReactNode;
  dimensionMeasurement: DimensionMeasurement;
  onFocus: (isFocused: boolean) => void;
}

const DimensionBarInputWrapper = ({
  children,
  dimensionMeasurement,
  dimensionUnits,
  onFocus,
}: DimensionBarInputWrapperProps) => {
  return (
    <div className="flex items-center" onFocus={() => onFocus(true)} onBlur={() => onFocus(false)}>
      <p className="text-12 font-bold uppercase">{dimensionMeasurement}</p>
      {children}
      <p className="text-16 text-grey-11">{dimensionUnits}</p>
    </div>
  );
};

interface DimensionBarInputProps
  extends Pick<DimensionBarInputWrapperProps, 'dimensionMeasurement' | 'dimensionUnits'>,
    Pick<DimensionBarProps, 'disabled'> {
  ['data-testid']?: string;
  onChange: FieldInputProps<DimensionBarFormData['height'] | DimensionBarFormData['width']>['onChange'];
  onFocus: (isFocused: boolean) => void;
}

export const DimensionBarInput = ({
  dimensionMeasurement,
  dimensionUnits,
  disabled,
  onChange,
  onFocus,
  ['data-testid']: testId,
}: DimensionBarInputProps) => {
  return (
    <DimensionBarInputWrapper
      dimensionUnits={dimensionUnits}
      dimensionMeasurement={dimensionMeasurement}
      onFocus={onFocus}
    >
      <FormikField
        id={dimensionMeasurement}
        className="mx-1.5 w-16"
        isLabelHidden
        label={dimensionMeasurement}
        name={dimensionMeasurement}
        isErrorHidden
        onChange={onChange}
        component={
          <Input
            size="large"
            type="number"
            className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            data-testid={testId || DIMENSION_BAR_INPUT}
            disabled={disabled}
          />
        }
      />
    </DimensionBarInputWrapper>
  );
};
