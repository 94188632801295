import { ComponentProps } from 'react';

export type ShortcutInfoProps = ComponentProps<'div'>;

export const ShortcutInfo = ({ children, ...props }: ShortcutInfoProps) => {
  return (
    <div
      className="pointer-events-none flex min-h-[20px] w-fit min-w-[20px] items-center justify-center rounded bg-grey-2 px-1 text-12 uppercase text-grey-11"
      {...props}
    >
      {children}
    </div>
  );
};
