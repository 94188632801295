export interface BoundingBox {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface PercentageRect {
  pctEndX: number;
  pctEndY: number;
  pctStartX: number;
  pctStartY: number;
}

export interface DrawRect {
  endX: number;
  endY: number;
  startX: number;
  startY: number;
}

export type DrawRectFunction = (params: { rect: DrawRect; context: CanvasRenderingContext2D }) => void;

export interface Point {
  x: number;
  y: number;
}

export interface PercentagePoint {
  pctX: number;
  pctY: number;
}

export interface BaseMouseEvent {
  clientX: number;
  clientY: number;
}

export type BoundingBoxAvatarDirection = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export const getHorizontalOpposite = (position: BoundingBoxAvatarDirection): BoundingBoxAvatarDirection => {
  switch (position) {
    case 'top-left':
      return 'top-right';
    case 'top-right':
      return 'top-left';
    case 'bottom-left':
      return 'bottom-right';
    case 'bottom-right':
      return 'bottom-left';
  }
};
