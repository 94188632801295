import { IconButton, IconButtonProps } from '@air/primitive-icon-button';
import { tailwindMerge } from '@air/tailwind-variants';
import { forwardRef, memo } from 'react';

export type AnnotationIconButtonProps = IconButtonProps;

const _AnnotationIconButton = forwardRef<HTMLButtonElement, AnnotationIconButtonProps>(
  ({ className, ...restOfProps }, forwardedRef) => {
    return (
      <IconButton
        appearance="ghost"
        className={tailwindMerge('text-pigeon-100 hover:bg-pigeon-100/15 hover:text-white', className)}
        color="grey"
        ref={forwardedRef}
        size="small"
        {...restOfProps}
      />
    );
  },
);

_AnnotationIconButton.displayName = '_AnnotationIconButton';

export const AnnotationIconButton = memo(_AnnotationIconButton);

AnnotationIconButton.displayName = 'AnnotationIconButton';
