import Router, { useRouter } from 'next/router';
import { useCallback } from 'react';

import { QueryParamNames } from '~/constants/search';
import { getPathWithOmittedParams, pushWithExistingQuery } from '~/utils/PathUtils';

export const useVersionsPanelState = () => {
  const router = useRouter();

  const closeVersionsPanel = useCallback(() => {
    Router.push(getPathWithOmittedParams(router.asPath, [QueryParamNames.versions]), undefined, { shallow: true });
  }, [router]);

  const openVersionsPanel = useCallback(() => {
    pushWithExistingQuery({
      newQuery: { [QueryParamNames.versions]: true },
      method: 'replace',
    });
  }, []);

  const toggleVersionsPanel = () => {
    const isVersionsPanelOpen = new URLSearchParams(router.asPath.split('?')[1]).get(QueryParamNames.versions);

    if (isVersionsPanelOpen) {
      closeVersionsPanel();
    } else {
      openVersionsPanel();
    }
  };

  const setVersionsPanelOpen = (isOpen: boolean) => {
    if (isOpen) {
      openVersionsPanel();
    } else {
      closeVersionsPanel();
    }
  };

  return {
    closeVersionsPanel,
    openVersionsPanel,
    setVersionsPanelOpen,
    toggleVersionsPanel,
    isVersionsPanelOpen: router.query[QueryParamNames.versions] === 'true',
  };
};
