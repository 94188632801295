import { ClipMetadata } from '@air/api';
import { Location } from '@air/next-icons';
import { memo } from 'react';

import { InfoPanelDetailsSection } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailsSection';
import { InfoPanelDetailValue } from '~/components/AssetModal/shared/components/InfoPanel/components/shared/InfoPanelDetailValue';
import { isObjectArrayMetadataType } from '~/utils/iptcDataTypes';

interface InfoPanelLocationProps {
  clipMetadata: ClipMetadata[];
}

export const InfoPanelLocation = memo(({ clipMetadata }: InfoPanelLocationProps) => {
  let city = clipMetadata.find((metadata) => metadata.fieldName === 'City')?.data;
  let state = clipMetadata.find((metadata) => metadata.fieldName === 'ProvinceState')?.data;
  let country = clipMetadata.find((metadata) => metadata.fieldName === 'CountryName')?.data;

  if (!city && !state && !country) {
    const locationShown = clipMetadata.find((metadata) => metadata.fieldName === 'LocationShown');
    if (locationShown && isObjectArrayMetadataType(locationShown) && locationShown.data.length > 0) {
      const location = locationShown.data[0];
      city = location['City'];
      state = location['ProvinceState'];
      country = location['CountryName'];

      if (!city && !state && !country) {
        return null;
      }
    } else {
      return null;
    }
  }

  const locationText = [city, state, country].filter(Boolean).join(', ');

  return (
    <InfoPanelDetailsSection Icon={Location} title="Location">
      <InfoPanelDetailValue value={locationText} />
    </InfoPanelDetailsSection>
  );
});

InfoPanelLocation.displayName = 'InfoPanelLocation';
