import * as RadixProgress from '@radix-ui/react-progress';

export type SeekProgressBarProps = {
  percentage: number;
};

export const SeekProgressBar = ({ percentage }: SeekProgressBarProps) => {
  return (
    <RadixProgress.Root
      className="pointer-events-none my-1.5 h-1 w-full overflow-hidden rounded bg-grey-7"
      value={percentage}
    >
      <RadixProgress.Indicator
        className="h-full w-full rounded bg-teal-9"
        style={{ transform: `translateX(-${100 - percentage}%)` }}
      />
    </RadixProgress.Root>
  );
};
