import { isUndefined } from 'lodash';
import { useCallback, useState } from 'react';

export type UseVideoPlaybackParams = {
  defaultStepIndex: number;
  isPlaying: boolean;
  steps: number[];
  videoElement: HTMLVideoElement | null;
};

export const useVideoPlayback = ({ defaultStepIndex, isPlaying, steps, videoElement }: UseVideoPlaybackParams) => {
  const [playbackStep, setPlaybackStep] = useState<number>();
  const [playbackSpeed, setPlaybackSpeed] = useState<number>();
  const [showPlaybackAlert, setShowPlaybackAlert] = useState(false);

  const onShowPlaybackAlert = useCallback(() => {
    setShowPlaybackAlert((prev) => {
      if (!prev) {
        setTimeout(() => setShowPlaybackAlert(false), 1000);

        return true;
      }

      return prev;
    });
  }, []);

  const onUpdatePlayback = useCallback(
    ({ playbackStep, playbackSpeed }: { playbackStep: number; playbackSpeed: number }) => {
      if (videoElement) {
        if (playbackSpeed < 0 || !playbackSpeed) {
          videoElement.playbackRate = 1;
        } else {
          videoElement.playbackRate = playbackSpeed;
          videoElement.play();
        }
      }

      setPlaybackStep(playbackStep);
      setPlaybackSpeed(playbackSpeed);
      onShowPlaybackAlert();
    },
    [onShowPlaybackAlert, videoElement],
  );

  const onDecreasePlaybackStep = useCallback(() => {
    const newPlaybackStep = isUndefined(playbackStep) ? defaultStepIndex - 1 : playbackStep > 0 ? playbackStep - 1 : 0;
    const newPlaybackSpeed = steps[newPlaybackStep];

    onUpdatePlayback({ playbackStep: newPlaybackStep, playbackSpeed: newPlaybackSpeed });
  }, [defaultStepIndex, onUpdatePlayback, playbackStep, steps]);

  const onIncreasePlaybackStep = useCallback(() => {
    const newPlaybackStep = isUndefined(playbackStep)
      ? isPlaying
        ? defaultStepIndex + 1
        : defaultStepIndex
      : playbackStep < steps.length - 1
      ? playbackStep + 1
      : steps.length - 1;
    const newPlaybackSpeed = steps[newPlaybackStep];

    onUpdatePlayback({ playbackStep: newPlaybackStep, playbackSpeed: newPlaybackSpeed });
  }, [defaultStepIndex, isPlaying, onUpdatePlayback, playbackStep, steps]);

  const onResetPlaybackStep = useCallback(() => {
    if (videoElement) {
      videoElement.playbackRate = 1;
    }

    setPlaybackStep(undefined);
    setPlaybackSpeed(undefined);
  }, [videoElement]);

  return {
    onDecreasePlaybackStep,
    onIncreasePlaybackStep,
    onShowPlaybackAlert,
    onResetPlaybackStep,
    playbackStep,
    playbackSpeed,
    showPlaybackAlert,
  };
};
