import { buildURL } from 'react-imgix';

/** This type represents the extension type that our users can export FROM */
export type ImgixSupportedExportExt =
  | 'ai'
  | 'apng'
  | 'avif'
  | 'bmp'
  | 'eps'
  | 'gif'
  | 'heic'
  | 'ico'
  | 'icns'
  | 'jpg'
  | 'jpeg'
  | 'pct'
  // | 'pdf'
  | 'pjpeg'
  | 'png'
  | 'psd'
  | 'svg'
  | 'tif'
  | 'webp';
// | 'avi'
// | 'h264'
// | 'hls'
// | 'mov'
// | 'mp4'
// | 'wmv'
// | 'webm';

/**
 * This function returns a boolean indicating if the extension you are trying to export can be exported.
 * THIS IS NOT the same as the FORMAT of the export. Meaning, a JPG can be exported, but the EXPORT FORMAT
 * (like it could be exported as a PNG) is different. @see extIsSupportedExportFormat
 * @param ext The extension of the file you are trying to export
 * @returns boolean
 */
export const extCanBeExported = (ext: string): ext is ImgixSupportedExportExt =>
  /**
   * @description List of supported Imgix import formats
   * @see https://docs.imgix.com/best-practices/guide-to-image-types#which-image-formats-can-be-served-with-imgix
   */
  [
    'ai',
    'apng',
    'avif',
    'bmp',
    'eps',
    'gif',
    'heic',
    'ico',
    'icns',
    'jpg',
    'jpeg',
    'pct',
    // 'pdf',
    'pjpeg',
    'png',
    'psd',
    'svg',
    'tif',
    'webp',
    // 'avi',
    // 'h264',
    // 'hls',
    // 'mov',
    // 'mp4',
    // 'wmv',
    // 'webm',
  ].includes(ext.toLowerCase());

/** This type represents the extensions/formats which can be exported from Imgix */
export type ImgixSupportedExportFormat =
  | 'jpg'
  | 'gif'
  | 'png'
  // | 'jp2'
  // | 'json'
  // | 'mp4' we aren't allowing video
  // | 'png8'
  // | 'png32'
  // | 'webm'
  | 'webp';
// | 'blurhash';

/** These are the list of formats we allow our users to export files as */
export const SUPPORTED_EXPORT_FORMATS = [
  'jpg',
  'png',
  'gif',
  // 'jp2',
  // 'json',
  // 'jxr',
  // 'pjpg',
  // 'mp4', we aren't allowing video
  // 'png8',
  // 'png32',
  // 'webm',
  'webp',
  // 'blurhash',
];

/** This function indicates whether or not the extension passed can be used as an export format */
export const extIsSupportedExportFormat = (ext: string): ext is ImgixSupportedExportFormat =>
  /**
   * @description List of supported Imgix export formats
   * @see https://docs.imgix.com/apis/rendering/format/fm
   */
  SUPPORTED_EXPORT_FORMATS.includes(ext.toLowerCase());

/**
 * @description List of support image resize options
 * @see https://docs.imgix.com/apis/rendering/size/fit
 */
export type ImgixSupportedResizeFitMode =
  | 'clamp'
  | 'clip'
  | 'crop'
  | 'facearea'
  | 'fill'
  | 'fillmax'
  | 'max'
  | 'min'
  | 'scale';

interface CreateImgixImageUrlParams {
  imageSrc: string;
  width: number;
  height: number;
  format: ImgixSupportedExportFormat;
  fileName?: string;
  fit?: ImgixSupportedResizeFitMode;
  auto?: 'auto';
}

/**
 *
 * @description By passing in an image's height, width, extension (i.e. format), along with its source, we use imgix's buildUrl function to create an image url. If you'd like to use a different fit than the default, you can also pass one in
 * @returns {string} an image url for the newly formatted image
 * @see https://docs.imgix.com/libraries/react-imgix#general-advanced-usage
 */
export const createImgixImageUrl = ({
  auto,
  fileName,
  fit = 'crop',
  format,
  height,
  imageSrc,
  width,
}: CreateImgixImageUrlParams) => {
  const baseParams = { w: width, h: height, fm: format, fit };
  const options = { disableLibraryParam: true, src: imageSrc };

  const paramsWithAuto = !!auto ? { ...baseParams, auto } : baseParams;
  const paramsWithFileName = !!fileName ? { ...paramsWithAuto, dl: fileName } : paramsWithAuto;

  return buildURL(imageSrc, paramsWithFileName, options);
};
