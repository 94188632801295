import { Pause as PauseIcon, PlayFilled as PlayIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

import { PLAY_PAUSE_BUTTON } from '~/constants/testIDs';

interface PlayPauseButtonProps {
  onPlayPause: () => void;
  isPlaying: boolean;
}
export const PlayPauseButton = memo(({ onPlayPause, isPlaying }: PlayPauseButtonProps) => {
  return (
    <IconButton
      appearance="ghost"
      color="grey"
      className="ml-1 mr-0 md:mr-2"
      data-testid={PLAY_PAUSE_BUTTON}
      label={isPlaying ? 'Pause' : 'Play'}
      onClick={onPlayPause}
      size="large"
    >
      {isPlaying ? <PauseIcon className="h-6 w-6" /> : <PlayIcon className="h-6 w-6" />}
    </IconButton>
  );
});

PlayPauseButton.displayName = 'PlayPauseButton';
