import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, memo } from 'react';

type InfoPanelDetailHeaderProps = ComponentProps<'h3'>;

export const InfoPanelDetailTitle = memo(({ className, ...props }: InfoPanelDetailHeaderProps) => (
  <h3 className={tailwindMerge('line-clamp-2 text-14 text-grey-12', className)} {...props} />
));

InfoPanelDetailTitle.displayName = 'InfoPanelDetailTitle';
