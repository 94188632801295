import { memo } from 'react';

import FixedWidthText from '~/components/FixedWidthText';
import { TIME_ELAPSED_CLOCK } from '~/constants/testIDs';

export interface TimeElapsedClockProps {
  elapsedText: string;
  durationText: string;
  maxText: string;
}
export const TimeElapsedClock = memo(({ elapsedText, durationText, maxText }: TimeElapsedClockProps) => {
  return (
    <div className="flex items-center" data-testid={TIME_ELAPSED_CLOCK}>
      <FixedWidthText
        data-testid="CURRENT_VIDEO_TIME"
        className="text-right text-14 text-grey-12"
        maxText={maxText}
        text={elapsedText}
      />
      <div className="px-1 text-14 font-medium text-grey-10">/</div>
      <FixedWidthText
        data-testid="VIDEO_TIME_LEFT"
        className="text-14 font-medium text-grey-9"
        maxText={maxText}
        text={durationText}
      />
    </div>
  );
});

TimeElapsedClock.displayName = 'TimeElapsedClock';
