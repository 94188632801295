import { ArrowLeft, ArrowRight } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { useBreakpointsContext } from '@air/provider-media-query';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo } from 'react';

import {
  ASSET_MODAL_NEXT,
  ASSET_MODAL_NEXT_DISABLED,
  ASSET_MODAL_PREVIOUS,
  ASSET_MODAL_PREVIOUS_DISABLED,
} from '~/constants/testIDs';

interface PrivateAndNextAssetModalArrowsProps {
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  className?: string;
}

export const PreviousAndNextAssetModalArrows = memo(
  ({ onNextClick, onPreviousClick, className }: PrivateAndNextAssetModalArrowsProps) => {
    const { isAboveMediumScreen } = useBreakpointsContext();

    const hasPrevious = !!onPreviousClick;
    const hasNext = !!onNextClick;

    return (
      <div
        className={tailwindMerge(
          'pointer-events-none flex w-full shrink-0 items-center justify-start bg-black p-1 md:fixed md:bottom-0 md:left-0 md:bg-transparent md:px-4 md:pb-3 md:pt-0',
          className,
        )}
      >
        <IconButton
          appearance="ghost"
          color="grey"
          disabled={!hasPrevious}
          size={isAboveMediumScreen ? 'large' : 'medium'}
          onClick={onPreviousClick}
          className="pointer-events-auto mr-auto disabled:bg-transparent disabled:text-grey-8"
          label="Previous asset"
          data-testid={hasPrevious ? ASSET_MODAL_PREVIOUS : ASSET_MODAL_PREVIOUS_DISABLED}
        >
          <ArrowLeft className="h-4 w-4 md:h-6 md:w-6" />
        </IconButton>
        <IconButton
          appearance="ghost"
          color="grey"
          disabled={!hasNext}
          size={isAboveMediumScreen ? 'large' : 'medium'}
          onClick={onNextClick}
          data-testid={hasNext ? ASSET_MODAL_NEXT : ASSET_MODAL_NEXT_DISABLED}
          className="pointer-events-auto ml-auto disabled:bg-transparent  disabled:text-grey-8"
          label="Next asset"
        >
          <ArrowRight className="h-4 w-4 md:h-6 md:w-6" />
        </IconButton>
      </div>
    );
  },
);

PreviousAndNextAssetModalArrows.displayName = 'PreviousAndNextAssetModalArrows';
