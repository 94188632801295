import styled from 'styled-components';

export const SeekBarContainer = styled.div`
  input[type='range'] {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: '100%';
    background-color: transparent;
  }

  input[type='range']::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: '100%';
    background-color: transparent;
    border: transparent;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    box-shadow:
      0px 0px 0px transparent,
      0px 0px 0px transparent;
    background: transparent;
    border-radius: 0px;
    border: 0px solid transparent;
    color: var(--colors-grey7);
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    cursor: pointer;
    box-shadow:
      0px 0px 0px transparent,
      0px 0px 0px transparent;
    background: var(--colors-grey7);
    border-radius: 2px;
    border: 0px solid transparent;
    height: 4px;
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: transparent;
    border: 0px solid transparent;
    border-radius: 0px;
    box-shadow:
      0px 0px 0px transparent,
      0px 0px 0px transparent;
  }

  input[type='range']::-ms-fill-upper {
    background: transparent;
    border: 0px solid transparent;
    border-radius: 0px;
    box-shadow:
      0px 0px 0px transparent,
      0px 0px 0px transparent;
  }

  input[type='range']:focus::-ms-fill-lower {
    background: transparent;
  }

  input[type='range']:focus::-ms-fill-upper {
    background: transparent;
  }

  input[type='range']::-ms-tooltip {
    display: none;
  }

  input[type='range']::-moz-range-progress {
    appearance: none;
    background-color: var(--colors-teal9);
    border-radius: 2px;
    box-shadow: none;
    border: none;
    z-index: 10;
    height: 4px;
    &:hover {
      cursor: pointer;
    }
  }
`;
