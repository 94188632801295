import { useTrackAnnotationSizeChanged } from '@air/analytics';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

import { annotationSizes } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/shared/constants';
import { ANNOTATION_SIZE_SELECT } from '~/constants/testIDs';

export interface AnnotationSizeSelectProps {
  selectedSize: number;
  setSize: (size: number) => void;
}

export const AnnotationSizeSelect = memo(({ selectedSize, setSize }: AnnotationSizeSelectProps) => {
  const { trackAnnotationSizeChanged } = useTrackAnnotationSizeChanged();

  return (
    <div className="mx-2 flex gap-0.5 py-2">
      {annotationSizes.map((size) => (
        <IconButton
          data-testid={`${ANNOTATION_SIZE_SELECT}-${size.width}`}
          appearance={selectedSize === size.width ? 'filled' : 'ghost'}
          color="grey"
          key={size.width}
          size="small"
          icon={size.icon}
          onClick={(event) => {
            event.stopPropagation();
            trackAnnotationSizeChanged({ size: size.width });
            setSize(size.width);
          }}
          label={`Change size to ${size.width}`}
        />
      ))}
    </div>
  );
});

AnnotationSizeSelect.displayName = 'AnnotationSizeSelect';
