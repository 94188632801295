import { memo, PropsWithChildren } from 'react';

import { IconType } from '~/types/icons';

export interface KeyShortcutProps {
  icon: IconType;
  text: string;
}

export const KeyShortcut = memo(({ icon: Icon, text, children }: PropsWithChildren<KeyShortcutProps>) => (
  <div className="flex items-center justify-between py-2">
    <div className="flex items-center">
      {!!Icon && <Icon className="h-4 w-4 text-grey-10" />}
      <div className="ml-2 text-14 text-grey-11">{text}</div>
    </div>
    <div className="flex [&>div]:ml-0.5">{children}</div>
  </div>
));

KeyShortcut.displayName = 'KeyShortcut';
