import { Discussions } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { ListDiscussionInput } from '@air/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import { useMemo } from 'react';

import { ASSET_MODAL_DISCUSSION_FILTERS } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { getDiscussionsKey } from '~/constants/react-query-keys';
import { TEN_SECONDS } from '~/constants/unitsOfTime';
import { convertDiscussionsToClipDiscussions } from '~/swr-hooks/discussions/utils';

export interface UsePrivateDiscussionsParams {
  boardId?: Board['id'];
  assetId: Clip['assetId'];
  clipId?: Clip['id'];
  discussionsFilter: ASSET_MODAL_DISCUSSION_FILTERS;
}

export const usePrivateDiscussions = ({ boardId, assetId, clipId, discussionsFilter }: UsePrivateDiscussionsParams) => {
  const queryKey = getDiscussionsKey({ boardId, assetId, clipId, discussionsFilter });

  let resolved: boolean | undefined = undefined;
  if (discussionsFilter === ASSET_MODAL_DISCUSSION_FILTERS.RESOLVED) {
    resolved = true;
  } else if (discussionsFilter === ASSET_MODAL_DISCUSSION_FILTERS.OPENED) {
    resolved = false;
  }

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => {
      const dicussionsListParams: ListDiscussionInput = {
        boardId,
        assetId,
        clipId,
        discussionLimit: 100,
        commentLimit: 100,
        cursor: pageParam || undefined,
        sortBy: 'mostRecentFirstComment',
        type: 'clip',
      };

      if (!isUndefined(resolved)) {
        dicussionsListParams.resolved = resolved;
      }

      const discussions = await Discussions.list(dicussionsListParams);
      return {
        ...discussions,
        // backend does not return correctly typed discussions, so just ensure TypeScript that we know its type
        data: convertDiscussionsToClipDiscussions(discussions.data),
      };
    },
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor || null,
    /** Revalidates every 10s */
    refetchInterval: TEN_SECONDS,
    initialPageParam: '',
  });

  const discussions = useMemo(() => data?.pages.flatMap((page) => page.data) ?? [], [data]);

  return {
    data: discussions,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    total: data?.pages[0]?.total ?? 0,
  };
};
