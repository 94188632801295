import { DrawRect, PercentageRect } from '~/components/BoundingBox/types';

export const getPercentageRectMaxMinsCoords = (boundingBox: PercentageRect) => {
  const minX = Math.min(boundingBox.pctStartX, boundingBox.pctEndX);
  const maxX = Math.max(boundingBox.pctStartX, boundingBox.pctEndX);
  const maxY = Math.max(boundingBox.pctStartY, boundingBox.pctEndY);
  const minY = Math.min(boundingBox.pctStartY, boundingBox.pctEndY);

  return {
    minX,
    maxX,
    maxY,
    minY,
  };
};

export const getDrawRectMaxMinsCoords = (drawRect: DrawRect) => {
  const minX = Math.min(drawRect.startX, drawRect.endX);
  const maxX = Math.max(drawRect.startX, drawRect.endX);
  const maxY = Math.max(drawRect.startY, drawRect.endY);
  const minY = Math.min(drawRect.startY, drawRect.endY);

  return {
    minX,
    maxX,
    maxY,
    minY,
  };
};
