import { TriangleDown } from '@air/next-icons';
import { memo } from 'react';

import {
  AnnotationColorSelect,
  AnnotationColorSelectProps,
} from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/PencilUtils/SizeAndColorSelector/components/AnnotationColorSelect';
import {
  AnnotationSizeSelect,
  AnnotationSizeSelectProps,
} from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/PencilUtils/SizeAndColorSelector/components/AnnotationSizeSelect';

export interface SizeAndColorSelectorProps extends AnnotationColorSelectProps, AnnotationSizeSelectProps {}

export const SizeAndColorSelect = memo(
  ({ selectedSize, selectedColor, setSize, setColor }: SizeAndColorSelectorProps) => {
    return (
      <div className="flex flex-col items-center">
        <div className="flex items-center rounded-sm bg-white px-1 shadow-popover">
          <AnnotationColorSelect setColor={setColor} selectedColor={selectedColor} />
          <div className="h-10 w-px bg-pigeon-50" />
          <AnnotationSizeSelect selectedSize={selectedSize} setSize={setSize} />
        </div>
        <TriangleDown className="-mt-4 w-8 text-white" />
      </div>
    );
  },
);

SizeAndColorSelect.displayName = 'SizeAndColorSelector';
