import { AssetCustomFieldValue, CustomFieldTypeName } from '@air/api/types';
import { Skeleton } from '@air/component-skeleton';
import { ComponentType, memo } from 'react';

import { EmptyAssetCustomFields } from '~/components/RightPanel/CustomFields/EmptyAssetCustomFields';

interface AssetCustomFieldsPanelContentProps {
  fields: AssetCustomFieldValue[] | undefined;
  SelectCustomField: ComponentType<{ field: AssetCustomFieldValue }>;
  PlainTextCustomField: ComponentType<{ field: AssetCustomFieldValue }>;
  DateCustomField: ComponentType<{ field: AssetCustomFieldValue }>;
}

export const AssetCustomFieldsPanelContent = memo(
  ({ fields, SelectCustomField, PlainTextCustomField, DateCustomField }: AssetCustomFieldsPanelContentProps) => {
    if (!fields) {
      return <Skeleton height={20} width={200} />;
    }

    if (fields.length === 0) {
      return <EmptyAssetCustomFields />;
    }

    return (
      <>
        {fields.map((field) => {
          switch (field.type.title) {
            case CustomFieldTypeName.singleSelect:
            case CustomFieldTypeName.multiSelect: {
              return <SelectCustomField field={field} key={field.id} />;
            }
            case CustomFieldTypeName.plainText: {
              return <PlainTextCustomField field={field} key={field.id} />;
            }
            case CustomFieldTypeName.date:
              return <DateCustomField field={field} key={field.id} />;
          }
        })}
      </>
    );
  },
);

AssetCustomFieldsPanelContent.displayName = 'AssetCustomFieldsPanelContent';
