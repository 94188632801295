export interface AirCanvasRect {
  w: number;
  h: number;
  x: number;
  y: number;
}

export const drawRoundedRect = (ctx: CanvasRenderingContext2D, { w, y, x, h }: AirCanvasRect) => {
  const cornerRadius = 4;

  // Depending on the direction the cursor is moving, we have to draw the rounded corners differently.
  // This is not the nicest set of calls to read, but hopefully no one ever has to touch this after its been
  // deployed.
  const isNegativeWidth = w < 0;
  const isNegativeHeight = h < 0;

  if (isNegativeWidth) {
    ctx.moveTo(x - cornerRadius, y);
    ctx.lineTo(x + w + cornerRadius, y);
  } else {
    ctx.moveTo(x + cornerRadius, y);
    ctx.lineTo(x + w - cornerRadius, y);
  }

  if (isNegativeHeight) {
    ctx.quadraticCurveTo(x + w, y, x + w, y - cornerRadius);
    ctx.lineTo(x + w, y + h + cornerRadius);
  } else {
    ctx.quadraticCurveTo(x + w, y, x + w, y + cornerRadius);
    ctx.lineTo(x + w, y + h - cornerRadius);
  }

  if (isNegativeWidth) {
    ctx.quadraticCurveTo(x + w, y + h, x + w + cornerRadius, y + h);
    ctx.lineTo(x - cornerRadius, y + h);
  } else {
    ctx.quadraticCurveTo(x + w, y + h, x + w - cornerRadius, y + h);
    ctx.lineTo(x + cornerRadius, y + h);
  }

  if (isNegativeHeight) {
    ctx.quadraticCurveTo(x, y + h, x, y + h + cornerRadius);
    ctx.lineTo(x, y - cornerRadius);
  } else {
    ctx.quadraticCurveTo(x, y + h, x, y + h - cornerRadius);
    ctx.lineTo(x, y + cornerRadius);
  }

  if (isNegativeWidth) {
    ctx.quadraticCurveTo(x, y, x - cornerRadius, y);
  } else {
    ctx.quadraticCurveTo(x, y, x + cornerRadius, y);
  }
};
