import { Pencil, Resolve, Trash } from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';

interface GetDiscussionCommentMenuOptionsParams {
  onDelete?: () => void;
  onEdit?: () => void;
  onResolve?: () => void;
}

export const getDiscussionCommentMenuOptions = ({
  onDelete,
  onEdit,
  onResolve,
}: GetDiscussionCommentMenuOptionsParams): DropdownMenuOption[] => {
  const options: DropdownMenuOption[] = [];

  if (onResolve) {
    options.push({
      id: 'resolve',
      label: 'Resolve',
      onSelect: onResolve,
      prefix: <Resolve className="h-4 w-4" />,
      type: 'item',
    });
  }

  if (onEdit) {
    options.push({
      id: 'edit',
      label: 'Edit',
      onSelect: onEdit,
      prefix: <Pencil className="h-4 w-4" />,
      type: 'item',
    });
  }

  if (onDelete) {
    options.push({
      id: 'delete',
      label: 'Delete comment',
      onSelect: onDelete,
      prefix: <Trash className="h-4 w-4" />,
      type: 'item',
    });
  }

  return options;
};
