export interface DiscussionVersionNumberProps {
  onVersionClick?: () => void;
  discussionVersion: number;
}

export const DiscussionVersionNumber = ({ onVersionClick, discussionVersion }: DiscussionVersionNumberProps) => {
  const versionText = `V${discussionVersion}`;

  if (!discussionVersion) {
    return null;
  }

  return !onVersionClick ? (
    <div className="text-12 text-grey-10">{versionText}</div>
  ) : (
    <button className="text-12 text-grey-10 hover:underline" onClick={onVersionClick}>
      {versionText}
    </button>
  );
};
