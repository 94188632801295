import { Point } from '~/components/BoundingBox/types';

export const drawStartingPoint = ({
  point,
  ctx,
  lineSize,
  lineColor,
}: {
  lineSize: number;
  ctx: CanvasRenderingContext2D;
  point: Point;
  lineColor: string;
}) => {
  ctx.fillStyle = lineColor;

  ctx.beginPath();
  ctx.arc(point.x, point.y, Math.ceil(lineSize / 2), 0, 2 * Math.PI, true);
  ctx.closePath();
  ctx.fill();

  ctx.beginPath();
  ctx.moveTo(point.x, point.y);
};
