import { DiscussionLocation, useTrackDeletedComment } from '@air/analytics';
import { Annotation, Clip, CommentResponse } from '@air/api/types';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getAnnotationAnalyticsType, isAnnotationCorrect } from '~/components/Annotations/shared/annotationUtils';
import { useUpdateStateAfterAddingOrRemovingDiscussion } from '~/components/Discussions/utils';
import { useURLShortIdSelector } from '~/hooks/useURLShortIdSelector';
import {
  centralizedClipAssetIdSelector,
  centralizedClipSelector,
  centralizedClipTypeSelector,
} from '~/store/centralizedClip/selectors';
import { useDeleteComment } from '~/swr-hooks/discussions/useDeleteComment';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useDeleteCommentClick = ({ trackLocation }: { trackLocation: DiscussionLocation }) => {
  const store = useAirStore();
  const { deleteComment } = useDeleteComment();
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const shortId = useURLShortIdSelector();
  const { trackDeletedComment } = useTrackDeletedComment();

  const { updateStateAfterAddingOrRemovingDiscussion } = useUpdateStateAfterAddingOrRemovingDiscussion();

  const handleCommentDeleteClick = useCallback(
    ({
      comment,
      clipId,
      timestamp,
      annotation,
    }: {
      comment: CommentResponse;
      clipId: Clip['id'];
      timestamp?: number;
      annotation?: Annotation;
    }) => {
      const boardId = getBoardIdFromPath(window.location.pathname);
      const { openCommentCount, openDiscussionCount } = centralizedClipSelector(store.getState());

      trackDeletedComment({
        boardId,
        clipId,
        location: trackLocation,
        public: trackLocation === 'public-board',
        has_timestamp: !isUndefined(timestamp),
        has_annotation: isAnnotationCorrect(annotation),
        annotationType: getAnnotationAnalyticsType(annotation),
        assetType: centralizedClipTypeSelector(store.getState()),
      });

      const decrementedCommentCount = openCommentCount ? openCommentCount - 1 : 0;
      const decrementedDiscussionCount = openDiscussionCount ? openDiscussionCount - 1 : 0;

      deleteComment({
        existingComment: comment,
        boardId,
        shortId,
        assetId,
      });
      updateStateAfterAddingOrRemovingDiscussion({
        newCommentCount: decrementedCommentCount,
        newDiscussionCount: decrementedDiscussionCount,
        boardId,
        clipId,
      });
    },
    [
      store,
      trackDeletedComment,
      trackLocation,
      deleteComment,
      assetId,
      shortId,
      updateStateAfterAddingOrRemovingDiscussion,
    ],
  );

  return {
    handleCommentDeleteClick,
  };
};
