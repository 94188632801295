import { memo } from 'react';

import { ASSET_MODAL_KEYWORD } from '~/constants/testIDs';

export interface InfoPanelKeywordProps {
  label: string;
  onClick?: (label: string) => void;
}

export const InfoPanelKeyword = memo(({ label, onClick }: InfoPanelKeywordProps) => (
  <button
    className="flex h-6 shrink-0 items-center break-words rounded-full border border-grey-5 bg-grey-1 px-2 text-12 font-medium text-grey-9"
    data-testid={`${ASSET_MODAL_KEYWORD}-${label}`}
    onClick={onClick ? () => onClick?.(label) : undefined}
  >
    {label}
  </button>
));

InfoPanelKeyword.displayName = 'InfoPanelKeyword';
