import { LineweightMedium } from '@air/next-icons';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SizeAndColorSelect } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/PencilUtils/SizeAndColorSelector/SizeAndColorSelect';
import {
  AnnotationIconButton,
  AnnotationIconButtonProps,
} from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/shared/AnnotationIconButton';
import { annotationSizes } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/shared/constants';
import { DRAW_ANNOTATION_BUTTON } from '~/constants/testIDs';
import { PopperOptions, usePopperContainer } from '~/hooks/usePopperContainer';
import { useAnnotationContext, useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import {
  annotationColorSelector,
  annotationSizeSelector,
  annotationTypeSelector,
} from '~/providers/AnnotationProvider/annotationProviderUtils';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';

const PENCIL_COLORS = [
  {
    className: 'bg-cockatoo-200/15 text-cockatoo-200 hover:bg-cockatoo-200/15 hover:text-cockatoo-200',
    id: 'cockatoo200',
  },
  { className: 'bg-cuckoo-600/15 text-cuckoo-600 hover:bg-cuckoo-600/15 hover:text-cuckoo-600', id: 'cuckoo600' },
  { className: 'bg-peacock-400/15 text-peacock-400 hover:bg-peacock-400/15 hover:text-peacock-400', id: 'peacock400' },
  { className: 'bg-canary-300/15 text-canary-300 hover:bg-canary-300/15 hover:text-canary-300', id: 'canary300' },
  {
    className: 'bg-flamingo-400/15 text-flamingo-400 hover:bg-flamingo-400/15 hover:text-flamingo-400',
    id: 'flamingo400',
  },
];

const popperOptions: PopperOptions = {
  placement: 'top-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: ({ popper }: { popper: { width: number } }) => {
          return [-popper.width / 2 + 12, -6];
        },
      },
    },
  ],
};

export const PencilAnnotationUtils = memo(() => {
  const { setAnnotationType, setAnnotationColor, setAnnotationSize } = useAnnotationContext();
  const annotationType = useAnnotationContextSelector(annotationTypeSelector);
  const annotationColor = useAnnotationContextSelector(annotationColorSelector);
  const annotationSize = useAnnotationContextSelector(annotationSizeSelector);

  const isActive = annotationType === 'pencil';

  const [isSelectorVisible, setIsSelectorVisible] = useState(isActive);

  const containerRef = useRef(null);

  const { panel } = useAssetModalPanelContext();

  const setPencilAnnotationType = useCallback(() => setAnnotationType('pencil'), [setAnnotationType]);

  const hideSelector = useCallback(() => {
    setIsSelectorVisible(false);
  }, []);

  useEffect(() => {
    if (isSelectorVisible) {
      window.addEventListener('click', hideSelector);
    }

    return () => {
      window.removeEventListener('click', hideSelector);
    };
  }, [hideSelector, isSelectorVisible]);

  const { PopperContainer, popperInstance } = usePopperContainer({
    containerRef,
    options: popperOptions,
    usePortal: true,
  });

  const isAnyTabOpen = useMemo(() => panel !== AssetModalPanel.NONE, [panel]);

  useEffect(() => {
    // update popper's position when tab appears or disappears
    // timeout is necessary to wait for DOM changes
    setTimeout(() => popperInstance.current?.update(), 1);
  }, [isAnyTabOpen, popperInstance]);

  const onPencilBtnClick: Required<AnnotationIconButtonProps>['onClick'] = useCallback(
    (event) => {
      event.stopPropagation();
      setIsSelectorVisible((isVisible) => !isVisible);
      setPencilAnnotationType();
    },
    [setPencilAnnotationType],
  );

  const AnnotationSizeIcon = annotationSizes.find(({ width }) => width === annotationSize)?.icon ?? LineweightMedium;
  const pencilColor = PENCIL_COLORS.find(({ id }) => id === annotationColor);

  return (
    <div className="flex items-center">
      <AnnotationIconButton
        className={isActive ? pencilColor?.className : undefined}
        data-testid={DRAW_ANNOTATION_BUTTON}
        label="Draw annotation"
        onClick={onPencilBtnClick}
        ref={containerRef}
      >
        <AnnotationSizeIcon className="h-4 w-4" />
      </AnnotationIconButton>
      {isSelectorVisible && (
        <>
          <PopperContainer>
            <SizeAndColorSelect
              selectedColor={annotationColor}
              setColor={setAnnotationColor}
              selectedSize={annotationSize}
              setSize={setAnnotationSize}
            />
          </PopperContainer>
        </>
      )}
    </div>
  );
});

PencilAnnotationUtils.displayName = 'PencilAnnotationUtils';
