import { useTrackAnnotationColorChanged } from '@air/analytics';
import { pink, purple, red, teal, yellow } from '@air/colors';
import { Checkbox } from '@air/primitive-checkbox';
import classNames from 'classnames';
import { memo } from 'react';

import { ANNOTATION_COLOR_CHECKBOX } from '~/constants/testIDs';

export const ANNOTATION_COLORS: { className: string; id: string; hex: string }[] = [
  { className: 'bg-pink-5 radix-state-checked:bg-pink-5', id: 'pink-5', hex: pink.pink5 },
  { className: 'bg-purple-8 radix-state-checked:bg-purple-8', id: 'purple-8', hex: purple.purple8 },
  { className: 'bg-teal-7 radix-state-checked:bg-peacock-400', id: 'teal-7', hex: teal.teal7 },
  { className: 'bg-yellow-5 radix-state-checked:bg-yellow-5', id: 'yellow-5', hex: yellow.yellow5 },
  { className: 'bg-red-8 radix-state-checked:bg-red-8', id: 'red-8', hex: red.red8 },
];

export interface AnnotationColorSelectProps {
  selectedColor: string;
  setColor: (color: string) => void;
}

export const AnnotationColorSelect = memo(({ selectedColor, setColor }: AnnotationColorSelectProps) => {
  const { trackAnnotationColorChanged } = useTrackAnnotationColorChanged();

  return (
    <div className="mr-1 flex items-center gap-1 py-2">
      {ANNOTATION_COLORS.map((color) => (
        <Checkbox
          aria-label={`Annotate using ${color.id} color`}
          checked={selectedColor === color.hex}
          className={classNames('border-none', color.className)}
          data-color={color}
          data-testid={`${ANNOTATION_COLOR_CHECKBOX}-${color}`}
          key={color.id}
          onCheckedChange={() => {
            trackAnnotationColorChanged({ color: color.id });
            setColor(color.hex);
          }}
          size="extra-large"
        />
      ))}
    </div>
  );
});

AnnotationColorSelect.displayName = 'AnnotationColorSelect';
