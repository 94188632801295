import { useTrackClickedAssetModalTab } from '@air/analytics';
import { Fields } from '@air/next-icons';
import { memo } from 'react';

import { PanelTabButton } from '~/components/AssetModal/Panel/Tabs/components/PanelTabButton';
import { _TAB } from '~/constants/testIDs';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';

export const CustomFieldsTabButton = memo(() => {
  const { panel, togglePanel } = useAssetModalPanelContext();
  const { trackClickedAssetModalTab } = useTrackClickedAssetModalTab();

  return (
    <PanelTabButton
      isSelected={panel === AssetModalPanel.FIELDS}
      label="Fields"
      testId={`FIELDS${_TAB}`}
      onClick={(event) => {
        event.stopPropagation();
        togglePanel(AssetModalPanel.FIELDS);
        trackClickedAssetModalTab({
          tab: AssetModalPanel.FIELDS,
          panelAction: panel ? (panel === AssetModalPanel.FIELDS ? 'close' : 'switch') : 'open',
        });
      }}
      icon={<Fields className="h-6 w-6" />}
    />
  );
});

CustomFieldsTabButton.displayName = 'CustomFieldsTabButton';
