import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AnnotationAvatarsFetcherProps } from '~/components/Annotations/AnnotationsAvatarsContainer/shared/annotationAvatarsTypes';
import { useActiveDiscussionIdObserver } from '~/components/Annotations/shared/useActiveDiscussionIdObserver';
import { useDiscussionPanelContextSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import {
  discussionsFilterSelector,
  discussionsVersionFilterSelector,
} from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextUtils';
import { useCurrentClipPermissions } from '~/components/PrivateAssetModal/hooks/useCurrentClipPermissions';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';
import { centralizedClipAssetIdSelector, CentralizedClipSelectors } from '~/store/centralizedClip/selectors';
import { usePrivateDiscussions } from '~/swr-hooks/discussions/usePrivateDiscussions';
import { canEditAssetDiscussions } from '~/utils/permissions/assetPermissions';
import { canViewSubscription } from '~/utils/permissions/workspacePermissions';

export const PrivateAnnotationsAvatars = memo(({ renderAnnotations }: AnnotationAvatarsFetcherProps) => {
  const boardId = useSelector(CentralizedBoardSelectors.boardId);
  const { boardPermissions } = useCurrentBoardPermissions();
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const clipId = useSelector(CentralizedClipSelectors.clipId);

  const discussionsFilter = useDiscussionPanelContextSelector(discussionsFilterSelector);
  const discussionsVersionFilter = useDiscussionPanelContextSelector(discussionsVersionFilterSelector);
  const { data: discussions } = usePrivateDiscussions({
    boardId,
    assetId,
    clipId: discussionsVersionFilter === 'CURRENT' ? clipId : undefined,
    discussionsFilter,
  });

  const { clipPermissions } = useCurrentClipPermissions();

  useActiveDiscussionIdObserver(discussions);

  const { data: subscription } = useSubscriptionContext();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const canSeeSubscription = canViewSubscription(workspacePermissions);
  const canEditDiscussions = canEditAssetDiscussions(boardId ? boardPermissions : clipPermissions);

  const hasActiveSubscription = !canSeeSubscription || !!(subscription && subscription.hasActiveSubscription);

  const renderedAnnotations = useMemo(
    () =>
      renderAnnotations({
        discussions,
        canDelete: hasActiveSubscription && canEditDiscussions,
        canEdit: hasActiveSubscription && canEditDiscussions,
        canResolve: hasActiveSubscription && canEditDiscussions,
        canReply: hasActiveSubscription && canEditDiscussions,
        canShowMentionsButton: true,
      }),
    [canEditDiscussions, discussions, hasActiveSubscription, renderAnnotations],
  );

  return <>{renderedAnnotations}</>;
});

PrivateAnnotationsAvatars.displayName = 'PrivateAnnotationsAvatars';
