import { animated, useSpring } from '@react-spring/web';
import { memo, ReactNode } from 'react';

import { Tabs } from './Tabs/Tabs';

export interface PanelProps {
  tabsBottomPadding?: number;
  tabs: ReactNode;
  panel?: ReactNode;
}

export const Panel = memo(({ tabsBottomPadding = 20, tabs, panel }: PanelProps) => {
  const [props] = useSpring(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      delay: 150,
    }),
    [panel],
  );

  return (
    <div className="flex h-full w-auto min-w-[72px] items-start justify-start overflow-hidden border-l border-l-grey-5">
      {/* PANEL TAB CONTENT */}
      {!!panel && (
        <animated.div className="h-full opacity-0 sm:w-[328px] 2xl:w-[350px]" style={props}>
          {panel}
        </animated.div>
      )}

      {/* PANEL TAB SWITCHER */}
      <Tabs style={{ paddingBottom: tabsBottomPadding }}>{tabs}</Tabs>
    </div>
  );
});

Panel.displayName = 'Panel';
