import { constant, noop } from 'lodash';

export const appStorage = (() => {
  if (typeof window === 'undefined') {
    return {
      getItem: constant(null),
      setItem: noop,
      removeItem: noop,
    };
  }
  return localStorage;
})();
