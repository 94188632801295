import { Account } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import { tailwindMerge } from '@air/tailwind-variants';
import { formatFullName, formatInitials } from '@air/utilities';
import { memo, useMemo } from 'react';

import { BoundingBoxAvatarDirection } from '~/components/BoundingBox/types';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

export interface BoundingBoxAvatarProps {
  account?: Pick<Account, 'id' | 'firstName' | 'lastName' | 'avatar'> | null;
  /** on which side the bottom corner is not rounded */
  direction: BoundingBoxAvatarDirection;
  className?: string;
}

export const BOUNDING_BOX_AVATAR_WIDTH = 24;
// 10 is: 4px padding  + 1px border on left and right
export const BOUNDING_BOX_AVATAR_SIZE = BOUNDING_BOX_AVATAR_WIDTH + 10;

export const BoundingBoxAvatar = memo(({ account, direction, className }: BoundingBoxAvatarProps) => {
  const fullName = account ? formatFullName(account.firstName, account.lastName) : '';
  const initials = account ? formatInitials(account.firstName, account.lastName) : '';

  const borderRadiusStyle = useMemo(() => {
    const borderRadius = {
      borderBottomLeftRadius: 30,
      borderTopRightRadius: 30,
      borderTopLeftRadius: 30,
      borderBottomRightRadius: 30,
    };

    switch (direction) {
      case 'top-left':
        borderRadius.borderTopLeftRadius = 2;
        break;
      case 'top-right':
        borderRadius.borderTopRightRadius = 2;
        break;
      case 'bottom-left':
        borderRadius.borderBottomLeftRadius = 2;
        break;
      case 'bottom-right':
        borderRadius.borderBottomRightRadius = 2;
        break;
    }

    return borderRadius;
  }, [direction]);

  return (
    <div
      className={tailwindMerge(
        'light:border-grey-1 light:bg-grey-3 pointer-events-none border p-1 shadow-popover dark:border-grey-12 dark:bg-grey-12',
        className,
      )}
      style={{
        width: BOUNDING_BOX_AVATAR_SIZE,
        height: BOUNDING_BOX_AVATAR_SIZE,
        ...borderRadiusStyle,
      }}
    >
      <Avatar
        alt={fullName}
        size={BOUNDING_BOX_AVATAR_WIDTH}
        src={getAvatarImageSrc(account?.avatar, BOUNDING_BOX_AVATAR_WIDTH)}
        text={initials}
        colorSeed={account?.id}
      />
    </div>
  );
});

BoundingBoxAvatar.displayName = 'BoundingBoxAvatar';
