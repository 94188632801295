import { useTrackCommentModeToggledOff, useTrackCommentModeToggledOn } from '@air/analytics';
import { useCallback } from 'react';

import { useAnnotationContext, useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import { isAnnotationsEnabledSelector } from '~/providers/AnnotationProvider/annotationProviderUtils';

export const useAnnotationsToggle = () => {
  const isAnnotationsEnabled = useAnnotationContextSelector(isAnnotationsEnabledSelector);
  const { setAnnotationsEnabled } = useAnnotationContext();
  const { trackCommentModeToggledOff } = useTrackCommentModeToggledOff();
  const { trackCommentModeToggledOn } = useTrackCommentModeToggledOn();

  const showAnnotations = useCallback(() => {
    trackCommentModeToggledOn();
    setAnnotationsEnabled(true);
  }, [setAnnotationsEnabled, trackCommentModeToggledOn]);

  const hideAnnotations = useCallback(() => {
    trackCommentModeToggledOff();
    setAnnotationsEnabled(false);
  }, [setAnnotationsEnabled, trackCommentModeToggledOff]);

  return {
    isAnnotationsEnabled,
    showAnnotations,
    hideAnnotations,
  };
};
