import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentProps, type ReactNode } from 'react';

export type DiscussionsCommentProps = ComponentProps<'article'> & {
  actions?: ReactNode;
  avatar?: ReactNode;
  author: ReactNode;
  comment: ReactNode;
  meta?: ReactNode;
  secondaryActions?: ReactNode;
  timestamp?: string;
};

export const DiscussionsComment = ({
  actions,
  author,
  avatar,
  className,
  comment,
  meta,
  secondaryActions,
  timestamp,
  ...restOfProps
}: DiscussionsCommentProps) => {
  return (
    <article
      className={tailwindMerge('group/discussions-comment flex w-full items-start gap-3 p-5', className)}
      data-testid="DISCUSSIONS_COMMENT"
      {...restOfProps}
    >
      {avatar}
      <div className="flex grow flex-col gap-1">
        <header className="flex items-center justify-between gap-1">
          <div className="flex items-center gap-1">
            <div
              className="shrink-0 truncate text-14 font-medium text-grey-12"
              data-testid="DISCUSSIONS_COMMENT_AUTHOR"
            >
              {author}
            </div>
            {meta && <div className="shrink-0 text-12 text-grey-10">{meta}</div>}
            {!!timestamp && (
              <>
                &middot;
                <time
                  className="shrink-0 text-12 text-grey-10"
                  data-testid="DISCUSSIONS_COMMENT_TIMESTAMP"
                  dateTime={timestamp}
                >
                  {timestamp}
                </time>
              </>
            )}
          </div>

          {actions && (
            <div className="shrink-0 gap-1 text-12 text-grey-10 opacity-0 group-hover/discussions-comment:opacity-100">
              {actions}
            </div>
          )}
        </header>

        <section className="text-14 text-grey-11">{comment}</section>

        {secondaryActions && <div className="flex gap-2">{secondaryActions}</div>}
      </div>
    </article>
  );
};
