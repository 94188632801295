import { Images, Refresh } from '@air/next-icons';
import { memo } from 'react';

import { ASSET_MODAL_PROCESSING_TEXT } from '~/constants/testIDs';

interface NoPreviewVisualizerProps {
  isProcessing?: boolean;
}

export const NoPreviewVisualizer = memo(({ isProcessing }: NoPreviewVisualizerProps) => {
  return (
    <div
      data-testid={ASSET_MODAL_PROCESSING_TEXT}
      className="flex min-h-[380px] flex-col items-center justify-center md:min-h-0"
    >
      {isProcessing ? (
        <Refresh className="h-8 w-8 animate-spin text-grey-9 motion-reduce:animate-none" />
      ) : (
        <Images className="h-16 w-16 text-grey-9" />
      )}
      <div className="mt-4 text-14 text-grey-11 md:mt-6 md:text-16">
        {isProcessing ? 'Processing... this may take a few minutes' : 'No preview available'}
      </div>
    </div>
  );
});

NoPreviewVisualizer.displayName = 'NoPreviewVisualizer';
