import { tailwindMerge } from '@air/tailwind-variants';
import { memo, PropsWithChildren, ReactNode } from 'react';

export interface AssetModalFooterProps {
  header: ReactNode;
  className?: string;
}

export const AssetModalFooter = memo(({ header, children, className }: PropsWithChildren<AssetModalFooterProps>) => {
  return (
    <div
      className={tailwindMerge(
        'relative flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden',
        className,
      )}
    >
      {children}
      <div className="absolute bottom-0 left-0 flex h-16 w-full items-center justify-center border-t border-t-grey-5 bg-grey-1 px-4">
        {header}
      </div>
    </div>
  );
});

AssetModalFooter.displayName = 'AssetModalFooter';
