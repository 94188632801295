import { memo, ReactNode } from 'react';

import { useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';

import { Content } from './Content';
import { ContentHeader, ContentHeaderProps } from './ContentHeader/ContentHeader';

export interface AssetModalPanelContainerProps {
  title: ContentHeaderProps['title'];
  adornment?: ContentHeaderProps['adornment'];
  contentHeader?: ContentHeaderProps['contentHeader'];
  panel: ReactNode;
}

export const AssetModalPanelContainer = memo(
  ({ title, adornment, contentHeader, panel }: AssetModalPanelContainerProps) => {
    const { hidePanel } = useAssetModalPanelContext();

    return (
      <Content>
        <ContentHeader title={title} adornment={adornment} contentHeader={contentHeader} closePanel={hidePanel} />
        <div className="flex w-full grow flex-col md:overflow-auto">{panel}</div>
      </Content>
    );
  },
);

AssetModalPanelContainer.displayName = 'AssetModalPanelContainer';
