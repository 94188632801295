import { Plus } from '@air/next-icons';
import { IconButton, IconButtonProps } from '@air/primitive-icon-button';
import { memo } from 'react';

export type AssetMobileVersionsHeaderProps = {
  onCreate?: IconButtonProps['onClick'];
  title?: string;
};

export const AssetMobileVersionsHeader = memo(({ onCreate, title = 'Versions' }: AssetMobileVersionsHeaderProps) => {
  return (
    <header className="flex items-center justify-between gap-2">
      <h3 className="text-12 font-bold uppercase tracking-wider text-grey-12">{title}</h3>

      {onCreate && (
        <IconButton
          appearance="ghost"
          color="grey"
          icon={Plus}
          label="Add new version"
          onClick={onCreate}
          size="small"
        />
      )}
    </header>
  );
});

AssetMobileVersionsHeader.displayName = 'AssetMobileVersionsHeader';
