import { isBoolean, noop } from 'lodash';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import screenfull, { IScreenfull } from 'screenfull';

const screenfullEnabled = screenfull && screenfull.enabled;

export type ToggleFullScreen = () => void;

/**
 * This hook uses screenfull library to support switching between fullscreen state
 * it also enables ESC hotkey to exit from fullscreen
 * @param element element to open in fullscreen mode
 * @returns [boolean, ToggleFullScreen] [true if element is fullscreen, method to toogle full screen]
 */
export function useFullScreen(element: RefObject<HTMLElement> | null): [boolean, ToggleFullScreen] {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullscreen: ToggleFullScreen = useCallback(
    () => (element && !!screenfull ? screenfull.toggle(element.current!) : noop()),
    [element],
  );

  const _screenfull = isBoolean(screenfull) ? ({ on: noop, off: noop, exit: noop } as IScreenfull) : screenfull;

  useEffect(() => {
    const onFullScreenChange = () => {
      setIsFullScreen(_screenfull.isFullscreen);
    };

    if (screenfullEnabled) {
      _screenfull.on('change', onFullScreenChange);
    }

    return () => {
      if (screenfullEnabled) {
        _screenfull.off('change', onFullScreenChange);
      }
    };
    // we want to call it on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useHotkeys('esc', () => {
    if (_screenfull.isFullscreen) {
      _screenfull.exit();
    }
  });

  return [isFullScreen, toggleFullscreen];
}
