import {
  Board,
  BoardClipDiscussionResponse,
  Clip,
  ClipDiscussionResponse,
  Comment,
  CommentResponse,
  CreateDiscussionInput,
  Discussion,
  User,
} from '@air/api/types';
import produce from 'immer';
import { isObject, isUndefined } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { getPageNumber, hasAnnotation, isAnnotationCorrect } from '~/components/Annotations/shared/annotationUtils';
import { ClipDiscussion, FreeformAnnotation } from '~/components/Annotations/shared/types';
import { DISCUSSIONS, GetDiscussionKeyParams } from '~/constants/react-query-keys';
import { ClipDiscussionsCacheType } from '~/swr-hooks/discussions/types';

export const getCommentParams = (
  newComment: string,
  existingDiscussion: BoardClipDiscussionResponse | ClipDiscussionResponse,
) =>
  ({
    body: newComment,
    createdAt: new Date().toISOString(),
    discussionId: existingDiscussion.id,
    id: uuidv4(),
  }) as Pick<Comment, 'body' | 'createdAt' | 'discussionId' | 'id'>;

export const insertCommentToDiscussion = ({
  newComment,
  discussionId,
  data,
}: {
  newComment: Pick<Comment, 'body' | 'createdAt' | 'discussionId' | 'id'> & {
    account: Pick<User, 'avatar' | 'firstName' | 'id' | 'lastName'>;
  };
  discussionId: string;
  data: ClipDiscussionsCacheType | undefined;
}) => {
  if (!data) return;

  return produce(data, (draft) => {
    for (const page of draft.pages) {
      const discussion = page.data.find((d) => d.id === discussionId);
      if (discussion) {
        discussion.comments.data.push({
          ...newComment,
          permissions: {
            canDelete: false,
            canEdit: false,
          },
        });
        discussion.comments.total += 1;
      }
    }
  });
};

export const deleteCommentInCache = (
  { existingComment }: { existingComment: CommentResponse },
  data: ClipDiscussionsCacheType | undefined,
) => {
  if (!data) return;

  return produce(data, (draft) => {
    draft.pages.forEach((page) => {
      const discussion = page.data.find((d) => d.id === existingComment.discussionId);
      if (!!discussion) {
        if (discussion.comments.total === 1) {
          page.data = page.data.filter((d) => d.id !== discussion.id);
          page.total -= 1;
        } else {
          discussion.comments.data = discussion.comments.data.filter((comment) => comment.id !== existingComment.id);
          discussion.comments.total -= 1;
        }
      }
    });
  });
};

export const editCommentInCache = (
  { newComment, existingComment }: { newComment: string; existingComment: CommentResponse },
  data?: ClipDiscussionsCacheType,
) => {
  if (!data) return;

  return produce(data, (draft) => {
    draft.pages.forEach((page) => {
      const discussion = page.data.find((d) => d.id === existingComment.discussionId);
      if (!!discussion) {
        const comment = discussion.comments.data.find((c) => c.id === existingComment.id);
        if (!!comment) {
          comment.body = newComment;
        }
      }
    });
  });
};

export const getDiscussionsParams = (
  newComment: string,
  type: 'clip',
  clipId?: Clip['id'],
  board?: Partial<Pick<Board, 'id' | 'title'>>,
  timestamp?: number,
  newAnnotation?: FreeformAnnotation,
) => {
  const params: CreateDiscussionInput = {
    discussion: {
      type,
      clipId: clipId || '',
      timestamp,
      annotation: newAnnotation
        ? {
            ...newAnnotation,
            timestamp,
          }
        : undefined,
    },
    comment: {
      createdAt: new Date().toISOString(),
      body: newComment,
    },
  };

  if (board?.id) {
    params.discussion.boardId = board.id;
  }

  return params;
};

type CreateDiscussionInCacheInput = CreateDiscussionInput['discussion'] & Pick<Discussion, 'id'>;
type CreateCommentInCacheInput = CreateDiscussionInput['comment'] & Pick<Discussion, 'id'>;

export const mockDiscussion = ({
  account,
  board,
  clipId,
  currentVersion,
  newComment,
  newDiscussion,
  assetId,
}: {
  account: Pick<User, 'avatar' | 'firstName' | 'id' | 'lastName'>;
  board?: Partial<Pick<Board, 'id' | 'title'>>;
  clipId: Clip['id'];
  currentVersion: number;
  newComment: CreateCommentInCacheInput;
  newDiscussion: CreateDiscussionInCacheInput;
  assetId: Clip['assetId'];
}): ClipDiscussion => ({
  ...newDiscussion,
  type: 'clip',
  board:
    board?.id && board.title
      ? {
          id: board.id,
          title: board.title,
        }
      : null,
  clipId,
  comments: {
    data: [
      {
        ...newComment,
        account,
        discussionId: newDiscussion.id,
        permissions: {
          canDelete: false,
          canEdit: false,
        },
      },
    ],
    pagination: {
      hasMore: false,
      cursor: null,
    },
    total: 1,
  },
  isDemo: false,
  permissions: {
    canResolve: false,
  },
  resolved: false,
  workspaceId: '',
  assetId,
  assetVersion: currentVersion,
});

export const createDiscussionInCache = (
  newDiscussion: ClipDiscussion,
  cacheData: ClipDiscussionsCacheType | undefined,
) => {
  if (!cacheData) return;

  return produce(cacheData, (draft) => {
    if (draft.pages.length > 0) {
      draft.pages[0].data.unshift(newDiscussion);
      draft.pages[0].total += 1;
    } else {
      draft.pages.push({
        data: [newDiscussion],
        pagination: {
          hasMore: false,
          cursor: null,
        },
        total: 1,
      });
    }
  });
};

export const updateDiscussionInCache = (
  { id, ...discussionUpdate }: Pick<Discussion, 'id' | 'resolved'>,
  data: ClipDiscussionsCacheType | undefined,
) => {
  if (!data) return;

  return produce(data, (draft) => {
    for (const page of draft.pages) {
      const discussionIndex = page.data.findIndex((d) => d.id === id);
      if (discussionIndex != -1) {
        const discussion = page.data[discussionIndex];
        page.data[discussionIndex] = Object.assign({}, discussion, discussionUpdate);
      }
    }
  });
};

export const convertDiscussionsToClipDiscussions = (discussions: ClipDiscussionResponse[]): ClipDiscussion[] =>
  discussions
    .filter((d): d is ClipDiscussion => d.type === 'clip')
    .map((d) => {
      if (!hasAnnotation(d) || !isAnnotationCorrect(d.annotation)) {
        return d;
      }
      const annotation = d.annotation;
      if (!annotation.type) {
        const annotation = d.annotation;
        annotation.type = 'box';
      }

      // images and videos used to have a different component for drawing annotations
      // annotations created by it treated width and height differently
      // currently width and height are treated as end points, whereas before it was width and height of rectangle
      // the only way to distinguish between old and new rectangles is to check pageNumber
      // for new image and videos rectangles we send pageNumber=-1
      // all previous image and videos annotations have pageNumber=undefined, so we know that we should map their width and height
      if (annotation.type === 'box' && isUndefined(getPageNumber(annotation))) {
        annotation.dimensions.width = annotation.coordinates.x + annotation.dimensions.width;
        annotation.dimensions.height = annotation.coordinates.y + annotation.dimensions.height;
      }

      return {
        ...d,
        annotation,
      };
    });

export type ClipDiscussionsKey = [typeof DISCUSSIONS, GetDiscussionKeyParams];

export const isClipDiscussionsKey = (key: unknown): key is ClipDiscussionsKey => {
  return Array.isArray(key) && key[0] === DISCUSSIONS && isObject(key[1]);
};
