import { Discussion } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { memo } from 'react';

import { useDiscussionPanelContext } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { DISCUSSION_COMMENT_ADD_REPLY } from '~/constants/testIDs';

export interface ReplyButtonProps {
  discussionId: Discussion['id'];
}

export const ReplyButton = memo(({ discussionId }: ReplyButtonProps) => {
  const { setReplyingDiscussionId } = useDiscussionPanelContext();
  return (
    <Button
      appearance="ghost"
      color="grey"
      data-testid={DISCUSSION_COMMENT_ADD_REPLY}
      onClick={(event) => {
        event.stopPropagation();
        setReplyingDiscussionId(discussionId);
      }}
      size="small"
    >
      Reply
    </Button>
  );
});

ReplyButton.displayName = 'ReplyButton';
