import { CheckCircled, Resolve } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo, useCallback } from 'react';

import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { CommentMenuProps } from '~/components/Discussions/Discussion/DiscussionComment/components/CommentMenu';
import { REOPEN_DISCUSSION_BUTTON } from '~/constants/testIDs';
import { useHover } from '~/hooks/useHover';

export interface ReopenDiscussionButtonProps {
  onToggleResolve: CommentMenuProps['onToggleResolve'];
  discussion: ClipDiscussion;
}

export const ReopenDiscussionButton = memo(({ onToggleResolve, discussion }: ReopenDiscussionButtonProps) => {
  const { handleUnhover, handleHover, isHovering } = useHover();

  const handleToggleResolveClick = useCallback(() => {
    onToggleResolve?.({
      annotation: discussion.annotation,
      clipId: discussion.clipId,
      id: discussion.id,
      resolved: !discussion.resolved,
      timestamp: discussion.timestamp,
    });
  }, [discussion, onToggleResolve]);

  return (
    <Tooltip label="Re-open" side="top">
      <IconButton
        data-testid={REOPEN_DISCUSSION_BUTTON}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
        icon={isHovering ? Resolve : CheckCircled}
        className="ml-1 text-grey-9"
        appearance="ghost"
        color="grey"
        size="small"
        onClick={handleToggleResolveClick}
        label="Reopen"
      />
    </Tooltip>
  );
});

ReopenDiscussionButton.displayName = 'ReopenDiscussionButton';
