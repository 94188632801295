import { useMemoizedContextProvider, useMemoizedContextSelector } from '@air/react-memoized-context';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';

import { ClipDiscussion } from '~/components/Annotations/shared/types';
import {
  ASSET_MODAL_DISCUSSION_FILTERS,
  ASSET_MODAL_DISCUSSION_SORT,
  ASSET_MODAL_DISCUSSION_VERSION_FILTERS,
  defaultDiscussionPanelContextValue,
  DiscussionsPanelContextActions,
  DiscussionsPanelContextType,
  DiscussionsPanelContextValue,
} from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { discussionsPanelContextDispatch } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextUtils';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

const DiscussionsPanelContext = createContext(defaultDiscussionPanelContextValue);

export const DiscussionsPanelContextProvider = ({ children }: PropsWithChildren<object>) => {
  const { contextValue } = useMemoizedContextProvider<DiscussionsPanelContextValue>(
    {
      discussionsVersionFilter: ASSET_MODAL_DISCUSSION_VERSION_FILTERS.ALL,
      discussionsFilter: ASSET_MODAL_DISCUSSION_FILTERS.OPENED,
      discussionsSort: ASSET_MODAL_DISCUSSION_SORT.DEFAULT,
      openReplies: {},
    },
    discussionsPanelContextDispatch,
  );

  const setDiscussionsFilter = useCallback(
    (filter: ASSET_MODAL_DISCUSSION_FILTERS) =>
      contextValue.dispatch({ type: DiscussionsPanelContextActions.setDiscussionsFilter, data: filter }),
    [contextValue],
  );

  const setDiscussionsSort = useCallback(
    (sort: ASSET_MODAL_DISCUSSION_SORT) =>
      contextValue.dispatch({ type: DiscussionsPanelContextActions.setDiscussionsSort, data: sort }),
    [contextValue],
  );

  const setDiscussionsVersionFilter = useCallback(
    (filter: ASSET_MODAL_DISCUSSION_VERSION_FILTERS) =>
      contextValue.dispatch({ type: DiscussionsPanelContextActions.setDiscussionsVersionFilter, data: filter }),
    [contextValue],
  );

  const setReplyingDiscussionId = useCallback(
    (discussionId: ClipDiscussion['id']) =>
      contextValue.dispatch({ type: DiscussionsPanelContextActions.setReplyingDiscussionId, data: discussionId }),
    [contextValue],
  );

  const clearReplyingDiscussionId = useCallback(
    () => contextValue.dispatch({ type: DiscussionsPanelContextActions.clearReplyingDiscussionId }),
    [contextValue],
  );

  const setRepliesOpen = useCallback(
    (discussionId: ClipDiscussion['id'], isOpen: boolean) =>
      contextValue.dispatch({ type: DiscussionsPanelContextActions.setRepliesOpen, data: { discussionId, isOpen } }),
    [contextValue],
  );

  const value: DiscussionsPanelContextType = useMemo(
    () => ({
      ...contextValue,
      setRepliesOpen,
      setDiscussionsFilter,
      setDiscussionsSort,
      setDiscussionsVersionFilter,
      setReplyingDiscussionId,
      clearReplyingDiscussionId,
    }),
    [
      clearReplyingDiscussionId,
      contextValue,
      setRepliesOpen,
      setDiscussionsFilter,
      setDiscussionsSort,
      setDiscussionsVersionFilter,
      setReplyingDiscussionId,
    ],
  );

  return <DiscussionsPanelContext.Provider value={value}>{children}</DiscussionsPanelContext.Provider>;
};

export const useDiscussionPanelContext = () => {
  const context = useContext(DiscussionsPanelContext);

  if (context === defaultDiscussionPanelContextValue) {
    const error = 'DiscussionsPanelContext used outside of DiscussionsPanelProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};

export function useDiscussionPanelContextSelector<T>(selector: (st: DiscussionsPanelContextValue) => T) {
  const context = useDiscussionPanelContext();
  return useMemoizedContextSelector(context, selector);
}
