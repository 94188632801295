import { ReactNode } from 'react';

import { FormikSingleSelect } from '~/components/Formik/FormikSingleSelect';
import { SelectProps } from '~/components/Select/Select';
import { ImgixSupportedExportFormat } from '~/utils/Imgix';

export interface ImageFormatFormFieldProps {
  bottomAdornment?: ReactNode;
  disabled?: boolean;
  options: SelectProps['options'];
  label: string;
  name: string;
  onFocus: (isFocused: boolean) => void;
  rightAdornment?: ReactNode;
  ['data-testid']?: string;
  className?: string;
}

export type ImageSizeRatio = '1x (Original)' | '.5x' | 'Custom';

export interface ImageSizeOption {
  label: ImageSizeRatio;
  value: string;
}

export interface ImageFormatOption {
  label: string;
  value: ImgixSupportedExportFormat;
}

/**
 * @description A few of the valid image extensions that an imgix image url can be created for
 * @see https://docs.imgix.com/apis/rendering/format/fm
 */
export const defaultImageExtOptions: ImageFormatOption[] = [
  {
    label: 'JPG',
    value: 'jpg',
  },
  {
    label: 'PNG',
    value: 'png',
  },
  {
    label: 'WEBP',
    value: 'webp',
  },
];

export const defaultImageSizeOptions: ImageSizeOption[] = [
  {
    label: '1x (Original)',
    value: '1',
  },
  {
    label: '.5x',
    value: '0.5',
  },
  {
    label: 'Custom',
    value: '0',
  },
];

export const ImageFormatField = ({
  bottomAdornment,
  disabled,
  options: imageSizeOptions,
  label,
  name,
  rightAdornment,
  'data-testid': testId,
  onFocus,
  className,
}: ImageFormatFormFieldProps) => {
  return (
    <>
      <div className={className}>
        <FormikSingleSelect
          disabled={disabled}
          data-testid={testId}
          isSearchable={false}
          label={label}
          name={name}
          options={imageSizeOptions}
          size="extra-large"
          onOptionsClose={() => {
            onFocus(false);
          }}
          onOptionsOpen={() => {
            onFocus(true);
          }}
        />
        {rightAdornment}
      </div>
      {bottomAdornment}
    </>
  );
};
