import { Download } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, ReactNode } from 'react';

import { ASSET_MODAL_PANEL_TITLE, COLLAPSE_PANE } from '~/constants/testIDs';
import usePrevious from '~/hooks/usePrevious';

export interface ContentHeaderProps {
  closePanel: () => void;
  title?: string;
  adornment?: ReactNode;
  contentHeader?: ReactNode;
}

export const ContentHeader = memo(({ closePanel, title, adornment, contentHeader }: ContentHeaderProps) => {
  const previousTitle = usePrevious(title);
  const { isAboveMediumScreen } = useBreakpointsContext();

  return (
    <div className="w-full border-b border-grey-5 pl-6 pr-4">
      <div className="flex h-[60px] items-center justify-start">
        <div data-testid={ASSET_MODAL_PANEL_TITLE} className="mr-auto text-14 font-bold uppercase text-grey-10">
          {!title && !!previousTitle ? previousTitle : title}
        </div>
        {adornment}
        {isAboveMediumScreen && (
          <Tooltip label="Collapse">
            <IconButton
              appearance="ghost"
              color="grey"
              data-testid={COLLAPSE_PANE}
              onClick={closePanel}
              label="Collapse pane"
              size="small"
            >
              <Download className="h-4 w-4 -rotate-90" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {contentHeader}
    </div>
  );
});

ContentHeader.displayName = 'ContentHeader';
