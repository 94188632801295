import { useHotkeys } from 'react-hotkeys-hook';

import { useFullscreenContext } from '../context/FullscreenContext';
import { useCloseAssetModal } from '../useCloseAssetModal';

let _stopEscKeyPropagation = false;

export const stopEscKeyPropagationOnAssetDetailsModal = (nextValue: boolean) => {
  _stopEscKeyPropagation = nextValue;
};

export const useAssetModalEscKeyHandler = () => {
  const { closeAssetModal } = useCloseAssetModal();
  const { isFullscreen } = useFullscreenContext();

  useHotkeys(
    'esc',
    () => {
      closeAssetModal();
    },
    {
      enabled: !isFullscreen && !_stopEscKeyPropagation,
    },
    [closeAssetModal],
  );
};
