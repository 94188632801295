import {
  BoundingBoxHandler,
  BoundingBoxHandlerConstructor,
  GetNewRectType,
  InternalStartDrawingType,
} from '~/components/BoundingBox/utils/BoundingBoxHandler';
import { MovableCorner } from '~/components/BoundingBox/utils/drawSquareAroundPoint';

export interface BoundingBoxResizerConstructor extends BoundingBoxHandlerConstructor {
  hoveredCorner: MovableCorner | null;
}

export class BoundingBoxResizer extends BoundingBoxHandler {
  private readonly hoveredCorner: MovableCorner | null;

  private hoveredXProperty: 'pctStartX' | 'pctEndX' | null = null;
  private hoveredYProperty: 'pctStartY' | 'pctEndY' | null = null;

  constructor({ hoveredCorner, ...params }: BoundingBoxResizerConstructor) {
    super(params);
    this.hoveredCorner = hoveredCorner;
  }

  _startDrawing: InternalStartDrawingType = ({ currentRect }) => {
    if (!currentRect) return;

    switch (this.hoveredCorner) {
      case 'top-left':
        this.hoveredXProperty = currentRect.pctStartX < currentRect.pctEndX ? 'pctStartX' : 'pctEndX';
        this.hoveredYProperty = currentRect.pctStartY < currentRect.pctEndY ? 'pctStartY' : 'pctEndY';
        break;
      case 'top-right':
        this.hoveredXProperty = currentRect.pctStartX < currentRect.pctEndX ? 'pctEndX' : 'pctStartX';
        this.hoveredYProperty = currentRect.pctStartY < currentRect.pctEndY ? 'pctStartY' : 'pctEndY';
        break;
      case 'bottom-left':
        this.hoveredXProperty = currentRect.pctStartX < currentRect.pctEndX ? 'pctStartX' : 'pctEndX';
        this.hoveredYProperty = currentRect.pctStartY < currentRect.pctEndY ? 'pctEndY' : 'pctStartY';
        break;
      case 'bottom-right':
        this.hoveredXProperty = currentRect.pctStartX < currentRect.pctEndX ? 'pctEndX' : 'pctStartX';
        this.hoveredYProperty = currentRect.pctStartY < currentRect.pctEndY ? 'pctEndY' : 'pctStartY';
        break;
    }
  };

  _getNewRect: GetNewRectType = ({ point }) => {
    if (!this.currentRect || !this.hoveredXProperty || !this.hoveredYProperty) return;

    return {
      ...this.currentRect,
      [this.hoveredXProperty]: point.pctX,
      [this.hoveredYProperty]: point.pctY,
    };
  };

  _finishDrawing = () => {
    this.hoveredXProperty = null;
    this.hoveredYProperty = null;
  };
}
