import { createPopper, Instance } from '@popperjs/core';
import { DependencyList, RefObject, useEffect } from 'react';

interface UseMouseTooltipParams {
  containerRef: RefObject<HTMLDivElement>;
  tooltipRef: RefObject<HTMLDivElement>;
  disabled?: boolean;
  dependencies?: DependencyList;
}

export const useMouseTooltip = ({ containerRef, tooltipRef, disabled, dependencies = [] }: UseMouseTooltipParams) => {
  useEffect(() => {
    const container = containerRef.current;
    let popperInstance: Instance | null = null;
    let cursorPosition: { x: number; y: number } | null = null;

    let intervalCursorPosition: { x: number; y: number } | null = null;
    let lastExecution: number | null = null;

    let timer = -1;
    const displayTooltipIfNeeded = () => {
      const _displayTooltipIfNeeded = () => {
        if (!cursorPosition) {
          return;
        }

        if (!intervalCursorPosition) {
          intervalCursorPosition = cursorPosition;
          return;
        }

        if (popperInstance) {
          return;
        }

        if (!tooltipRef.current) {
          return;
        }

        if (
          Math.abs(cursorPosition.x - intervalCursorPosition.x) < 20 &&
          Math.abs(cursorPosition.y - intervalCursorPosition.y) < 20
        ) {
          popperInstance = createPopper(
            {
              getBoundingClientRect: () => {
                if (!cursorPosition) {
                  return new DOMRect(0, 0, 0, 0);
                }
                return new DOMRect(cursorPosition.x, cursorPosition.y, 0, 0);
              },
            },
            tooltipRef.current,
            {
              placement: 'bottom-start',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [8, 8],
                  },
                },
              ],
            },
          );
          tooltipRef.current.style.opacity = '1';
        }
      };

      if (!lastExecution) {
        lastExecution = Date.now();
      }

      if (Date.now() - lastExecution > 500) {
        _displayTooltipIfNeeded();
        lastExecution = Date.now();
      }

      timer = window.requestAnimationFrame(displayTooltipIfNeeded);
    };

    const clearTooltip = () => {
      if (tooltipRef.current) {
        tooltipRef.current.style.opacity = '0';
      }

      if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
      }
    };

    const handler = (e: MouseEvent) => {
      clearTooltip();

      intervalCursorPosition = null;

      cursorPosition = { x: e.clientX, y: e.clientY };
    };

    const mouseEnterHandler = (e: MouseEvent) => {
      cursorPosition = { x: e.clientX, y: e.clientY };

      timer = window.requestAnimationFrame(displayTooltipIfNeeded);
    };

    const resetState = () => {
      clearTooltip();
      intervalCursorPosition = null;
      lastExecution = null;
    };

    const mouseLeaveHandler = () => {
      if (timer !== -1) {
        window.cancelAnimationFrame(timer);
      }
      resetState();
    };

    if (container && !disabled) {
      container.addEventListener('mouseenter', mouseEnterHandler);
      container.addEventListener('mouseleave', mouseLeaveHandler);
      container.addEventListener('mousemove', handler);
    }

    window.addEventListener('blur', resetState);

    return () => {
      if (container && !disabled) {
        container.removeEventListener('mouseenter', mouseEnterHandler);
        container.removeEventListener('mouseleave', mouseLeaveHandler);
        container.removeEventListener('mousemove', handler);
      }

      window.removeEventListener('blur', resetState);

      if (timer !== -1) {
        window.cancelAnimationFrame(timer);
      }
    };
    // eslint complains about ...dependencies (spread)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, tooltipRef, disabled, ...dependencies]);
};
